import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {ALL_LOGIN_SERVICES} from '@authentication/login-service';
import {UserService} from '@services/user-service/user.service';
import {ITHRIV_INSTITUTION_ID, PUBLIC_INSTITUTION_ID} from '@shared/constants/constants';
import {Institution} from '@shared/types/institution';

import {BreadcrumbsHistoryService} from '@services/breadcrumbs-history/breadcrumbs-history.service';
import {ResourceApiService} from '@services/resource-api/resource-api.service';

// Types
import {Category} from '@shared/types/category';
import {FileAttachment} from '@shared/types/file-attachment';
import {NavItem} from '@shared/types/nav-item';
import {Resource} from '@shared/types/resource';
import {ResourceCategory} from '@shared/types/resource-category';
import {ResourceType} from '@shared/types/resourceType';
import {User} from '@shared/types/user';

import {fadeTransition, zoomTransition} from '@utilities/animations';
import {lastValueFrom} from 'rxjs';

import dayjs from 'dayjs';

@Component({
  selector: 'app-resource',
  templateUrl: './resource.component.html',
  styleUrls: ['./resource.component.scss'],
  animations: [zoomTransition(), fadeTransition()],
})
export class ResourceComponent implements OnInit {
  resourceId: number;
  @Input() resource: Resource;
  @Input() categories: ResourceCategory[];
  attachments: FileAttachment[];
  user: User;

  transitionState = '';

  @HostBinding('@fadeTransition')
  isDataLoaded = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private api: ResourceApiService,
    private breadcrumbsHistory: BreadcrumbsHistoryService,
    private userService: UserService,
    public snackBar: MatSnackBar,
  ) {
    this.route.params.subscribe(params => {
      this.resourceId = params['resource'];
      this.loadResource();
      this.loadUser();
    });
  }

  /**
   * Returns the cached navItems in the breadcrumbs history, so we can add this resource to the end of the list.
   */
  get navItems(): NavItem[] {
    return this.breadcrumbsHistory.get();
  }

  ngOnInit() {
    let import_js = async () => {
      // using this atc.min.js need to be changed in the future to an ES6 compliant module
      // @ts-ignore
      return await import('@src/assets/js/atc.min.js');
    };
    import_js().then(() => {});
  }

  showToggle() {
    return (
      this.resource.institution.id !== ITHRIV_INSTITUTION_ID && this.resource.institution.id !== PUBLIC_INSTITUTION_ID
    );
  }

  submitForApproval() {
    this.resource.approved = 'Requested';
    this.api.updateResource(this.resource).subscribe(resource => {
      this.api.sendApprovalRequestEmail(this.user, resource).subscribe(resp => {});
    });
  }

  handleClick($event) {
    $event.preventDefault();
    $event.stopPropagation();
  }

  loadResource() {
    this.api.getResource(this.resourceId).subscribe(resource => {
      this.resource = resource;

      if (this.resource.starts && this.resource.ends) {
        // Add this resource to the end of the cached breadcrumbs list.
        this.breadcrumbsHistory.push({
          title: this.resource.name,
        });
      }
      this.loadResourceCategories(resource);
    });
  }

  loadResourceCategories(resource: Resource) {
    this.api.getResourceCategories(resource).subscribe(rcs => {
      this.categories = rcs;
      this.transitionState = 'zoom-in-enter';
      this.isDataLoaded = true;
    });
  }

  loadUser() {
    this.api.getSession().subscribe(user => (this.user = user));
  }

  getCategoryIcon(category: Category) {
    if (category.level === 2) {
      return `ithriv_${category.parent.icon.id}`;
    }
  }

  getResourceOrEventURL(resource: Resource) {
    return [window.location.origin, '#', 'resource', resource.id].join('/');
  }

  getAvailableInstitutions() {
    return this.resource.availabilities.filter(av => av.available).map(av => av.institution);
  }

  goInstitution($event, institution: Institution) {
    $event.preventDefault();
    this.router.navigateByUrl(`/search/filter?Institution=${institution.name}`);
  }

  goCategory($event, category: Category) {
    $event.preventDefault();

    if (category.level === 2) {
      this.router.navigate(['category', category.id]);
    } else if (this.api.getViewPreferences().isNetworkView) {
      this.router.navigate(['network', category.id]);
    } else {
      const id = category.level === 1 ? category.parent.id : category.id;
      this.router.navigate(['browse', id]);
    }
  }

  goResourceType($event, type: ResourceType) {
    $event.preventDefault();
    this.router.navigateByUrl(`/search/filter?Type=${type.name}`);
  }

  goWebsite($event) {
    $event.preventDefault();
    window.open(this.resource.website, '_blank');
  }

  openEdit() {
    this.router.navigateByUrl(
      `<segment>/${this.resource.id}/edit`.replace('<segment>', this.resource.segment.name.toLowerCase()),
    );
  }

  fileIcon(file: FileAttachment): string {
    const s = file.mime_type || file.type || file.name || file.file_name;
    const nameArray = s.toLowerCase().split(file.mime_type || file.type ? '/' : '.');

    if (nameArray.length > 0) {
      return `/assets/filetypes/${nameArray[nameArray.length - 1]}.svg`;
    } else {
      return `/assets/filetypes/unknown.svg`;
    }
  }

  async togglePrivate(isPrivate: string) {
    this.resource.private = isPrivate === 'PRIVATE';
    await lastValueFrom(this.api.updateResource(this.resource));
    this.snackBar.open('Resource updated', 'Ok', {duration: 3000});
  }

  getInstitutionLogo() {
    return ALL_LOGIN_SERVICES[this.resource.institution.name].image;
  }

  openInTab(url: string) {
    window.open(url, '_blank');
  }

  loadDate(date: string) {
    // Convert UTC (from db) to user's local time for readable display
    return dayjs.utc(date).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('MMM D, YYYY, h:mm A z');
  }
}
