<div id="fileUpload" class="mat-typography" [ngClass]="{hover: dropZoneHover}">
  <ngx-file-drop
    id="dropZoneContainer"
    (onFileDrop)="dropped($event)"
    (onFileOver)="fileOver($event)"
    (onFileLeave)="fileLeave($event)"
    class="center"
    dropZoneClassName="file-upload"
    dropZoneLabel="Drop files here to attach them to this resource"
  >
    <ng-template ngx-file-drop-content-tmp>
      <span id="dropZoneInstructions">
        <mat-icon>cloud_upload</mat-icon>
        <h3>Drop files here to attach them to this resource</h3>
        <mat-progress-bar [ngClass]="{ghost: !displayProgressBar}" [value]="progressValue"></mat-progress-bar>
      </span>
    </ng-template>
  </ngx-file-drop>
  <div
    id="fileQueueContainer"
    *ngIf="filesDataSource && filesDataSource.data.length > 0"
    [@zoomTransition]="field.attachments.size > 0 ? 'fade-enter' : 'fade-exit'"
  >
    <table mat-table [dataSource]="filesDataSource" class="mat-elevation-z8" aria-label="Uploaded files">
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Type</th>
        <td mat-cell *matCellDef="let file">
          <img [alt]="fileType(file)" [src]="fileIcon(file)" />
        </td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let file">{{ truncate(file.name || file.file_name, 60) }}</td>
      </ng-container>
      <ng-container matColumnDef="display_name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let file">
          <mat-form-field [formGroup]="field.formGroup">
            <input
              matInput
              placeholder="Display name"
              [formControlName]="field.name"
              [value]="file.display_name || file.name || file.file_name"
              (blur)="updateDisplayName($event, file)"
            />
          </mat-form-field>
        </td>
      </ng-container>
      <ng-container matColumnDef="size">
        <th mat-header-cell *matHeaderCellDef>Size</th>
        <td mat-cell *matCellDef="let file">{{ formatSize(file.size) }}</td>
      </ng-container>
      <ng-container matColumnDef="lastModifiedDate">
        <th mat-header-cell *matHeaderCellDef>Date Modified</th>
        <td mat-cell *matCellDef="let file">{{ formatDate(file.lastModifiedDate || file.date_modified) }}</td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let file">
          <button mat-icon-button color="warn" (click)="removeFile($event, file)">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</div>
