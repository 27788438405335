/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { IRBInvestigatorsResponse } from '../models/IRBInvestigatorsResponse';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable({
    providedIn: 'root',
})
export class IrbProtocolsService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get Investigators By Study Irb Number
     * Gets a list of investigators (emails) of a study identified by IRB number. When "project-id" is sent
     * as a query parameter, only users who are on the IRB protocol and associated with the project are returned
     * @param studyIrbNumber
     * @param projectId
     * @returns IRBInvestigatorsResponse Successful Response
     * @throws ApiError
     */
    public listInvestigatorsByStudyIrbNumber(
        studyIrbNumber: string,
        projectId?: (string | null),
    ): Observable<Array<IRBInvestigatorsResponse>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/irb-protocols/{study_irb_number}/users',
            path: {
                'study_irb_number': studyIrbNumber,
            },
            query: {
                'project_id': projectId,
            },
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get Active Status By Study Irb Number
     * Returns True if the given study_irb_number is ACTIVE.
     * @param studyIrbNumber
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public listActiveStatusByStudyIrbNumber(
        studyIrbNumber: string,
    ): Observable<boolean> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/irb-protocols/{study_irb_number}/active',
            path: {
                'study_irb_number': studyIrbNumber,
            },
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

}
