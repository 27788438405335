// Autogenerated by util/sync-labels/sync-labels.ts
// Do not edit this file directly.
// To regenerate this file, start ithriv_landing_service locally on port 8000 and
// run the following command in the root directory of the ithriv_web repository:
// > npm run sync_labels

export enum DatasetAggregationLevel {
  NONE = 'none',
  ROW_LEVEL = 'row-level',
  GROUPS = 'groups',
}

export const DatasetAggregationLevelLabels = {
  none: 'NONE',
  'row-level': 'Row Level Data',
  groups: 'Data Aggregated to Groups of 11 or more',
} as const;

export type DatasetAggregationLevelId = keyof typeof DatasetAggregationLevelLabels;
export type DatasetAggregationLevelLabel = typeof DatasetAggregationLevelLabels[DatasetAggregationLevelId];
