/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { DatasetResponseList } from '../models/DatasetResponseList';
import type { FileVersionResponse } from '../models/FileVersionResponse';
import type { ProjectCreate } from '../models/ProjectCreate';
import type { ProjectFileCategoryID } from '../models/ProjectFileCategoryID';
import type { ProjectPatch } from '../models/ProjectPatch';
import type { ProjectPut } from '../models/ProjectPut';
import type { ProjectResponse } from '../models/ProjectResponse';
import type { ProjectResponseList } from '../models/ProjectResponseList';
import type { RequestBodyFileUpload } from '../models/RequestBodyFileUpload';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable({
    providedIn: 'root',
})
export class ProjectsService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get Projects
     * Gets a list of all project metadata (matching query params) where
     * requesting user has a role on the project
     * @param page
     * @param perPage
     * @param showAll
     * @param name
     * @param alternateName
     * @param description
     * @param isMetadataPublic
     * @param timeCreated
     * @param timeUpdated
     * @param sourceOrganizationId
     * @param publisherId
     * @returns ProjectResponseList Successful Response
     * @throws ApiError
     */
    public listProjects(
        page?: (number | null),
        perPage?: (number | null),
        showAll?: (boolean | null),
        name?: (string | null),
        alternateName?: (string | null),
        description?: (string | null),
        isMetadataPublic?: (boolean | null),
        timeCreated?: (string | null),
        timeUpdated?: (string | null),
        sourceOrganizationId?: (string | null),
        publisherId?: (string | null),
    ): Observable<ProjectResponseList> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/projects',
            query: {
                'page': page,
                'per_page': perPage,
                'show_all': showAll,
                'name': name,
                'alternate_name': alternateName,
                'description': description,
                'is_metadata_public': isMetadataPublic,
                'time_created': timeCreated,
                'time_updated': timeUpdated,
                'source_organization_id': sourceOrganizationId,
                'publisher_id': publisherId,
            },
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Create Project
     * Creates a new project
     * @param requestBody
     * @returns any Successful Response
     * @returns string Created
     * @throws ApiError
     */
    public createProject(
        requestBody: ProjectCreate,
    ): Observable<any | string> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/projects',
            body: requestBody,
            mediaType: 'application/json',
            responseHeader: 'Location',
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get Project
     * Gets project metadata for a specified (project_id) project where requesting user has a role on the project
     * @param projectId
     * @returns ProjectResponse Successful Response
     * @throws ApiError
     */
    public getProject(
        projectId: string,
    ): Observable<ProjectResponse> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/projects/{project_id}',
            path: {
                'project_id': projectId,
            },
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update Project
     * Updates project metadata for a specified (project_id) project
     * where requesting user is an owner on the project.
     * @param projectId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateProject(
        projectId: string,
        requestBody: ProjectPut,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/projects/{project_id}',
            path: {
                'project_id': projectId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Patch Project
     * Patches project metadata for a specified (project_id) project
     * where requesting user is an owner on the project.
     * @param projectId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public editProject(
        projectId: string,
        requestBody: ProjectPatch,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'PATCH',
            url: '/projects/{project_id}',
            path: {
                'project_id': projectId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Delete Project
     * Schedules a specified (project_id) project where requesting user is an owner on the project for permanent
     * deletion. If param: 'immediate' is True, a permanent and immediate delete occurs for the project as well as all its
     * associated files, datasets where this project is primary, and files in those datasets.
     * @param projectId
     * @param immediate
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteProject(
        projectId: string,
        immediate?: (boolean | null),
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/projects/{project_id}',
            path: {
                'project_id': projectId,
            },
            query: {
                'immediate': immediate,
            },
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Restore Project
     * If a project is scheduled to be deleted, this endpoint will remove it from the schedule
     * @param projectId
     * @returns any Successful Response
     * @throws ApiError
     */
    public restoreProject(
        projectId: string,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/projects/{project_id}/restore',
            path: {
                'project_id': projectId,
            },
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get Datasets In Project
     * Gets metadata for all datasets that a user has permissions to view in a specified (project_id) project
     * @param projectId
     * @param page
     * @param perPage
     * @param showAll
     * @param name
     * @param description
     * @param datasetType
     * @param license
     * @param studyIrbNumber
     * @param sensitivityLevelId
     * @param dataAggregationId
     * @param doi
     * @param isMetadataPublic
     * @param isDataPublic
     * @param timeCreated
     * @param timeUpdated
     * @param sourceOrganizationId
     * @param publisherId
     * @returns DatasetResponseList Successful Response
     * @throws ApiError
     */
    public listDatasetsInProject(
        projectId: string,
        page?: (number | null),
        perPage?: (number | null),
        showAll?: (boolean | null),
        name?: (string | null),
        description?: (string | null),
        datasetType?: (string | null),
        license?: (string | null),
        studyIrbNumber?: (string | null),
        sensitivityLevelId?: (string | null),
        dataAggregationId?: (string | null),
        doi?: (string | null),
        isMetadataPublic?: (boolean | null),
        isDataPublic?: (boolean | null),
        timeCreated?: (string | null),
        timeUpdated?: (string | null),
        sourceOrganizationId?: (string | null),
        publisherId?: (string | null),
    ): Observable<DatasetResponseList> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/projects/{project_id}/datasets',
            path: {
                'project_id': projectId,
            },
            query: {
                'page': page,
                'per_page': perPage,
                'show_all': showAll,
                'name': name,
                'description': description,
                'dataset_type': datasetType,
                'license': license,
                'study_irb_number': studyIrbNumber,
                'sensitivity_level_id': sensitivityLevelId,
                'data_aggregation_id': dataAggregationId,
                'doi': doi,
                'is_metadata_public': isMetadataPublic,
                'is_data_public': isDataPublic,
                'time_created': timeCreated,
                'time_updated': timeUpdated,
                'source_organization_id': sourceOrganizationId,
                'publisher_id': publisherId,
            },
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Upload Project File
     * Uploads a document of specific type (document_type) to
     * a specified (project_id) project
     * @param projectId
     * @param documentType
     * @param formData
     * @returns FileVersionResponse Successful Response
     * @throws ApiError
     */
    public uploadProjectFile(
        projectId: string,
        documentType: ProjectFileCategoryID,
        formData: RequestBodyFileUpload,
    ): Observable<FileVersionResponse> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/projects/{project_id}/files',
            path: {
                'project_id': projectId,
            },
            query: {
                'document_type': documentType,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Download Project File
     * Downloads a document of a specified (project_id) project
     * @param projectId
     * @param fileVersionId
     * @returns any Successful Response
     * @throws ApiError
     */
    public downloadProjectFile(
        projectId: string,
        fileVersionId: string,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/projects/{project_id}/files/{file_version_id}',
            path: {
                'project_id': projectId,
                'file_version_id': fileVersionId,
            },
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Delete Project File
     * Schedules a specified project file version for permanent deletion. If param: 'immediate' is True, a permanent and
     * immediate delete occurs from the database and file storage for the specified file version.
     * @param projectId
     * @param fileVersionId
     * @param immediate
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteProjectFile(
        projectId: string,
        fileVersionId: string,
        immediate?: (boolean | null),
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/projects/{project_id}/files/{file_version_id}',
            path: {
                'project_id': projectId,
                'file_version_id': fileVersionId,
            },
            query: {
                'immediate': immediate,
            },
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Restore Project File
     * Restores a document that was previously deleted from a specified (project_id) project
     * @param projectId
     * @param fileVersionId
     * @returns any Successful Response
     * @throws ApiError
     */
    public restoreProjectFile(
        projectId: string,
        fileVersionId: string,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/projects/{project_id}/files/{file_version_id}/restore',
            path: {
                'project_id': projectId,
                'file_version_id': fileVersionId,
            },
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get Pis On Project Over Size Limit
     * Gets a list of pi's associated with the project that are over the file size limit
     * @param projectId
     * @returns any Successful Response
     * @throws ApiError
     */
    public getPisOnProjectOverSizeLimit(
        projectId: string,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/projects/{project_id}/pi-size-limit',
            path: {
                'project_id': projectId,
            },
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

}
