import {Router} from '@angular/router';
import {ITHRIV_INSTITUTION_ID, PUBLIC_INSTITUTION_ID} from '@shared/constants/constants';
import {Institution} from '@shared/types/institution';
import {User} from '@shared/types/user';
import {getPrevUrl, setPrevUrl} from '@utilities/prev-url';
import {environment} from '@src/environments/environment';
import {cloneDeep} from 'lodash-es';
import {CommonsObject, CommonsObjectWithJoins} from '@shared/types/commons-types';

export type LoginServiceMap = {[key: string]: LoginService};
export type InstitutionMap = {[key: string]: Institution};

export type LoginServiceProps = {
  id?: number;
  color?: string;
  name?: string;
  image?: string;
  url?: string;
  websiteURL?: string;
  hasLandingService?: boolean;
  allowPublishDatasets?: boolean;
  domains?: string[];
};

export class LoginService {
  id: number;
  color: string;
  name: string;
  image: string;
  url?: string;
  websiteURL?: string;
  hasLandingService: boolean;
  allowPublishDatasets?: boolean;
  domains?: string[];

  constructor(private _props: LoginServiceProps) {
    for (const propName in this._props) {
      if (this._props.hasOwnProperty(propName)) {
        this[propName] = this._props[propName];
      }
    }
  }

  async goLogin(router: Router, timeoutDelaySeconds?: number) {
    const institutionId = this.id.toString();
    sessionStorage.setItem('institution_id', institutionId);
    localStorage.setItem('session', 'active');

    setPrevUrl();

    if (this.name === 'Public') {
      const prevUrl = getPrevUrl('/home');
      if (prevUrl) {
        // Navigate to the previous URL with Public mode enabled.
        const oldUrlTree = router.parseUrl(prevUrl);
        const urlPath = prevUrl.split('?')[0].split('/');
        const newUrlTree = router.createUrlTree(urlPath, {
          queryParams: {...oldUrlTree.queryParams},
          queryParamsHandling: 'merge',
          preserveFragment: true,
        });

        await router.navigateByUrl(newUrlTree);
      } else {
        await router.navigate(['home'], {
          queryParams: {tabIndex: 'events'},
        });
      }
    } else if (this.url) {
      const paramStr = timeoutDelaySeconds && environment.use_header_auth ? `?timeout=${timeoutDelaySeconds}` : '';
      window.location.href = this.url + paramStr;
    } else {
      await router.navigate(['login'], {
        queryParams: {institutionId: institutionId},
      });
    }
  }
}

const fakeLogin = environment.use_header_auth;
const loginUrl = fakeLogin
  ? environment.api + '/api/login'
  : environment.api + '/Shibboleth.sso/Login?target=' + environment.api + '/api/login&entityID=';

export const ALL_LOGIN_SERVICES: LoginServiceMap = {
  UVA: new LoginService({
    color: 'orange',
    name: 'UVA',
    image: '/assets/institutions/uva-logo.svg',
    url: fakeLogin ? loginUrl : loginUrl + 'urn:mace:incommon:virginia.edu',
    websiteURL: 'https://research.virginia.edu/',
    hasLandingService: true,
    allowPublishDatasets: true,
    domains: ['virginia.edu'],
  }),
  Carilion: new LoginService({
    color: 'navy',
    name: 'Carilion',
    image: '/assets/institutions/carilion-logo.svg',

    // This URL MUST be http (NOT https), because that's what's in the InCommon Shibboleth database
    url: fakeLogin ? loginUrl : loginUrl + 'http://fs.carilionclinic.org/adfs/services/trust',
    websiteURL: 'https://www.carilionclinic.org',
    hasLandingService: true,
    allowPublishDatasets: false,
    domains: ['carilionclinic.org'],
  }),
  'Virginia Tech': new LoginService({
    color: 'purple',
    name: 'Virginia Tech',
    image: '/assets/institutions/vt-logo.svg',
    url: fakeLogin ? loginUrl : loginUrl + 'urn:mace:incommon:vt.edu',
    websiteURL: 'https://www.research.vt.edu/research-development.html',
    hasLandingService: true,
    allowPublishDatasets: false,
    domains: ['vt.edu'],
  }),
  Inova: new LoginService({
    color: 'blue',
    name: 'Inova',
    image: '/assets/institutions/inova-logo.svg',
    url: fakeLogin ? loginUrl : loginUrl + 'https://sso.inova.org/idp',
    websiteURL: 'https://www.inova.org/our-services/inova-neurosciences/research',
    hasLandingService: false,
    domains: ['inova.org'],
  }),
  VCU: new LoginService({
    color: 'black',
    name: 'VCU',
    image: '/assets/institutions/vcu-logo.svg',
    url: fakeLogin ? loginUrl : loginUrl + 'https://shibboleth.vcu.edu/idp/shibboleth',
    websiteURL: 'https://cctr.vcu.edu',
    hasLandingService: false,
    domains: ['vcu.edu'],
  }),
  iTHRIV: new LoginService({
    color: 'green',
    name: 'iTHRIV',
    image: '/assets/institutions/ithriv-logo.svg',
    url: '',
    websiteURL: 'https://www.ithriv.org',
    hasLandingService: false,
    domains: [],
  }),
  'Fralin Biomedical Research Institute': new LoginService({
    color: 'purple',
    name: 'Fralin Biomedical Research Institute',
    image: '/assets/institutions/fbri-logo.svg',
    url: fakeLogin ? loginUrl : loginUrl + 'urn:mace:incommon:vt.edu',
    websiteURL: 'https://fbri.vtc.vt.edu',
    hasLandingService: false,
    domains: ['vt.edu'],
  }),
  CU: new LoginService({
    color: '#CFB87C',
    name: 'CU',
    image: '/assets/institutions/cu-logo.svg',
    url: fakeLogin
      ? loginUrl
      : loginUrl + 'https://idcs-6dfbdd810afa4d509f6cfc191d612acd.identity.oraclecloud.com:443/fed',
    websiteURL: 'https://www.cuanschutz.edu/',
    hasLandingService: false,
    domains: ['cuanschutz.edu', 'ucdenver.edu'],
  }),
  Public: new LoginService({
    color: 'green',
    name: 'Public',
    image: '/assets/institutions/public-logo.svg',
    url: '',
    websiteURL: 'https://www.ithriv.org',
    hasLandingService: false,
    domains: [],
  }),
};

export const ALLOWED_DOMAINS = Object.values(ALL_LOGIN_SERVICES).reduce(
  (acc, s) => (s.domains && s.domains.length > 0 ? acc.concat(s.domains) : acc),
  [],
);

export const getUserLoginService = (user: User): LoginService => {
  return new LoginService(ALL_LOGIN_SERVICES[user.institution.name]);
};

/**
 * If user is provided, returns user's LoginService, iTHRIV, & Other Source/External (if hidePublic is false)
 * @param institutions
 * @param hidePublic
 * @param user
 */
export const getLoginServices = (institutions: Institution[], hidePublic = false, user?: User): LoginService[] => {
  const services: LoginServiceMap = {};
  const loginServiceNames = ['UVA', 'Carilion', 'Virginia Tech', 'Inova', 'VCU', 'CU'];

  // Make a map for quick lookup of institutions by name.
  const institutionsMap: InstitutionMap = {};
  institutions.forEach(i => (institutionsMap[i.name] = i));

  const cloneAndSetId = (name: string): LoginService => {
    services[name] = cloneDeep(ALL_LOGIN_SERVICES[name]);
    services[name].id = institutionsMap[name].id;
    return services[name];
  };

  loginServiceNames.forEach(name => {
    services[name] = cloneDeep(ALL_LOGIN_SERVICES[name]);

    // Add institution IDs
    services[name].id = institutionsMap[name].id;
  });

  // User is already logged in.
  if (user) {
    // Remove institutions that don't match user's institution.
    Object.keys(services).forEach(k => {
      if (k !== user.institution.name) {
        delete services[k];
      }
    });

    // Add iTHRIV
    cloneAndSetId('iTHRIV');

    // If user's home institution is VT, add FBRI as an option.
    if (user.institution.name === 'Virginia Tech') {
      cloneAndSetId('Fralin Biomedical Research Institute');
    }
  }

  // Add Public and set its name
  if (hidePublic === undefined || hidePublic === false) {
    const publicLoginService = cloneAndSetId('Public');
    publicLoginService.name = user ? 'Other Source / External' : 'Public';
  }

  const servicesArray = Object.values(services).sort((a, b) => a.id - b.id);

  // Move iTHRIV and Public to the end.
  const moveToEnd = id => {
    const indexToMove = servicesArray.findIndex(s => s.id === id);
    servicesArray.push(servicesArray.splice(indexToMove, 1)[0]);
  };

  moveToEnd(ITHRIV_INSTITUTION_ID);
  moveToEnd(PUBLIC_INSTITUTION_ID);
  return servicesArray;
};

export interface HomeInstitution {
  name: string;
  image: string;
}

export const getCommonsObjectInstitutionName = (
  commonsObject: CommonsObject,
  get_source_org: boolean = false,
): string => {
  const obj = commonsObject as CommonsObjectWithJoins;

  if (get_source_org) {
    return obj.source_organization.name;
  }

  if (obj?.publisher?.name) {
    return obj.publisher.name;
  }

  if (obj?.source_organization?.name) {
    return obj.source_organization.name;
  }

  return ALL_LOGIN_SERVICES.Public.name;
};

export const getCommonsObjectHomeInstitution = (
  commonsObject: CommonsObject,
  get_source_org: boolean = false,
): HomeInstitution => {
  // Default to the dataset institution
  let institutionName = getCommonsObjectInstitutionName(commonsObject, get_source_org);

  // Look up login service by name
  const loginService = ALL_LOGIN_SERVICES[institutionName] || ALL_LOGIN_SERVICES.Public;

  // Hide logo if source org is not listed in ALL_LOGIN_SERVICES
  if (get_source_org && loginService.name === 'Public') {
    institutionName = 'Other Source / External';
  }
  return {
    name: institutionName,
    image: loginService.image,
  };
};

export const getSessionInstitutionId = () => {
  const institutionIdStr = sessionStorage.getItem('institution_id');

  if (institutionIdStr) {
    const institutionId = parseInt(institutionIdStr, 10);

    if (isFinite(institutionId)) {
      return institutionId;
    }
  }

  sessionStorage.setItem('institution_id', PUBLIC_INSTITUTION_ID.toString());
  localStorage.setItem('session', 'active');
  return PUBLIC_INSTITUTION_ID;
};

/**
 * Returns given user's institution name, the session institution_name, or "the public" if a user is
 * not logged in and there is no session institution set.
 */
export const getInstitutionName = (user?: User): string => {
  if (user && user.institution) {
    return user.institution.name;
  } else if (sessionStorage.getItem('institution_name')) {
    return sessionStorage.getItem('institution_name');
  } else {
    return 'the public';
  }
};

export const ACCEPTABLE_EMAIL_FORMATS = `Acceptable formats: ${ALLOWED_DOMAINS.map(d => `name\`@\`**${d}**`).join(', ')}`;
