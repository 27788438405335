import {CommonModule} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ButtonsModule} from '@buttons/buttons.module';
import {AddPermissionComponent} from '@commons/add-permission/add-permission.component';
import {AddRelatedResourceDialogComponent} from '@commons/add-related-resource-dialog/add-related-resource-dialog.component';
import {CommonsDatasetCreateEditComponent} from '@commons/commons-dataset-create-edit/commons-dataset-create-edit.component';
import {CommonsDatasetListComponent} from '@commons/commons-dataset-list/commons-dataset-list.component';
import {CommonsDatasetTileComponent} from '@commons/commons-dataset-tile/commons-dataset-tile.component';
import {CommonsEulaDialogComponent} from '@commons/commons-eula-dialog/commons-eula-dialog.component';
import {CommonsFileUploadComponent} from '@commons/commons-file-upload/commons-file-upload.component';
import {CommonsHipaaWarningComponent} from '@commons/commons-hipaa-warning/commons-hipaa-warning.component';
import {CommonsHomeComponent} from '@commons/commons-home/commons-home.component';
import {CommonsMenuModule} from '@commons/commons-menu/commons-menu.module';
import {CommonsProjectActionsComponent} from '@commons/commons-project-actions/commons-project-actions.component';
import {CommonsProjectDocumentComponent} from '@commons/commons-project-document/commons-project-document.component';
import {CommonsProjectListComponent} from '@commons/commons-project-list/commons-project-list.component';
import {CommonsProjectTileComponent} from '@commons/commons-project-tile/commons-project-tile.component';
import {CommonsSearchComponent} from '@commons/commons-search/commons-search.component';
import {HsdDownloadDialogComponent} from '@commons/hsd-download-dialog/hsd-download-dialog.component';
import {HsdUploadDialogComponent} from '@commons/hsd-upload-dialog/hsd-upload-dialog.component';
import {PublishDatasetDialogComponent} from '@commons/publish-dataset-dialog/publish-dataset-dialog.component';
import {SensitiveDataDialogComponent} from '@commons/sensitive-data-dialog/sensitive-data-dialog.component';
import {VPNDisconnectDialogComponent} from '@commons/vpndisconnect-dialog/vpndisconnect-dialog.component';
import {VpnWarningComponent} from '@app/vpn-warning/vpn-warning.component';
import {DatasetDetailsCardComponent} from '@dataset/dataset-details-card/dataset-details-card.component';
import {FormControlsModule} from '@form-controls/form-controls.module';
import {MaterialModule} from '@material/material.module';
import {NavigationModule} from '@navigation/navigation.module';
import {PortalModule} from '@portal/portal.module';
import {CommonsProjectCreateEditComponent} from '@private-commons/commons-project-create-edit/commons-project-create-edit.component';
import {PrivateCommonsRoutingModule} from '@private-commons/private-commons-routing.module';
import {PrivateProjectComponent} from '@private-commons/private-project/private-project.component';
import {ButtonsCardComponent} from '@project/buttons-card/buttons-card.component';
import {DatasetsCardComponent} from '@project/datasets-card/datasets-card.component';
import {DetailsCardComponent} from '@project/details-card/details-card.component';
import {DocumentsCardComponent} from '@project/documents-card/documents-card.component';
import {ResourcesCardComponent} from '@project/resources-card/resources-card.component';
import {PublicProjectComponent} from '@public-commons/public-project/public-project.component';
import {PipesModule} from '@shared/pipes/pipes.module';
import {MarkdownModule} from 'ngx-markdown';
import {CommonsInstitutionLogoComponent} from './commons-institution-logo/commons-institution-logo.component';
import {CommonsRoutingModule} from './commons-routing.module';
import {CommonsComponent} from './commons.component';

@NgModule({
  declarations: [
    AddPermissionComponent,
    AddRelatedResourceDialogComponent,
    CommonsComponent,
    CommonsDatasetCreateEditComponent,
    CommonsDatasetListComponent,
    CommonsDatasetTileComponent,
    CommonsEulaDialogComponent,
    CommonsFileUploadComponent,
    CommonsHipaaWarningComponent,
    CommonsHomeComponent,
    CommonsInstitutionLogoComponent,
    CommonsProjectActionsComponent,
    CommonsProjectCreateEditComponent,
    CommonsProjectDocumentComponent,
    CommonsProjectListComponent,
    CommonsProjectTileComponent,
    CommonsSearchComponent,
    HsdDownloadDialogComponent,
    HsdUploadDialogComponent,
    PublishDatasetDialogComponent,
    SensitiveDataDialogComponent,
    VPNDisconnectDialogComponent,
    VpnWarningComponent,
    PrivateProjectComponent,
    PublicProjectComponent,
    DatasetsCardComponent,
    DatasetDetailsCardComponent,
    ResourcesCardComponent,
    ButtonsCardComponent,
    DocumentsCardComponent,
    DetailsCardComponent,
  ],
  imports: [
    ButtonsModule,
    CommonModule,
    CommonsMenuModule,
    CommonsRoutingModule,
    FlexLayoutModule,
    FormControlsModule,
    FormsModule,
    MarkdownModule.forRoot({loader: HttpClient}),
    MaterialModule,
    NavigationModule,
    PipesModule,
    PortalModule,
    ReactiveFormsModule,
    PrivateCommonsRoutingModule,
  ],
  exports: [
    AddPermissionComponent,
    AddRelatedResourceDialogComponent,
    CommonsComponent,
    CommonsDatasetCreateEditComponent,
    CommonsDatasetListComponent,
    CommonsDatasetTileComponent,
    CommonsEulaDialogComponent,
    CommonsFileUploadComponent,
    CommonsHipaaWarningComponent,
    CommonsHomeComponent,
    CommonsInstitutionLogoComponent,
    CommonsProjectActionsComponent,
    CommonsProjectCreateEditComponent,
    CommonsProjectDocumentComponent,
    CommonsProjectListComponent,
    CommonsProjectTileComponent,
    CommonsSearchComponent,
    HsdDownloadDialogComponent,
    HsdUploadDialogComponent,
    PublishDatasetDialogComponent,
    SensitiveDataDialogComponent,
    VPNDisconnectDialogComponent,
    PrivateProjectComponent,
    PublicProjectComponent,
    DatasetDetailsCardComponent,
    VpnWarningComponent,
  ],
})
export class CommonsModule {}
