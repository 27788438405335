import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ACCEPTABLE_EMAIL_FORMATS} from '@authentication/login-service';
import {getRoleTooltip, IrbInvestigator, UserPermission, UserPermissionMap} from '@shared/types/commons-types';
import {ValidateLandingServiceInstitutionEmail} from '@shared/validators/landing_service_email.validator';
import {DatasetRoleID, ProjectUserRoleReference} from '@services/landing-service';

interface PermissionKeyVal {
  key: string;
  value: string;
  tooltip: string;
}

@Component({
  selector: 'app-add-permission',
  templateUrl: './add-permission.component.html',
  styleUrls: ['./add-permission.component.scss'],
})
export class AddPermissionComponent implements OnInit {
  emailControl = new UntypedFormControl('', [Validators.required, ValidateLandingServiceInstitutionEmail]);
  irbInvestigators: IrbInvestigator[];
  permissionControl = new UntypedFormControl('', Validators.required);
  hasIrbNumber: boolean;
  isDataset: boolean;
  permissions: PermissionKeyVal[];
  emailFormatsText = ACCEPTABLE_EMAIL_FORMATS;
  projectTeamMembers: ProjectUserRoleReference[];

  constructor(
    public dialogRef: MatDialogRef<AddPermissionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UserPermissionMap,
  ) {}

  ngOnInit() {
    if (this.data.userPermission) {
      this.emailControl.patchValue(this.data.userPermission.email);
    }
    this.permissions = Object.entries(this.data.permissionsMap)
      .map(([key, value]) => {
        return {
          key,
          value,
          tooltip: getRoleTooltip(value),
        };
      })
      .filter(p => p.value !== 'DATASET CUSTOMER'); // Remove 'DATASET CUSTOMER' as an option.

    const permission = this.permissions.find(p => p.key === this.data.userPermission.role.toString());
    this.permissionControl.patchValue(permission);
    this.isDataset = this.data.isDataset || false;

    if (this.isDataset) {
      this.irbInvestigators = this.data.irbInvestigators;
      this.projectTeamMembers = this.data.projectTeamMembers;
      this.hasIrbNumber = this.data.hasIrbNumber;
    }
  }

  get isLoaded(): boolean {
    return !!(this.data && this.permissions && this.permissionControl && this.emailControl);
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }

  validate() {
    this.emailControl.markAsTouched();
    this.emailControl.updateValueAndValidity();
    this.permissionControl.markAsTouched();
    this.permissionControl.updateValueAndValidity();

    return !(this.emailControl.errors || this.permissionControl.errors);
  }

  submit(): void {
    if (this.validate()) {
      this.dialogRef.close({
        email: this.emailControl.value,
        role: this.permissionControl.value.key,
      } as UserPermission);
    }
  }
}
