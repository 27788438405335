// Autogenerated by util/sync-labels/sync-labels.ts
// Do not edit this file directly.
// To regenerate this file, start ithriv_landing_service locally on port 8000 and
// run the following command in the root directory of the ithriv_web repository:
// > npm run sync_labels

export enum OtherSensitiveData {
  NONE = 'none',
  HIV_STATUS = 'hiv-status',
  PSYCHIATRIC = 'psychiatric',
  FINANCIAL = 'financial',
  EMPLOYMENT = 'employment',
  STUDENT = 'student',
  CONSUMER = 'consumer',
  CRIMINAL = 'criminal',
  ADDICTION_STATUS = 'addiction-status',
  SEXUAL_HEALTH = 'sexual-health',
  GENDER = 'gender',
  PII = 'pii',
  SSN = 'ssn',
  CUI = 'cui',
  OTHER_SENSITIVE = 'other-sensitive',
}

export const OtherSensitiveDataLabels = {
  none: 'NONE',
  'hiv-status': 'HIV Status',
  psychiatric: 'Psychiatric',
  financial: 'Financial',
  employment: 'Employment',
  student: 'Student',
  consumer: 'Consumer',
  criminal: 'Criminal',
  'addiction-status': 'Addiction status',
  'sexual-health': 'Sexual Health',
  gender: 'Gender Identity',
  pii: 'Personally Identifiable Information',
  ssn: 'Social Security Number',
  cui: 'Controlled Unclassified Information (CUI)',
  'other-sensitive': 'Other Sensitive',
} as const;

export type OtherSensitiveDataId = keyof typeof OtherSensitiveDataLabels;
export type OtherSensitiveDataLabel = typeof OtherSensitiveDataLabels[OtherSensitiveDataId];
