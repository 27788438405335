import {Component, Input} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {getSessionInstitutionId} from '@authentication/login-service';
import {PUBLIC_INSTITUTION_ID} from '@shared/constants/constants';
import {ResourceApiService} from '@services/resource-api/resource-api.service';
import {Category} from '@shared/types/category';
import {User} from '@shared/types/user';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent {
  categories: Category[];
  user: User;
  publicpage: boolean;
  @Input() isExpanded: boolean;
  childCategory: Category;

  constructor(
    private api: ResourceApiService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.loadUser();
    this.loadCategories();

    this.route.queryParamMap.subscribe(params => {
      const institutionId = getSessionInstitutionId();
      if (params.has('publicpage')) {
        this.publicpage = !!params.get('publicpage');
      } else {
        this.publicpage = institutionId === PUBLIC_INSTITUTION_ID;
      }
    });

    // Watch both the route and router for changes.
    this.route.paramMap.subscribe(_ => {
      this.loadChildCategory();
    });

    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.loadChildCategory();
      }
    });
  }

  loadCategories() {
    this.api.getRootCategories().subscribe(categories => {
      this.categories = categories;
    });
  }

  loadUser() {
    this.api.getSession().subscribe(user => {
      this.user = user;
    });
  }

  goCategory(category: Category) {
    const viewPrefs = this.api.getViewPreferences();
    const isNetworkView = viewPrefs && viewPrefs.hasOwnProperty('isNetworkView') ? viewPrefs.isNetworkView : true;
    const catId = category.id.toString();

    if (isNetworkView) {
      this.router.navigate(['network', catId]);
    } else {
      this.router.navigate(['browse', catId]);
    }
  }

  /**
   * If router is on the category details screen, get the child category ID from the URL.
   */
  loadChildCategory() {
    const categoryIdMatches = this.router.url.match(/^\/category\/([0-9]+)$/);

    if (categoryIdMatches && categoryIdMatches.length === 2) {
      const categoryId = parseInt(categoryIdMatches[1], 10);
      this.api.getCategory(categoryId).subscribe(c => (this.childCategory = c));
    } else {
      this.childCategory = undefined;
    }
  }
}
