import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {
  CommonsEulaDialogComponent,
  CommonsEulaDialogData,
} from '@commons/commons-eula-dialog/commons-eula-dialog.component';
import {BreadcrumbsHistoryService} from '@services/breadcrumbs-history/breadcrumbs-history.service';
import {CommonsApiService} from '@services/commons-api/commons-api.service';
import {ResourceApiService} from '@services/resource-api/resource-api.service';
import {UserService} from '@services/user-service/user.service';
import {VPNCheckService} from '@services/vpnCheckService/vpn-check.service';
import {CommonsSearchDataset, CommonsSearchProject} from '@shared/types/commons-types';
import {SearchEmit} from '@shared/types/search-emit';
import {User} from '@shared/types/user';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-commons-home',
  templateUrl: './commons-home.component.html',
  styleUrls: ['./commons-home.component.scss'],
})
export class CommonsHomeComponent implements OnInit, OnDestroy {
  @Input() publicCommons: boolean;
  subs: Subscription[] = [];
  projects: CommonsSearchProject[];
  datasets: CommonsSearchDataset[];
  userIsOnVPN: boolean;
  dialogRef: MatDialogRef<CommonsEulaDialogComponent, CommonsEulaDialogData>;
  default_filter = '*';
  searchMode: 'projects' | 'datasets' = 'projects';
  searchString: string;

  constructor(
    public cas: CommonsApiService,
    public api: ResourceApiService,
    public dialog: MatDialog,
    public changeDetectorRef: ChangeDetectorRef,
    public vpnCheckService: VPNCheckService,
    public route: ActivatedRoute,
    public router: Router,
    public breadcrumbsHistoryService: BreadcrumbsHistoryService,
    public userService: UserService,
  ) {
    this.subs = [];
  }

  get user(): User {
    return this.userService.getUser();
  }

  get userHasAcceptedEula(): boolean {
    if (this.publicCommons) {
      return true;
    } else {
      return !!(this.user && this.user.commons_eula_accepted);
    }
  }

  get shouldShowCommons(): boolean {
    return this.vpnCheckService.userHasLandingService;
  }

  get showProjectCreateButton(): boolean {
    return (
      this.user &&
      this.userHasLandingService &&
      this.userIsOnVPN &&
      this.userCanCreateProject &&
      !this.publicCommons &&
      this.searchMode === 'projects'
    );
  }

  get userHasLandingService(): boolean {
    return this.vpnCheckService.userHasLandingService;
  }

  get userCanCreateProject(): boolean {
    return this.vpnCheckService.userIsLandingServiceAdmin;
  }

  get isLoaded(): boolean {
    const resultsLoaded =
      (this.searchMode === 'projects' && !!this.projects) || (this.searchMode === 'datasets' && !!this.datasets);
    if (this.publicCommons) {
      return resultsLoaded;
    }
    return !!(this.user && resultsLoaded);
  }

  ngOnInit() {
    // Load public projects
    if (this.publicCommons) {
      this.loadProjects();
    }
    // Load private projects
    if (!this.publicCommons) {
      if (!this.userHasAcceptedEula) {
        this.displayCommonsEulaDialog();
      } else {
        this.checkVPNStatus();
        setTimeout(async () => {
          this.loadProjects;
        }, 1000);
      }
    }
  }

  async loadProjects() {
    this.projects = await this.api.getProjects(this.default_filter, this.user, this.publicCommons);
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
    this.subs = [];
    this.changeDetectorRef.detach();
  }

  displayCommonsEulaDialog() {
    this.dialogRef = this.dialog.open(CommonsEulaDialogComponent, {
      height: '400px',
      width: '500px',
      data: {confirm: false},
    });

    const dialogSub = this.dialogRef.afterClosed().subscribe((data: CommonsEulaDialogData) => {
      if (data && typeof data === 'object' && data.hasOwnProperty('confirm')) {
        const eulaSub = this.api.acceptCommonsEula(this.user, !!data.confirm).subscribe(async _ => {
          await this.userService.refresh();
          if (this.userHasAcceptedEula) {
            await this.loadProjects();
          }
        });

        this.subs.push(eulaSub);
      }
    });

    this.subs.push(dialogSub);
  }

  async getSearchResults(searchEmit: SearchEmit) {
    this.searchMode = searchEmit.searchMode;
    this.searchString = searchEmit.searchString;
    if (searchEmit.searchMode === 'projects') {
      this.projects = await this.api.getProjects(searchEmit.searchString, this.user, this.publicCommons);
    }

    if (searchEmit.searchMode === 'datasets') {
      this.datasets = await this.api.getDatasets(searchEmit.searchString, this.user, this.publicCommons);
    }

    this.changeDetectorRef.detectChanges();
  }

  hasNoResults(): boolean {
    return !!(
      this.searchString &&
      ((this.searchMode === 'datasets' && (!this.datasets || this.datasets.length === 0)) ||
        (this.searchMode === 'projects' && (!this.projects || this.projects.length === 0)))
    );
  }

  showDatasetList() {
    return this.searchMode === 'datasets' && this.datasets.length > 0;
  }

  private async loadProjectsIfVPNStatusHasChanged(isConnected) {
    const statusHasChanged = this.userIsOnVPN !== isConnected;
    this.userIsOnVPN = isConnected;

    if (isConnected && statusHasChanged) {
      await this.loadProjects();
    }
    return isConnected;
  }

  private checkVPNStatus() {
    return new Promise<boolean>(async resolve => {
      await this.loadProjectsIfVPNStatusHasChanged(this.vpnCheckService.userIsOnVPN);
      this.vpnCheckService.updated.subscribe(async isConnected => {
        await this.loadProjectsIfVPNStatusHasChanged(isConnected);
        resolve(isConnected);
      });
    });
  }
}
