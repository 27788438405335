import {LabelTextIDResponse} from '@services/landing-service';
import {DatasetAggregationLevelLabels} from '@shared/labels/dataset-aggregation-level';
import {
  IdentifierHipaa,
  IdentifierHipaaId,
  IdentifierHipaaLabel,
  IdentifierHipaaLabels,
} from '@shared/labels/identifier-hipaa';
import {OtherSensitiveDataLabels} from '@shared/labels/other-sensitive-data';
import {SensitivityLevel, SensitivityLevelId} from '@shared/labels/sensitivity-level';
import {omit} from 'lodash-es';

export const DEFAULT_HOME_TABINDEX = 2;

// TODO: SHOULD BE POPULATED FROM API
export const AllowedMimeTypes: {readonly [key: string]: readonly string[]} = {
  CSV: ['text/plain', 'text/csv', 'application/csv'],
  JSON: ['application/json'],
  PDF: ['application/pdf'],
  IMAGE: ['image/png', 'image/jpeg', 'image/gif', 'image/bmp', 'image/svg+xml', 'image/tiff', 'image/webp'],
  MS_WORD: [
    'application/msword',
    'application/CDFV2',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ],
  MS_EXCEL: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'],
  MS_PPT: [
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/x-ole-storage',
  ],
  DICOM: ['application/dicom'],
  ZIP: ['application/zip'],
  FCS: ['application/vnd.isac.fcs'],
} as const;

export const ALLOWED_MIME_TYPES: string[] = Object.values(AllowedMimeTypes).flat();

export type PossibleHIPAAWarning =
  | 'PossibleDateData'
  | 'PossiblePhoneData'
  | 'PossibleMRNData'
  | 'PossibleSSNData'
  | 'PossibleZIPData'
  | 'PossibleEmailData';

export const ITHRIV_INSTITUTION_ID = 92;

export const PUBLIC_INSTITUTION_ID = 87;

export const DATASET_ROLES = {
  admin: 'DATASET ADMINISTRATOR',
  collaborator: 'DATASET COLLABORATOR',
  customer: 'DATASET CUSTOMER',
};

export enum SafeType {
  HTML = 'html',
  STYLE = 'style',
  SCRIPT = 'script',
  URL = 'url',
  RESOURCEURL = 'resourceUrl',
  DEFAULT = 'default',
}

export const ProjectFlags = {
  CAN_VIEW_META: '_can_view_meta',
  CAN_MANAGE_PERMISSION: '_can_manage_permission',
  CAN_UPDATE_META: '_can_update_meta',
  CAN_DELETE_META: '_can_delete_meta',
  CAN_UPLOAD_DATA: '_can_upload_data',
  CAN_DELETE_DATA: '_can_delete_data',
  CAN_DOWNLOAD_DATA: '_can_download_data',
} as const;

export type ProjectFlagKey = keyof typeof ProjectFlags;
export type ProjectFlagValue = (typeof ProjectFlags)[ProjectFlagKey];

export const DatasetFlags = {
  CAN_VIEW_META: '_can_view_meta',
  CAN_MANAGE_PERMISSION: '_can_manage_permission',
  CAN_UPDATE_META: '_can_update_meta',
  CAN_DELETE_META: '_can_delete_meta',
  CAN_DELETE_DATA: '_can_delete_data',
  CAN_UPLOAD_DATA: '_can_upload_data',
  CAN_DOWNLOAD_DATA: '_can_download_data',
} as const;

export type DatasetFlagKey = keyof typeof DatasetFlags;
export type DatasetFlagValue = (typeof DatasetFlags)[DatasetFlagKey];

export type IdentifierHipaaBase = {
  id: IdentifierHipaa;
  text: IdentifierHipaaLabel;
  sensitivity_level: SensitivityLevel;
};

// HIPAA Identifiers grouped by identifier ID
export type IdentifierHipaaMap = {
  [key in IdentifierHipaaId]?: IdentifierHipaaBase;
};

export const IDENTIFIERS_HIPAA: IdentifierHipaaMap = {
  na: {
    id: IdentifierHipaa.NA,
    text: IdentifierHipaaLabels[IdentifierHipaa.NA],
    sensitivity_level: SensitivityLevel.NA,
  },
  none: {
    id: IdentifierHipaa.NONE,
    text: IdentifierHipaaLabels[IdentifierHipaa.NONE],
    sensitivity_level: SensitivityLevel.DE_ID,
  },
  'zip-short': {
    id: IdentifierHipaa.ZIP_SHORT,
    text: IdentifierHipaaLabels[IdentifierHipaa.ZIP_SHORT],
    sensitivity_level: SensitivityLevel.DE_ID,
  },
  region: {
    id: IdentifierHipaa.REGION,
    text: IdentifierHipaaLabels[IdentifierHipaa.REGION],
    sensitivity_level: SensitivityLevel.DE_ID,
  },
  'dates-year': {
    id: IdentifierHipaa.DATES_YEAR,
    text: IdentifierHipaaLabels[IdentifierHipaa.DATES_YEAR],
    sensitivity_level: SensitivityLevel.DE_ID,
  },
  'dates-shifted': {
    id: IdentifierHipaa.DATES_SHIFTED,
    text: IdentifierHipaaLabels[IdentifierHipaa.DATES_SHIFTED],
    sensitivity_level: SensitivityLevel.DE_ID,
  },
  'dates-full': {
    id: IdentifierHipaa.DATES_FULL,
    text: IdentifierHipaaLabels[IdentifierHipaa.DATES_FULL],
    sensitivity_level: SensitivityLevel.LDS,
  },
  ages: {
    id: IdentifierHipaa.AGES,
    text: IdentifierHipaaLabels[IdentifierHipaa.AGES],
    sensitivity_level: SensitivityLevel.LDS,
  },
  'city-zip': {
    id: IdentifierHipaa.CITY_ZIP,
    text: IdentifierHipaaLabels[IdentifierHipaa.CITY_ZIP],
    sensitivity_level: SensitivityLevel.LDS,
  },
  'medical-record-num': {
    id: IdentifierHipaa.MEDICAL_RECORD_NUM,
    text: IdentifierHipaaLabels[IdentifierHipaa.MEDICAL_RECORD_NUM],
    sensitivity_level: SensitivityLevel.HSD,
  },
  names: {
    id: IdentifierHipaa.NAMES,
    text: IdentifierHipaaLabels[IdentifierHipaa.NAMES],
    sensitivity_level: SensitivityLevel.HSD,
  },
  addresses: {
    id: IdentifierHipaa.ADDRESSES,
    text: IdentifierHipaaLabels[IdentifierHipaa.ADDRESSES],
    sensitivity_level: SensitivityLevel.HSD,
  },
  'email-phone-fax': {
    id: IdentifierHipaa.EMAIL_PHONE_FAX,
    text: IdentifierHipaaLabels[IdentifierHipaa.EMAIL_PHONE_FAX],
    sensitivity_level: SensitivityLevel.HSD,
  },
  'social-security': {
    id: IdentifierHipaa.SOCIAL_SECURITY,
    text: IdentifierHipaaLabels[IdentifierHipaa.SOCIAL_SECURITY],
    sensitivity_level: SensitivityLevel.HSD,
  },
  'biometric-id': {
    id: IdentifierHipaa.BIOMETRIC_ID,
    text: IdentifierHipaaLabels[IdentifierHipaa.BIOMETRIC_ID],
    sensitivity_level: SensitivityLevel.HSD,
  },
  'certificate-num': {
    id: IdentifierHipaa.CERTIFICATE_NUM,
    text: IdentifierHipaaLabels[IdentifierHipaa.CERTIFICATE_NUM],
    sensitivity_level: SensitivityLevel.HSD,
  },
  'vehicle-id': {
    id: IdentifierHipaa.VEHICLE_ID,
    text: IdentifierHipaaLabels[IdentifierHipaa.VEHICLE_ID],
    sensitivity_level: SensitivityLevel.HSD,
  },
  'device-id': {
    id: IdentifierHipaa.DEVICE_ID,
    text: IdentifierHipaaLabels[IdentifierHipaa.DEVICE_ID],
    sensitivity_level: SensitivityLevel.HSD,
  },
  'other-unique': {
    id: IdentifierHipaa.OTHER_UNIQUE,
    text: IdentifierHipaaLabels[IdentifierHipaa.OTHER_UNIQUE],
    sensitivity_level: SensitivityLevel.HSD,
  },
} as const;

// HIPAA Identifiers grouped by sensitivity level
export type IdentifierHipaaBySensitivity = {
  [key in SensitivityLevelId]?: LabelTextIDResponse[];
};

// Group the identifiers by sensitivity level
const grouped: IdentifierHipaaBySensitivity = {};

for (const o of Object.values(IDENTIFIERS_HIPAA)) {
  if (!grouped[o.sensitivity_level]) {
    grouped[o.sensitivity_level] = [] as LabelTextIDResponse[];
  }

  grouped[o.sensitivity_level].push({id: o.id, text: o.text} as LabelTextIDResponse);
}

// Useful subgroups of HIPAA identifiers for form population, validation, and display logic
export const HIPAA_OPTIONS = Object.values(IDENTIFIERS_HIPAA).map(o => omit(o, 'sensitivity_level'));
export const NA_HIPAA_OPTIONS = grouped[SensitivityLevel.NA];
export const DEID_HIPAA_OPTIONS = grouped[SensitivityLevel.DE_ID];
export const LDS_HIPAA_OPTIONS = grouped[SensitivityLevel.LDS];
export const DEID_LDS_HIPAA_OPTIONS = [].concat(DEID_HIPAA_OPTIONS, LDS_HIPAA_OPTIONS);
export const HSD_HIPAA_OPTIONS = grouped[SensitivityLevel.HSD];
export const DATA_AGGREGATION = Object.values(DatasetAggregationLevelLabels);
export const OTHER_SENSITIVE_DATA_OPTIONS = Object.values(OtherSensitiveDataLabels).map(v => v.toString());

export const REDCAP_TOKEN_PLACEHOLDER = '*'.repeat(32);
