/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum IdentifierHipaaID {
    NA = 'na',
    NONE = 'none',
    ZIP_SHORT = 'zip-short',
    REGION = 'region',
    DATES_YEAR = 'dates-year',
    CITY_ZIP = 'city-zip',
    DATES_FULL = 'dates-full',
    AGES = 'ages',
    NAMES = 'names',
    ADDRESSES = 'addresses',
    EMAIL_PHONE_FAX = 'email-phone-fax',
    SOCIAL_SECURITY = 'social-security',
    MEDICAL_RECORD_NUM = 'medical-record-num',
    CERTIFICATE_NUM = 'certificate-num',
    VEHICLE_ID = 'vehicle-id',
    DEVICE_ID = 'device-id',
    BIOMETRIC_ID = 'biometric-id',
    OTHER_UNIQUE = 'other-unique',
}
