// Autogenerated by util/sync-labels/sync-labels.ts
// Do not edit this file directly.
// To regenerate this file, start ithriv_landing_service locally on port 8000 and
// run the following command in the root directory of the ithriv_web repository:
// > npm run sync_labels

export enum ProjectFileCategory {
  CONTRACT = 'contract',
  PROTOCOL = 'protocol',
  IRB_APPLICATION = 'irb-application',
  IRB_APPROVAL = 'irb-approval',
  DATA_SECURITY_PLAN = 'data-security-plan',
  PUBLICATION = 'publication',
  OTHER = 'other',
}

export const ProjectFileCategoryLabels = {
  contract: 'Contract',
  protocol: 'Protocol',
  'irb-application': 'IRB Application',
  'irb-approval': 'IRB Approval',
  'data-security-plan': 'Data Security Plan',
  publication: 'Publication',
  other: 'Other',
} as const;

export type ProjectFileCategoryId = keyof typeof ProjectFileCategoryLabels;
export type ProjectFileCategoryLabel = typeof ProjectFileCategoryLabels[ProjectFileCategoryId];
