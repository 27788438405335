import {LayoutModule} from '@angular/cdk/layout';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
// import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AuthenticationModule} from '@authentication/authentication.module';
import {ButtonsModule} from '@buttons/buttons.module';
import {CommonsModule} from '@commons/commons.module';
import {MaterialModule} from '@material/material.module';
import {NavigationModule} from '@navigation/navigation.module';
import {PortalModule} from '@portal/portal.module';
import {DirectivesModule} from '@shared/directives/directives.module';
import {PipesModule} from '@shared/pipes/pipes.module';
import {NgxFileDropModule} from 'ngx-file-drop';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthInterceptor} from '@authentication/AuthInterceptor';
import {HomeComponent} from '@home/home.component';
import {BreadcrumbsHistoryService} from '@services/breadcrumbs-history/breadcrumbs-history.service';
import {CategoriesService} from '@services/categories/categories.service';
import {IntervalService} from '@services/interval/interval.service';
import {ResourceApiService} from '@services/resource-api/resource-api.service';
import {DummyComponent} from '@shared/mocks/dummy.component';

@NgModule({
  declarations: [AppComponent, DummyComponent, HomeComponent],
  imports: [
    AppRoutingModule,
    AuthenticationModule,
    BrowserAnimationsModule,
    BrowserModule,
    ButtonsModule,
    CommonModule,
    CommonsModule,
    DirectivesModule,
    FlexLayoutModule,
    // FormsModule,
    HttpClientModule,
    LayoutModule,
    MaterialModule,
    NavigationModule,
    NgxFileDropModule,
    PipesModule,
    PortalModule,
    // ReactiveFormsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    BreadcrumbsHistoryService,
    CategoriesService,
    IntervalService,
    ResourceApiService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
