<div style="margin-left: 1em"><app-breadcrumbs [navItems]="navItems"></app-breadcrumbs></div>
<div *ngIf="isDataLoaded && !resource.user_may_view" [hidden]="resource.user_may_view" color="warn">
  <div *ngIf="!user" class="please-log-in">
    <h1>
      The page you are trying to view is not open to the public. If applicable, please log in through your institution
      to apply your personal permissions
    </h1>
    <app-login-services hidePublic="true"></app-login-services>
  </div>
  <div *ngIf="user" class="please-log-in">
    <h1>
      You do not have permission to see this page. If you have questions about your portal permissions, you may send an
      inquiry to ithrivadmin&#64;uvahealth.org
    </h1>
  </div>
</div>
<div
  *ngIf="isDataLoaded; else loadingMessage"
  [ngClass]="{
    'mat-typography': true,
    resource: true,
    private: resource.private
  }"
  class="mat-typography resource"
  [hidden]="!resource.user_may_view"
  [@zoomTransition]="transitionState"
>
  <mat-toolbar-row class="category-color" [appCategoryColorBorder]="resource"></mat-toolbar-row>
  <mat-toolbar-row *ngIf="resource.approved === 'Unapproved' || resource.approved === 'Requested'" class="draft">
    DRAFT RESOURCE/EVENT: This page is visible only to owners and admins at this time. Submit for approval to admin to
    share with other Portal users.
  </mat-toolbar-row>
  <mat-card>
    <div *ngIf="resource" fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="1em" fxLayoutAlign="center">
      <div fxFlex.xl="75%" fxFlex.lg="75%" fxFlex.md="67%" fxFlex.sm="50%" fxFlex.xs="100%">
        <h1 *ngIf="resource.website" class="mat-display-2 resource-name" (click)="goWebsite($event)">
          <a [href]="resource.website">{{ resource.name }}</a>
        </h1>
        <h1 *ngIf="!resource.website" class="mat-display-2">
          {{ resource.name }}
        </h1>
        <div class="button-row" *ngIf="resource.website || resource.user_may_edit">
          <a
            *ngIf="resource.website"
            mat-flat-button
            [href]="resource.website"
            target="_blank"
            color="primary"
            id="resource-website"
          >
            <mat-icon>exit_to_app</mat-icon>
            Visit Website
          </a>
          <button *ngIf="resource.user_may_edit" mat-flat-button (click)="openEdit()" color="accent" id="resource-edit">
            <mat-icon>edit</mat-icon>
            Edit {{ resource.segment.name }}
          </button>
          <button
            type="button"
            mat-raised-button
            *ngIf="resource.approved === 'Unapproved'"
            color="primary"
            id="submitForApproval"
            (click)="submitForApproval()"
          >
            Submit for approval
          </button>
          <button type="button" mat-raised-button *ngIf="resource.approved === 'Requested'" color="primary" disabled>
            Approval requested
          </button>
        </div>

        <markdown [data]="resource.description"></markdown>
        <div
          class="container"
          fxLayout="row wrap"
          fxLayout.xs="column"
          fxLayoutAlign="center center"
          fxLayoutGap="10px"
          fxLayoutGap.xs="0"
        >
          <div *ngIf="resource.institution.name !== 'Public'">
            <a class="available-institution">
              <img
                [alt]="resource.institution.name"
                [src]="getInstitutionLogo()"
                matTooltip="{{ resource.institution.name }}"
              />
            </a>
          </div>
        </div>
      </div>
      <mat-card fxFlex.xl="25%" fxFlex.lg="25%" fxFlex.md="33%" fxFlex.sm="50%" fxFlex.xs="100%">
        <app-approved-badge [resource]="resource" [user]="user"></app-approved-badge>
        <mat-card-title> Information </mat-card-title>
        <mat-card-content>
          <div *ngIf="showToggle()">
            <app-public-private-toggle
              *ngIf="resource.user_may_edit"
              [selected_option]="resource.private ? 'PRIVATE' : 'PUBLIC'"
              (toggle)="togglePrivate($event)"
              tooltipPrivate="Click here to allow only users from {{
                resource.institution.name
              }} to view this resource."
              tooltipPublic="Click here to allow all iTHRIV users to access this resource."
              labelPrivate="PRIVATE"
              labelPublic="NOT PRIVATE"
            ></app-public-private-toggle>
          </div>
          <mat-list dense>
            <mat-list-item *ngIf="resource.segment.name === 'Event'">
              <h4 mat-line>Event Location:</h4>
              <p mat-line>{{ resource.location }}</p>
            </mat-list-item>
            <mat-list-item *ngIf="resource.segment.name === 'Event'">
              <h4 mat-line>Event Starts:</h4>
              <p mat-line>{{ loadDate(resource.starts) }}</p>
            </mat-list-item>
            <mat-list-item *ngIf="resource.segment.name === 'Event'">
              <h4 mat-line>Event Ends:</h4>
              <p mat-line>{{ loadDate(resource.ends) }}</p>
            </mat-list-item>
            <mat-list-item *ngIf="resource.segment.name === 'Event'">
              <h4 mat-line>Add to Calendar:</h4>
            </mat-list-item>
            <mat-list-item *ngIf="resource.segment.name === 'Event'">
              <button
                mat-flat-button
                *ngIf="resource.segment.name === 'Event'"
                title="Add to Calendar"
                class="addeventatc"
                (click)="handleClick($event)"
                color="white"
                matTooltip="Click to add to calender"
              >
                Click Here
                <span class="arrow">&nbsp;</span>
                <span class="start">{{ resource.starts | date: 'MM/d/y h:mm a' }}</span>
                <span class="end">{{ resource.ends | date: 'MM/d/y h:mm a' }}</span>
                <span class="timezone"></span>
                <span class="title">{{ resource.name }}</span>
                <span class="description">{{ getResourceOrEventURL(resource) }}</span>
                <span class="location">{{ resource.location }}</span>
                <span class="alarm_reminder">1440</span>
              </button>
            </mat-list-item>
            <mat-list-item *ngIf="resource.type">
              <h4 mat-line>Type</h4>
              <a mat-line href="#" (click)="goResourceType($event, resource.type)">{{ resource.type.name }}</a>
            </mat-list-item>
            <mat-list-item *ngIf="resource.institution">
              <h4 mat-line>Source Organization</h4>
              <a mat-line href="#" (click)="goInstitution($event, resource.institution)">{{
                resource.institution.name
              }}</a>
            </mat-list-item>
            <mat-list-item *ngIf="resource.availabilities">
              <h4 mat-line>Curated For:</h4>
              <a
                *ngFor="let institution of getAvailableInstitutions()"
                mat-line
                href="#"
                (click)="goInstitution($event, institution)"
                >{{ institution.name }}</a
              >
            </mat-list-item>
            <mat-list-item *ngIf="resource.cost">
              <h4 mat-line>Cost Type</h4>
              <p mat-line>{{ resource.cost }}</p>
            </mat-list-item>
            <mat-list-item *ngIf="resource.contact_notes || resource.contact_email || resource.contact_phone">
              <h4 mat-line>Contact</h4>
              <p mat-line *ngIf="resource.contact_notes">
                {{ resource.contact_notes }}
              </p>
              <p mat-line *ngIf="resource.contact_email">
                <a href="mailto://{{ resource.contact_email }}">{{ resource.contact_email }}</a>
              </p>
              <p mat-line *ngIf="resource.contact_phone">
                {{ resource.contact_phone }}
              </p>
            </mat-list-item>
            <mat-list-item *ngIf="categories">
              <h4 mat-line>Categories</h4>
              <div class="category" *ngFor="let rc of categories" mat-line>
                <a href="#" (click)="goCategory($event, rc.category.parent.parent)">{{
                  rc.category.parent.parent.name
                }}</a>
                <mat-icon class="category-separator">chevron_right</mat-icon>
                <a href="#" (click)="goCategory($event, rc.category.parent)" matTooltip="{{ rc.category.parent.name }}">
                  <mat-icon class="category-icon" svgIcon="{{ getCategoryIcon(rc.category) }}"></mat-icon>
                </a>
                <mat-icon class="category-separator">chevron_right</mat-icon>
                <a
                  href="#"
                  (click)="goCategory($event, rc.category)"
                  matTooltip="{{ rc.category.parent.parent.name }} > {{ rc.category.parent.name }} > {{
                    rc.category.name
                  }}"
                  >{{ rc.category.name }}</a
                >
              </div>
            </mat-list-item>
            <mat-list-item *ngIf="resource.files && resource.files.length > 0">
              <h4 mat-line>Attachments</h4>
              <a *ngFor="let file of resource.files" mat-line (click)="openInTab(file.url)">
                <img [alt]="file.display_name || file.name || file.file_name" [src]="fileIcon(file)" />
                {{ file.display_name || file.name || file.file_name }}
              </a>
            </mat-list-item>
            <app-favorite-resource-button [resource]="resource" [user]="user"></app-favorite-resource-button>
          </mat-list>
        </mat-card-content>
        <mat-card-actions>
          <a
            *ngIf="resource.website"
            mat-button
            [href]="resource.website"
            target="_blank"
            color="primary"
            id="resource-actions-website"
          >
            VISIT WEBSITE
          </a>
          <div fxFlex></div>
          <app-edit-resource-button [resource]="resource"></app-edit-resource-button>
        </mat-card-actions>
      </mat-card>
    </div>
    <div *ngIf="!resource">
      <mat-progress-spinner></mat-progress-spinner>
    </div>
  </mat-card>
</div>

<ng-template #loadingMessage>
  <app-loading message="Loading Resource"></app-loading>
</ng-template>
