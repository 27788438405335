<mat-form-field
  *ngIf="isNormalField(field)"
  [attr.app-form-field-name]="field.name"
  [hideRequiredMarker]="false"
  [matTooltip]="field.tooltip"
  [fxHide]="field.hidden"
  [formGroup]="field.formGroup"
>
  <mat-label *ngIf="field.label">{{ field.label }}</mat-label>
  <div *ngIf="field.markdownAbove" class="markdown-above">
    <markdown>{{ field.markdownAbove }}</markdown>
  </div>

  <input
    *ngIf="field.formControl && field.type === 'color'"
    [colorPicker]="field.formControl.value"
    [cpCancelButton]="true"
    [cpOKButton]="true"
    [cpPositionOffset]="'50%'"
    [cpPosition]="'top'"
    [errorStateMatcher]="errorMatcher"
    [formControlName]="field.name"
    [placeholder]="field.placeholder"
    [required]="field.required"
    [style.background]="field.formControl.value"
    [value]="field.formControl.value"
    matInput
    type="text"
  />
  <input
    *ngIf="isTextField(field)"
    [errorStateMatcher]="errorMatcher"
    [formControlName]="field.name"
    [placeholder]="field.placeholder"
    [required]="field.required"
    matInput
    type="{{ field.type }}"
  />
  <textarea
    *ngIf="field.type === 'textarea'"
    [errorStateMatcher]="errorMatcher"
    [formControlName]="field.name"
    [placeholder]="field.placeholder"
    [required]="field.required"
    matInput
    type="{{ field.type }}"
  ></textarea>
  <mat-select
    *ngIf="field.type === 'select'"
    [formControlName]="field.name"
    [multiple]="field.multiSelect"
    [placeholder]="field.placeholder"
    [required]="field.required"
    (change)="field.onChange && field.onChange($event)"
  >
    <mat-option *ngIf="!field.multiSelect && !field.hideNoneOption">-- None --</mat-option>
    <mat-option
      (click)="toggleNoneOption($event, option, field.multiSelect)"
      *ngFor="let option of options"
      [value]="option.id"
    >
      <mat-icon *ngIf="field.showIcons" svgIcon="ithriv_{{ option.id }}"></mat-icon>
      {{ option.name }}
    </mat-option>
  </mat-select>
  <mat-label *ngIf="field.type === 'select'">
    {{ field.placeholder }}
  </mat-label>
  <ng-container [ngTemplateOutlet]="fieldHelpAndErrors"></ng-container>
</mat-form-field>

<div *ngIf="field.type === 'radio'" [matTooltip]="field.tooltip" [fxHide]="field.hidden" [formGroup]="field.formGroup">
  <mat-label>{{ field.label || field.placeholder }}</mat-label>
  <mat-radio-group
    class="radio-group"
    [required]="field.required"
    [formControlName]="field.name"
    (change)="field.onChange && field.onChange($event)"
  >
    <mat-radio-button
      class="radio-button"
      *ngFor="let option of options"
      [value]="option.id"
      [matTooltip]="option.tooltip"
      >{{ option.name }}</mat-radio-button
    >
  </mat-radio-group>
</div>

<div *ngIf="field.type === 'multicheckbox'" [matTooltip]="field.tooltip" [fxHide]="field.hidden">
  <mat-label>{{ field.label || field.placeholder }}</mat-label>
  <div *ngIf="field.markdownAbove" class="markdown-above">
    <markdown>{{ field.markdownAbove }}</markdown>
  </div>

  <fieldset [formGroup]="field.formGroup">
    <legend class="ghost">{{ field.label || field.placeholder }}</legend>
    <mat-selection-list
      [attr.app-form-field-name]="field.name"
      [formControlName]="field.name"
      (selectionChange)="handleSelectionListChange()"
      fxLayout="column"
    >
      <mat-list-option *ngFor="let option of options" [disabled]="option.readonly" [value]="option.id">
        <mat-icon *ngIf="field.showIcons" svgIcon="ithriv_{{ option.id }}"></mat-icon>
        {{ option.name }}
      </mat-list-option>
    </mat-selection-list>
  </fieldset>
  <ng-container [ngTemplateOutlet]="fieldHelpAndErrors"></ng-container>
</div>

<mat-form-field
  *ngIf="field.type === 'daterange'"
  [attr.app-form-field-name]="field.name"
  [matTooltip]="field.tooltip"
  [fxHide]="field.hidden"
  [formGroup]="field.formGroup"
>
  <mat-label>{{ field.label }}</mat-label>
  <div *ngIf="field.markdownAbove" class="markdown-above">
    <markdown>{{ field.markdownAbove }}</markdown>
  </div>

  <button matPrefix (click)="openDateTimePicker($event)"><mat-icon>date_range</mat-icon></button>
  <input
    #dateRangeHTMLInput
    type="text"
    matInput
    ngxDaterangepickerMd
    [locale]="{applyLabel: 'ok', format: field.showTime ? 'MM-DD-YYYY hh:mm a' : 'MM-DD-YYYY'}"
    startKey="start"
    endKey="end"
    [formControlName]="field.name"
    name="daterange"
    [ranges]="ranges"
    [showDropdowns]="true"
    [timePicker]="!!field.showTime"
    [showClearButton]="true"
    [showCancel]="true"
    [alwaysShowCalendars]="true"
    [placeholder]="field.placeholder"
    [required]="field.required"
    [defaultValue]="field.defaultValue"
  />
  <button (click)="clearValue(field.formControl)" *ngIf="!field.required" mat-icon-button matSuffix type="button">
    <mat-icon>close</mat-icon>
  </button>

  <ng-container [ngTemplateOutlet]="fieldHelpAndErrors"></ng-container>
</mat-form-field>

<div
  *ngIf="field.type === 'list'"
  [attr.app-form-field-name]="field.name"
  class="mat-typography"
  [fxHide]="field.hidden"
>
  <app-form-field-label [errors]="errors" [field]="field"></app-form-field-label>
  <div *ngIf="field.markdownAbove" class="markdown-above">
    <markdown>{{ field.markdownAbove }}</markdown>
  </div>

  <app-chip-list [field]="field" [matTooltip]="field.tooltip">
    <ng-container [ngTemplateOutlet]="fieldHelpAndErrors"></ng-container>
  </app-chip-list>
</div>

<div
  *ngIf="field.type === 'image_select'"
  [attr.app-form-field-name]="field.name"
  class="mat-typography"
  [matTooltip]="field.tooltip"
  [fxHide]="field.hidden"
>
  <mat-label>
    {{ field.label || field.placeholder }}
  </mat-label>
  <div *ngIf="field.markdownAbove" class="markdown-above">
    <markdown>{{ field.markdownAbove }}</markdown>
  </div>

  <app-image-select [field]="field" [options]="options">
    <ng-container [ngTemplateOutlet]="fieldHelpAndErrors"></ng-container>
  </app-image-select>
</div>

<div
  *ngIf="field.type === 'tree'"
  [attr.app-form-field-name]="field.name"
  class="mat-typography"
  [matTooltip]="field.tooltip"
  [fxHide]="field.hidden"
  [formGroup]="field.formGroup"
>
  <app-form-field-label [errors]="errors" [field]="field"></app-form-field-label>
  <div *ngIf="field.markdownAbove" class="markdown-above">
    <markdown>{{ field.markdownAbove }}</markdown>
  </div>
  <app-tree-select [field]="field"></app-tree-select>
</div>

<div
  *ngIf="field.type === 'files'"
  [attr.app-form-field-name]="field.name"
  class="mat-typography"
  [matTooltip]="field.tooltip"
  [fxHide]="field.hidden"
>
  <app-form-field-label [errors]="errors" [field]="field"></app-form-field-label>
  <div *ngIf="field.markdownAbove" class="markdown-above">
    <markdown>{{ field.markdownAbove }}</markdown>
  </div>
  <app-file-upload [field]="field"></app-file-upload>
</div>

<div
  *ngIf="field.type === 'boolean'"
  [attr.app-form-field-name]="field.name"
  [matTooltip]="field.tooltip"
  [fxHide]="field.hidden"
  [formGroup]="field.formGroup"
>
  <div *ngIf="field.booleanMode === 'buttonToggle'" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
    <mat-label>{{ field.label || field.placeholder }}</mat-label>
    <div *ngIf="field.markdownAbove" class="markdown-above">
      <markdown>{{ field.markdownAbove }}</markdown>
    </div>
    <mat-button-toggle-group [value]="field.formControl.value" [formControlName]="field.name">
      <mat-button-toggle *ngFor="let option of field.booleanOptions" [value]="option.value" (change)="setValue($event)">
        <mat-icon style="margin-right: 8px">{{ option.icon }}</mat-icon>
        {{ option.label }}
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <ng-container *ngIf="field.booleanMode !== 'buttonToggle'">
    <mat-label>{{ field.placeholder }}</mat-label>
    <div *ngIf="field.markdownAbove" class="markdown-above">
      <markdown>{{ field.markdownAbove }}</markdown>
    </div>

    <div *ngIf="field.booleanMode === 'checkbox'" class="checkbox-container">
      <mat-checkbox
        [formControlName]="field.name"
        [required]="field.required"
        (change)="field.onChange && field.onChange($event)"
        >{{ field.label || field.placeholder }}</mat-checkbox
      >
    </div>

    <div *ngIf="field.booleanMode === 'slideToggle'" class="toggle mat-typography">
      <mat-slide-toggle [formControlName]="field.name" color="primary">
        {{ field.placeholder }}
      </mat-slide-toggle>
    </div>
  </ng-container>

  <mat-error
    *ngIf="field.formControl.errors && (field.formControl.dirty || field.formControl.touched)"
    [formGroup]="field.formGroup"
  >
    <span *ngIf="field.required && field.formControl.hasError('required')">
      {{ field.placeholder }} is
      <strong>required</strong>
    </span>
    <span *ngIf="field.minLength && field.formControl.hasError('minlength')">
      Must be at least
      <strong>{{ field.minLength }}</strong> characters long. You only entered
      <strong>{{ currentLength() }}</strong> characters.
    </span>
    <span *ngIf="field.maxLength && field.formControl.hasError('maxlength')">
      Maximum length is
      <strong>"{{ field.maxLength }}"</strong> characters. You entered
      <strong>{{ currentLength() }}</strong> characters.
    </span>
  </mat-error>
</div>

<div *ngIf="field.markdownBelow" class="markdown-below" [fxHide]="field.hidden">
  <markdown>{{ field.markdownBelow }}</markdown>
</div>

<ng-template #fieldHelpAndErrors>
  <mat-hint *ngIf="field.helpText" align="start">
    <markdown>{{ field.helpText }}</markdown>
  </mat-hint>

  <mat-hint *ngIf="field.maxLength" align="end"> {{ currentLength() }} / {{ field.maxLength }} </mat-hint>

  <mat-error *ngIf="field.formControl.errors && (field.formControl.dirty || field.formControl.touched)">
    <span *ngIf="field.formControl.hasError('email') && !field.formControl.hasError('required')">
      Please enter a valid email address. {{ emailFormatsText }}
    </span>
    <span *ngIf="field.formControl.hasError('commaDelimitedList')">
      Please enter a comma-delimited list. The items in the list may only include alphanumeric characters, spaces, and
      hyphens.
    </span>
    <span *ngIf="field.formControl.hasError('url') && !field.formControl.hasError('required')">
      Please enter a valid URL, such as https://website.domain.com
    </span>
    <span *ngIf="field.formControl.hasError('required')">
      {{ field.placeholder }} is
      <strong>required</strong>
    </span>
    <span *ngIf="field.minLength && field.formControl.hasError('minlength')">
      Must be at least
      <strong>{{ field.minLength }}</strong> characters long. You only entered
      <strong>{{ currentLength() }}</strong> characters.
    </span>
    <span *ngIf="field.maxLength && field.formControl.hasError('maxlength')">
      Maximum length is
      <strong>"{{ field.maxLength }}"</strong> characters. You entered
      <strong>{{ currentLength() }}</strong> characters.
    </span>
    <span *ngIf="field.required && field.formControl.hasError('dateTimeRange')">
      {{ field.placeholder }} both starts and ends are
      <strong>required/formatted. </strong>
      Click to set both From: and To: values
    </span>
  </mat-error>
</ng-template>
