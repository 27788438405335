import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute} from '@angular/router';
import {getNavItems} from '@commons/commons-nav/commons-nav-util';
import {CommonsApiService} from '@services/commons-api/commons-api.service';
import {ProjectFileVersionResponse, ProjectResponse} from '@services/landing-service';
import {ResourceApiService} from '@services/resource-api/resource-api.service';
import {UserService} from '@services/user-service/user.service';
import {CommonsSearchDataset, CommonsSearchProject, getRoleTooltip} from '@shared/types/commons-types';
import {NavItem} from '@shared/types/nav-item';
import {Resource} from '@shared/types/resource';
import {scrollToRouteParam} from '@utilities/scroll-to-selector';
import {firstValueFrom, lastValueFrom} from 'rxjs';
import {VPNCheckService} from '@services/vpnCheckService/vpn-check.service';

@Component({
  selector: 'app-private-project',
  templateUrl: './private-project.component.html',
  styleUrls: ['./private-project.component.scss'],
})
export class PrivateProjectComponent implements OnInit {
  project: ProjectResponse;
  datasets: CommonsSearchDataset[];
  documentsDataSource: MatTableDataSource<ProjectFileVersionResponse>;
  datasetsDataSource: MatTableDataSource<CommonsSearchDataset>;
  showConfirmDelete = false;
  error: String;
  createNew = false;
  userIsOnVPN: boolean;
  displayedColumns: string[] = [
    'name',
    'hsd',
    'isMetadataPublic',
    'isDataPublic',
    'editMetadata',
    'editDataFile',
    'upload',
    'download',
  ];
  projectResources: Resource[];
  getRoleTooltip = getRoleTooltip;
  contentBeingLoaded: string;
  navItems: NavItem[];
  projectWithPermissions: CommonsSearchProject;
  publisherName: string;

  constructor(
    private ras: ResourceApiService,
    private cas: CommonsApiService,
    private route: ActivatedRoute,
    private userService: UserService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public vpnCheckService: VPNCheckService,
  ) {}

  isContentLoadedAndOnVPN(): boolean {
    return this.isLoaded() && this.vpnCheckService.userIsOnVPN;
  }

  isLoaded(): boolean {
    return !!(this.userService.user && this.project && this.projectWithPermissions);
  }

  projectId(): string {
    return this.route.snapshot.paramMap.get('project_id');
  }

  ngOnInit() {
    this.checkVPNStatus();
    setTimeout(async () => {
      await this.tryLoad();
    }, 1000);
  }

  private checkVPNStatus() {
    return new Promise<boolean>(async resolve => {
      await this.loadProjectsIfVPNStatusHasChanged(this.vpnCheckService.userIsOnVPN);
      this.vpnCheckService.updated.subscribe(async isConnected => {
        await this.loadProjectsIfVPNStatusHasChanged(isConnected);
        resolve(isConnected);
      });
    });
  }

  private async loadProjectsIfVPNStatusHasChanged(isConnected) {
    const statusHasChanged = this.userIsOnVPN !== isConnected;
    this.userIsOnVPN = isConnected;

    if (isConnected && statusHasChanged) {
      await this.loadProject();
    }
    return isConnected;
  }

  async tryLoad() {
    this.contentBeingLoaded = 'Project';
    await this.loadProject();
    this.contentBeingLoaded = 'Navigation';
    this.loadNavItems();
    this.contentBeingLoaded = 'Project Datasets';
    await this.fetchDatasets();
    this.contentBeingLoaded = 'Project Documents';
    await this.loadProjectDocuments();
    this.contentBeingLoaded = 'Project Resources';
    await this.loadProjectResources();
    await scrollToRouteParam(this.route);
  }

  async reloadProject() {
    this.snackBar.open('Project updated', 'Ok', {duration: 3000});
    await this.tryLoad();
  }

  async loadProject(delay = 1000) {
    const results = await lastValueFrom(this.ras.searchProjects(this.userService.user, {id: this.projectId()}));
    if (results?.total?.value > 0) {
      this.publisherName = results.hits[0]?._source?.publisher?.name;
      this.projectWithPermissions = <CommonsSearchProject>(<unknown>results.hits.map(x => x._source)[0]);
    }

    this.project = await lastValueFrom(
      this.cas.getProject(this.projectId(), this.userService.user, this.publisherName),
    );
  }

  showProjectDocuments() {
    if (!this.projectWithPermissions) return false;
    return (
      this.projectWithPermissions._can_upload_data === true ||
      this.projectWithPermissions._can_download_data === true ||
      this.projectWithPermissions._can_delete_data === true
    );
  }

  private loadNavItems() {
    this.navItems = getNavItems(['commons-private', 'commons-project-private'], this.projectId());
  }

  private async updateDocumentsDataSource() {
    this.documentsDataSource = new MatTableDataSource<ProjectFileVersionResponse>(
      this.project.project_file_versions.filter(v => !v.is_latest_and_not_deleted),
    );

    await firstValueFrom(this.documentsDataSource.connect());
  }

  private updateDatasetsDataSource() {
    this.datasetsDataSource = new MatTableDataSource<CommonsSearchDataset>(Array.from(this.datasets));
  }

  private async loadProjectResources() {
    this.projectResources = await this.ras.loadProjectResources(this.project.portal_resources_urls);
  }

  /**
   * Gets list of all DSP links from all project datasets and sets can_be_deleted flag to false
   * if DSP document is being used by a dataset. Finally, updates the documents table.
   * @private
   */
  private async loadProjectDocuments() {
    if (this.project?.project_file_versions) {
      // Update documents table.
      await this.updateDocumentsDataSource();
    }
  }

  private async fetchDatasets() {
    this.datasets = await this.ras.getDatasets(
      '*',
      this.userService.user,
      false,
      {associated_projects: this.projectId()},
      true,
    );
    this.updateDatasetsDataSource();
  }
}
