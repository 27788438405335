import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {EventEmitter, Injectable} from '@angular/core';
import {Params} from '@angular/router';
import {ProjectUrlResponse} from '@services/landing-service';
import {ResourceAPIEndpoints, ResourceAPIEndpointsURL} from '@services/resource-api/resource-api.endpoints';
import {Availability} from '@shared/types/availability';
import {Category} from '@shared/types/category';
import {CategoryResource} from '@shared/types/category-resource';
import {
  CommonsDatasetSearchParams,
  CommonsProjectSearchParams,
  CommonsSearchDataset,
  CommonsSearchProject,
  CommonsSearchResponse,
  CommonsStringSearchParams,
} from '@shared/types/commons-types';
import {Favorite} from '@shared/types/favorite';
import {FileAttachment} from '@shared/types/file-attachment';
import {Icon} from '@shared/types/icon';
import {Institution} from '@shared/types/institution';
import {Resource} from '@shared/types/resource';
import {ResourceCategory, ResourceCategoryCreate} from '@shared/types/resource-category';
import {ResourceQuery} from '@shared/types/resource-query';
import {ResourceType} from '@shared/types/resourceType';
import {SegmentType} from '@shared/types/segmentType';
import {DeleteComplete, TaskResponse, TaskState, TaskStatus} from '@shared/types/task';
import {User} from '@shared/types/user';
import {UserSearchResults} from '@shared/types/user-search-results';
import {ViewPreferences} from '@shared/types/view-preferences';
import {environment} from '@src/environments/environment';
import {showProgress} from '@utilities/show-progress';
import {cloneDeep} from 'lodash-es';
import {delay, filter, lastValueFrom, Observable, of, skipUntil, throwError} from 'rxjs';
import {catchError, last, map, tap} from 'rxjs/operators';

@Injectable()
export class ResourceApiService {
  endpoints = ResourceAPIEndpoints;

  constructor(private httpClient: HttpClient) {}

  /** search_projects */
  searchProjects(user: User, q: any): Observable<CommonsSearchResponse> {
    const url = this.getURL(this.endpoints.search_projects);
    return this.getCommonsSearchResponse(url, user, q);
  }

  /** getSession */
  public getSession(): Observable<User> {
    const token = localStorage.getItem('token');
    if (token) {
      return this.httpClient.get<User>(this.getURL(this.endpoints.session)).pipe(catchError(this.handleError));
    } else {
      return of(null);
    }
  }

  /** closeSession */
  closeSession(): Observable<User> {
    localStorage.removeItem('token');
    return this.httpClient.delete<User>(this.getURL(this.endpoints.session));
  }

  /** getSessionStatus */
  getSessionStatus(): Observable<number> {
    const token: string = localStorage.getItem('token');
    if (token) {
      return this.httpClient
        .get<number>(this.getURL(this.endpoints.sessionstatus))
        .pipe(catchError(this.sessionStatusError));
    } else {
      return of(0);
    }
  }

  /** login - An alternative to single sign on, allow users to log into the system with a username and password.
   * email_token is not required, only send this if user is logging in for the first time
   * after an email verification link. */
  login(email: string, password: string, email_token = ''): Observable<any> {
    const options = {email, password, email_token};
    return this.httpClient.post(this.getURL(this.endpoints.login_password), options).pipe(catchError(this.handleError));
  }

  /** updateViewPreferences */
  updateViewPreferences(preferences: ViewPreferences) {
    localStorage.setItem('viewPreferences', JSON.stringify(preferences));
  }

  /** getViewPreferences */
  getViewPreferences(): ViewPreferences {
    const viewPrefs = JSON.parse(localStorage.getItem('viewPreferences'));
    if (viewPrefs) {
      return viewPrefs;
    } else {
      // Initialize view preferences
      this.updateViewPreferences({isNetworkView: true});
      return this.getViewPreferences();
    }
  }

  /** syncProject */
  syncProject(projectId: string, user: User, publisherName: string): Observable<TaskResponse> {
    let url =
      this.getURL(this.endpoints.syncproject, {project_id: projectId}) +
      `?landing-service-institution=${publisherName}`;
    return this.httpClient
      .put<TaskResponse>(url, {headers: this.getRequestHeaders(user), responseType: 'json'})
      .pipe(catchError(this.handleError));
  }

  /** waitForSyncProject */
  async waitForSyncProject(projectId: string, user: User, publisherName: string): Promise<TaskStatus> {
    const task = await lastValueFrom(this.syncProject(projectId, user, publisherName));
    return await this.waitForTaskComplete(task);
  }

  /** syncOnDatasetDelete: Deletes a record from elasticsearch */
  syncOnDatasetDelete(datasetId: string, user: User, publisherName: string): Observable<DeleteComplete> {
    let url = this.getURL(this.endpoints.syncdataset, {dataset_id: datasetId});
    url += '?landing-service-institution=' + publisherName;
    return this.httpClient
      .delete<DeleteComplete>(url, {headers: this.getRequestHeaders(user), responseType: 'json'})
      .pipe(catchError(this.handleError));
  }

  /** syncOnProjectDelete: Deletes a record from elasticsearch */
  syncOnProjectDelete(projectId: string, user: User, publisherName: string): Observable<DeleteComplete> {
    let url =
      this.getURL(this.endpoints.syncproject, {project_id: projectId}) +
      `?landing-service-institution=${publisherName}`;
    return this.httpClient
      .delete<DeleteComplete>(url, {headers: this.getRequestHeaders(user), responseType: 'json'})
      .pipe(catchError(this.handleError));
  }

  /** getDataset */
  getDataset(user: User, q: any): Observable<CommonsSearchResponse> {
    return this.getCommonsSearchResponse(this.getURL(this.endpoints.datasets), user, q);
  }

  /** syncDataset */
  syncDataset(datasetId: string, user: User, publisherName: string): Observable<TaskResponse> {
    let url =
      this.getURL(this.endpoints.syncdataset, {dataset_id: datasetId}) +
      `?landing-service-institution=${publisherName}`;
    return this.httpClient
      .put<TaskResponse>(url, {headers: this.getRequestHeaders(user), responseType: 'json'})
      .pipe(catchError(this.handleError));
  }

  /** waitForSyncDataset */
  async waitForSyncDataset(datasetId: string, user: User, publisherName: string): Promise<TaskStatus> {
    const task = await lastValueFrom(this.syncDataset(datasetId, user, publisherName));
    return await this.waitForTaskComplete(task);
  }

  /** searchDatasetsGlobal */
  searchDatasets(user: User, q: any): Observable<CommonsSearchResponse> {
    const url = this.getURL(this.endpoints.search_datasets);
    return this.httpClient
      .post<any>(url, q, {headers: this.getRequestHeaders(user), responseType: 'json'})
      .pipe(catchError(this.handleError));
  }

  /** searchProjectsGlobal */
  searchProjectsGlobal(user: User, q: any): Observable<CommonsSearchResponse> {
    const url = this.getURL(this.endpoints.search_projects_global);
    return this.getCommonsSearchResponse(url, user, q);
  }

  /** searchResources */
  searchResources(query: ResourceQuery): Observable<ResourceQuery> {
    // Scrub the query of unnecessary data.
    const cleanQuery = cloneDeep(query);
    ['resources', 'facets', '_props'].forEach(s => delete cleanQuery[s]);

    return this.httpClient
      .post<ResourceQuery>(this.getURL(this.endpoints.search, {}, true), cleanQuery)
      .pipe(catchError(this.handleError));
  }

  /** getCategories */
  getCategories(): Observable<Category[]> {
    return this.httpClient.get<Category[]>(this.getURL(this.endpoints.categorylist)).pipe(catchError(this.handleError));
  }

  /** getRootCategories */
  getRootCategories(): Observable<Category[]> {
    return this.httpClient
      .get<Category[]>(this.getURL(this.endpoints.rootcategorylist))
      .pipe(catchError(this.handleError));
  }

  /** getCategory */
  getCategory(id: Number): Observable<Category> {
    return this.httpClient.get<Category>(this.getURL(this.endpoints.category, {id})).pipe(catchError(this.handleError));
  }

  /** getCategoryResources */
  getCategoryResources(category: Category): Observable<CategoryResource[]> {
    return this.httpClient
      .get<CategoryResource[]>(this.getURL(this.endpoints.resourcebycategory, {category_id: category.id}))
      .pipe(catchError(this.handleError));
  }

  /** updateCategory */
  updateCategory(category: Category): Observable<Category> {
    return this.httpClient
      .put<Category>(this.getURL(this.endpoints.category, {id: category.id}), category)
      .pipe(catchError(this.handleError));
  }

  /** addCategory */
  addCategory(category: Category): Observable<Category> {
    return this.httpClient
      .post<Category>(this.getURL(this.endpoints.categorylist), category)
      .pipe(catchError(this.handleError));
  }

  /** deleteCategory */
  deleteCategory(category: Category): Observable<Category> {
    return this.httpClient
      .delete<Category>(this.getURL(this.endpoints.category, {id: category.id}))
      .pipe(catchError(this.handleError));
  }

  /** getIcons */
  getIcons(): Observable<Icon[]> {
    return this.httpClient.get<Icon[]>(this.getURL(this.endpoints.iconlist)).pipe(catchError(this.handleError));
  }

  /** getInstitutions */
  getInstitutions(): Observable<Institution[]> {
    return this.httpClient
      .get<Institution[]>(this.getURL(this.endpoints.institutionlist))
      .pipe(catchError(this.handleError));
  }

  /** getInstitution */
  getInstitution(id: Number): Observable<Institution> {
    return this.httpClient
      .get<Institution>(this.getURL(this.endpoints.institution, {id}))
      .pipe(catchError(this.handleError));
  }

  /** getTypes */
  getTypes(): Observable<ResourceType[]> {
    return this.httpClient.get<ResourceType[]>(this.getURL(this.endpoints.typelist)).pipe(catchError(this.handleError));
  }

  /** getSegments */
  getSegments(): Observable<SegmentType[]> {
    return this.httpClient
      .get<SegmentType[]>(this.getURL(this.endpoints.segmentlist))
      .pipe(catchError(this.handleError));
  }

  /** getResource */
  getResource(id: Number): Observable<Resource> {
    return this.httpClient.get<Resource>(this.getURL(this.endpoints.resource, {id})).pipe(catchError(this.handleError));
  }

  /**
   * Returns resources for the given list of IDs. If the user does not have permission to retrieve
   * a given ID, it is not included in the results.
   * @param resourceIds
   */
  getResourcesById(resourceIds?: number[]): Observable<Resource[]> {
    return this.httpClient
      .get<Resource[]>(this.getURL(this.endpoints.resourcelist) + `?resource_ids=${resourceIds.join(',')}`)
      .pipe(catchError(this.handleError));
  }

  /**
   * Parses the given list of project resource URLs, extracts the resource IDs, and returns the associated resources.
   */
  async loadProjectResources(projectResourceUrls: ProjectUrlResponse[]): Promise<Resource[]> {
    const allDigitsPattern = /^[0-9]+$/;

    const resourceIds = projectResourceUrls.map(u => {
      const uSplit = u.url.split('/#/resource/');

      if (uSplit.length === 2 && allDigitsPattern.test(uSplit[1])) {
        return parseInt(uSplit[1], 10);
      } else {
        console.error(`Invalid Resource URL:`, u);
      }
    });

    if (resourceIds.length > 0) {
      return await lastValueFrom(this.getResourcesById(resourceIds));
    }

    return [];
  }

  /** getResourceCategories */
  getResourceCategories(resource: Resource): Observable<ResourceCategory[]> {
    const url = this.getURL(this.endpoints.categorybyresource, {resource_id: resource.id.toString()});
    return this.httpClient.get<ResourceCategory[]>(url).pipe(catchError(this.handleError));
  }

  /** updateResourceCategories */
  updateResourceCategories(resource: Resource, categories: ResourceCategoryCreate[]): Observable<CategoryResource[]> {
    const url = this.getURL(this.endpoints.categorybyresource, {resource_id: resource.id}, true);
    return this.httpClient.post<CategoryResource[]>(url, categories).pipe(catchError(this.handleError));
  }

  /** updateResource */
  updateResource(resource: Resource): Observable<Resource> {
    const url = this.getURL(this.endpoints.resource, {id: resource.id}, true);
    return this.httpClient.put<Resource>(url, resource).pipe(catchError(this.handleError));
  }

  /** updateResourceAvailability */
  updateResourceAvailability(resource: Resource, avails: Availability[]): Observable<Availability> {
    const url = this.getURL(this.endpoints.resourceavailability, {resource_id: resource.id}, true);
    return this.httpClient.post<Availability>(url, avails).pipe(catchError(this.handleError));
  }

  /** addResource */
  addResource(resource: Resource): Observable<Resource> {
    return this.httpClient
      .post<Resource>(this.getURL(this.endpoints.resourcelist, null, true), resource)
      .pipe(catchError(this.handleError));
  }

  /** deleteResource */
  deleteResource(resource: Resource): Observable<Resource> {
    const url = this.getURL(this.endpoints.resource, {id: resource.id}, true);
    return this.httpClient.delete<Resource>(url).pipe(catchError(this.handleError));
  }

  /** getFileAttachment */
  getFileAttachment(id?: number, md5?: string): Observable<FileAttachment> {
    const params = {id: String(id), md5: md5};
    const url = this.getURL(this.endpoints.filelist);
    return this.httpClient.get<FileAttachment>(url, {params: params}).pipe(catchError(this.handleError));
  }

  /** addFileAttachment */
  addFileAttachment(attachment: FileAttachment): Observable<FileAttachment> {
    const url = this.getURL(this.endpoints.filelist, {}, true);
    const attachmentMetadata = {
      resource_id: attachment.resource_id,
      dataset_id: attachment.dataset_id,
      file_name: attachment.file_name || attachment.name,
      display_name: attachment.display_name || attachment.name,
      date_modified: new Date(attachment.lastModified),
      md5: attachment.md5,
      mime_type: attachment.mime_type || attachment.type,
      size: attachment.size,
    };

    return this.httpClient.post<FileAttachment>(url, attachmentMetadata).pipe(catchError(this.handleError));
  }

  /** getPublicDatasetFileAttachment */
  getPublicDatasetFileAttachment(datasetId: string): Observable<FileAttachment> {
    const url = this.getURL(this.endpoints.file, {}, true).replace('<id>', datasetId).replace('<file_type>', 'dataset');
    return this.httpClient.get<FileAttachment>(url).pipe(catchError(this.handleError));
  }

  /** addFileAttachmentBlob */
  addFileAttachmentBlob(
    attachmentId: number | string,
    attachment: FileAttachment,
    progress: EventEmitter<number>,
  ): Observable<FileAttachment> {
    const filetype = attachment.dataset_id ? 'dataset' : 'resource';
    const url = this.getURL(this.endpoints.file, {id: attachmentId.toString(), file_type: filetype}, true);
    const attachmentMetadata = {
      file_name: attachment.file_name || attachment.name,
      mime_type: attachment.mime_type || attachment.type,
      file_size: attachment.size.toString(),
    };

    const options: {
      headers?: HttpHeaders;
      observe: 'events';
      params?: HttpParams;
      reportProgress?: boolean;
      // responseType: 'json';
      withCredentials?: boolean;
    } = {
      headers: new HttpHeaders({
        ...attachmentMetadata,
        type: 'multipart/form-data',
      }),
      observe: 'events',
      reportProgress: true,
      // responseType: 'blob' as 'json',
    };

    const fd = new FormData();
    fd.append('file', attachment);

    return this.httpClient.put<File>(url, fd, options).pipe(
      map(event => showProgress(event, progress, attachment)),
      tap(_ => {}),
      last(), // return last (completed) message to caller
      catchError(this.handleError),
    );
  }

  /** updateFileAttachment */
  updateFileAttachment(attachment: FileAttachment): Observable<FileAttachment> {
    const filetype = attachment.dataset_id ? 'dataset' : 'resource';
    const url = this.getURL(this.endpoints.file, {}, true)
      .replace('<id>', attachment.id.toString())
      .replace('<file_type>', filetype);
    const attachmentMetadata = {
      display_name: attachment.display_name || attachment.name,
      date_modified: new Date(attachment.lastModified || attachment.date_modified),
      md5: attachment.md5,
      mime_type: attachment.type || attachment.mime_type,
      size: attachment.size,
      resource_id: attachment.resource_id,
    };

    const options = {
      headers: new HttpHeaders({
        type: 'multipart/form-data',
      }),
    };

    const fd = new FormData();
    Object.entries(attachmentMetadata).forEach(([key, value]) => {
      fd.append(key, `${value}`);
    });

    return this.httpClient.put<FileAttachment>(url, fd, options).pipe(catchError(this.handleError));
  }

  /** deleteFileAttachment */
  deleteFileAttachment(attachment: FileAttachment): Observable<FileAttachment> {
    const filetype = attachment.dataset_id ? 'dataset' : 'resource';
    const url = this.getURL(this.endpoints.file, {}, true)
      .replace('<id>', attachment.id.toString())
      .replace('<file_type>', filetype);
    return this.httpClient.delete<FileAttachment>(url).pipe(catchError(this.handleError));
  }

  /** getUserResources
   * get resources that the user owns */
  getUserResources(): Observable<Resource[]> {
    return this.httpClient.get<Resource[]>(this.getURL(this.endpoints.userresource)).pipe(catchError(this.handleError));
  }

  /** addFavorite */
  addFavorite(user: User, resource: Resource): Observable<Favorite> {
    const options = {resource_id: resource.id, user_id: user.id};
    return this.httpClient
      .post<Favorite>(this.getURL(this.endpoints.favoritelist), options)
      .pipe(catchError(this.handleError));
  }

  /** deleteFavorite */
  deleteFavorite(favorite: Favorite): Observable<Favorite> {
    const url = this.getURL(this.endpoints.favorite, {id: favorite.id});
    return this.httpClient.delete<Favorite>(url).pipe(catchError(this.handleError));
  }

  /** getUserFavorites */
  getUserFavorites(): Observable<Favorite[]> {
    return this.httpClient.get<Favorite[]>(this.getURL(this.endpoints.userfavorite)).pipe(catchError(this.handleError));
  }

  /** getUser
   * retrieve a user */
  getUser(id: number): Observable<User> {
    return this.httpClient.get<User>(this.getURL(this.endpoints.user, {id})).pipe(catchError(this.handleError));
  }

  getConsultCategoryList(): Observable<any> {
    return this.httpClient
      .get<any>(this.getURL(this.endpoints.consult_category_list))
      .pipe(catchError(this.handleError));
  }

  /** updateUser */
  updateUser(user: User): Observable<User> {
    return this.httpClient
      .put<User>(this.getURL(this.endpoints.user, {id: user.id.toString()}), user)
      .pipe(catchError(this.handleError));
  }

  /** addUser */
  addUser(user: User): Observable<User> {
    return this.httpClient.post<User>(this.getURL(this.endpoints.userlist), user).pipe(catchError(this.handleError));
  }

  /** findUsers */
  findUsers(
    filter = '',
    sort = 'display_name',
    sortOrder = 'asc',
    pageNumber = 0,
    pageSize = 3,
  ): Observable<UserSearchResults> {
    const search_data = {
      filter: filter,
      sort: sort,
      sortOrder: sortOrder,
      pageNumber: String(pageNumber),
      pageSize: String(pageSize),
    };
    return this.httpClient
      .get<UserSearchResults>(this.getURL(this.endpoints.userlist), {params: search_data})
      .pipe(catchError(this.handleError));
  }

  /** acceptCommonsEula
   * Request a Consult */
  acceptCommonsEula(user: User, userAccepted: boolean): Observable<any> {
    const request_data = {
      user_id: user.id,
      user_accepted: userAccepted,
    };
    return this.httpClient
      .post<any>(this.getURL(this.endpoints.commons_eula), request_data)
      .pipe(catchError(this.handleError));
  }

  /** dataverse Email */
  sendDataverseEmail(user: User, datasetId: string): Observable<any> {
    const request_data = {
      user_id: user.id,
      ds_id: datasetId,
    };
    return this.httpClient
      .post<any>(this.getURL(this.endpoints.dataverse_email), request_data)
      .pipe(catchError(this.handleError));
  }

  /** sendConsultRequestEmail
   * Request a Consult */
  sendConsultRequest(
    user: User,
    request_category: string,
    request_type: string,
    request_title: string,
    request_text: string,
  ): Observable<any> {
    const request_data = {
      user_id: user.id,
      request_category: request_category,
      request_type: request_type,
      request_title: request_title,
      request_text: request_text,
    };
    return this.httpClient
      .post<any>(this.getURL(this.endpoints.consult_request), request_data)
      .pipe(catchError(this.handleError));
  }

  /** sendApprovalRequestEmail
   * Request Resource Approval */
  sendApprovalRequestEmail(user: User, resource: Resource): Observable<any> {
    return this.httpClient
      .post<any>(this.getURL(this.endpoints.approval_request), {user_id: user.id, resource_id: resource.id})
      .pipe(catchError(this.handleError));
  }

  // an unauthorized message.
  private sessionStatusError(error: HttpErrorResponse) {
    if (error.status === 401) {
      localStorage.removeItem('token');
    }
    return throwError(() => error);
  }

  private handleError(error: HttpErrorResponse) {
    let message = 'Something bad happened; please try again later.';

    console.error(error);

    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned a status code ${error.status}, ` +
          `Code was: ${JSON.stringify(error.error.code)}, ` +
          `Message was: ${JSON.stringify(error.error.message)}`,
      );
      message = error.error.message;
      // If this was a 401 error, re-verify they have a valid session.
      if (error.error.code === 401) {
        this.getSession();
      }
    }
    // return an observable with a user-facing error message
    // FIXME: Log all error messages to Google Analytics
    return throwError(() => message);
  }

  private getCommonsSearchResponse(
    url: string,
    user: User,
    body: CommonsStringSearchParams | CommonsProjectSearchParams | CommonsDatasetSearchParams,
  ) {
    return this.httpClient
      .post<CommonsSearchResponse>(url, body, {headers: this.getRequestHeaders(user), responseType: 'json'})
      .pipe(catchError(this.handleError));
  }

  getRequestHeaders(user: User) {
    if (user) {
      return new HttpHeaders({Eppn: user.eppn});
    } else {
      return new HttpHeaders({Eppn: 'no_eppn'});
    }
  }

  rebuildIndex(whichIndex: 'portal' | 'commons' | 'fake') {
    const url = this.getURL(this.endpoints.rebuild_index, {index: whichIndex});
    return this.httpClient.get(url, {observe: 'events', responseType: 'text', reportProgress: true});
  }

  async getDatasets(
    search: string,
    user: User,
    publicCommons: boolean,
    queryObject?: {[key: string]: string},
    fieldSpecificSearch?: boolean,
  ): Promise<CommonsSearchDataset[]> {
    user = user && !publicCommons ? user : null;
    const results = await lastValueFrom(
      this.searchDatasets(user, this.makeQuery(search, queryObject, fieldSpecificSearch)),
    );
    return <CommonsSearchDataset[]>results?.hits?.map(x => x?._source);
  }

  async getProjects(
    search: string,
    user: User,
    publicCommons: boolean,
    queryObject?: {[key: string]: string},
    fieldSpecificSearch?: boolean,
  ): Promise<CommonsSearchProject[]> {
    user = user && !publicCommons ? user : null;
    const results = await lastValueFrom(
      this.searchProjects(user, this.makeQuery(search, queryObject, fieldSpecificSearch)),
    );
    return <CommonsSearchProject[]>results?.hits?.map(x => x?._source);
  }

  makeQuery(search: string, queryObject?: {[key: string]: any}, fieldSpecificSearch?: boolean) {
    if (fieldSpecificSearch) return queryObject;
    search = search === '' ? '*' : search; // Default to wildcard search
    queryObject = queryObject || {};
    return {
      ...queryObject,
      name: search,
      alternateName: search,
      description: search,
      keywords: [search],
    };
  }

  getTaskStatus(taskId: string) {
    const url = this.getURL(this.endpoints.task_status, {task_id: taskId});
    return this.httpClient.get<TaskStatus>(url);
  }

  async waitForTaskComplete(task: TaskResponse): Promise<TaskStatus> {
    let delayTime = 100;
    let success = false;

    while (!success && delayTime < 5000) {
      const status = await lastValueFrom(this.getTaskStatus(task.task_id).pipe(delay(delayTime)));
      success = status?.task_status === TaskState.SUCCESS;
      delayTime *= 2;
    }

    const source = this.getTaskStatus(task?.task_id);

    return await lastValueFrom(
      source.pipe(
        // When one successful response has been emitted, complete source observable.
        skipUntil(
          source.pipe(
            filter((v: TaskStatus) => {
              return v?.task_status === TaskState.SUCCESS;
            }),
          ),
        ),
        delay(1000),
      ),
    );
  }

  getURL(path: ResourceAPIEndpointsURL, params?: Params, useAlt?: boolean): string {
    const apiRoot = useAlt ? environment.api_alt : environment.api;

    if (!params) return apiRoot + path;

    const pathWithParams = Object.entries(params).reduce((acc: string, [key, value]: [string, string | number]) => {
      return acc.replace(`<${key}>`, value.toString());
    }, path as string);

    return apiRoot + pathWithParams;
  }
}
