import {MediaMatcher} from '@angular/cdk/layout';
import {Location} from '@angular/common';
import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {MatSidenav} from '@angular/material/sidenav';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {ActivatedRoute, ActivationEnd, ActivationStart, NavigationEnd, Router} from '@angular/router';
import {getSessionInstitutionId, getUserLoginService} from '@authentication/login-service';
import {IntervalService} from '@services/interval/interval.service';
import {environment} from '@src/environments/environment';
import {ResourceApiService} from '@services/resource-api/resource-api.service';
import {UserService} from '@services/user-service/user.service';
import {DEFAULT_HOME_TABINDEX} from '@shared/constants/constants';
import {Icon} from '@shared/types/icon';
import {Institution} from '@shared/types/institution';
import {User} from '@shared/types/user';
import {setPrevUrl} from '@utilities/prev-url';
import {lastValueFrom, Subscription} from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('sidenav', {static: true}) sidenav: MatSidenav;
  private subscriptions = new Subscription();
  hideHeader = false;
  timeLeftInSession: number;
  categoryId: string;
  icons: Icon[];
  institution: Institution;
  isExpanded = true;
  isHome = false;
  isNetworkView: boolean;
  isResourceView = false;
  mobileQuery: MediaQueryList;
  title = 'iTHRIV';
  pageTitle = '';
  trustUrl;
  tabIndex = DEFAULT_HOME_TABINDEX;
  private _mobileQueryListener: () => void;

  public constructor(
    public changeDetectorRef: ChangeDetectorRef,
    public media: MediaMatcher,
    private api: ResourceApiService,
    private location: Location,
    public router: Router,
    private sanitizer: DomSanitizer,
    private titleService: Title,
    public iconRegistry: MatIconRegistry,
    private route: ActivatedRoute,
    private intervalService: IntervalService,
    private userService: UserService,
  ) {}

  ngOnInit() {
    this.setupApp();
  }

  /** Returns true if session will expire in 5 minutes or less */
  get showTimeoutWarning(): boolean {
    return !!this.timeLeftInSession && this.timeLeftInSession >= 0 && this.timeLeftInSession <= 5 * 60 * 1000;
  }

  get isDev(): boolean {
    return environment.use_header_auth;
  }

  get user(): User {
    return this.userService.getUser();
  }

  get shouldDisplaySearchBar(): boolean {
    return !this.isNetworkOrBrowseView();
  }

  get isSearch(): boolean {
    const pathArray = this.location.path().split('/');
    return pathArray && pathArray.length > 1 && pathArray[1] === 'search';
  }

  /**
   * Warn the user if there session has less than 5 minutes remaining.
   */
  toolBarWarningClass() {
    if (this.user && this.timeLeftInSession < 300000) {
      return 'warning';
    } else {
      return '';
    }
  }

  async checkStatus() {
    const token = localStorage.getItem('token');

    if (token) {
      try {
        const timestamp = await lastValueFrom(this.api.getSessionStatus());
        const now = new Date();
        const exp = new Date(timestamp * 1000);
        await this.setTimeLeftOrLogout(exp.getTime() - now.getTime());
      } catch (e) {
        await this.logoutIfNoTimeLeft(true);
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
  }

  async loadIcons() {
    this.icons = await lastValueFrom(this.api.getIcons());
    this.loadIconRegistry();
  }

  loadIconRegistry() {
    for (const key in this.icons) {
      if (this.icons.hasOwnProperty(key)) {
        this.registerIcon(this.icons[key]);
      }
    }
  }

  registerIcon(icon: Icon) {
    this.iconRegistry.addSvgIcon(`ithriv_${icon.id}`, this.trustUrl(icon.url));
  }

  async goHome($event?: MouseEvent) {
    if ($event) {
      $event.preventDefault();
    }
    await this.router.navigate(['/home', {tabIndex: this.tabIndex}]);
  }

  async goLogin($event?: MouseEvent) {
    if ($event) {
      $event.preventDefault();
    }

    // Make sure we're not already on the login screen.
    const onLoginScreen = /^\/login/.test(this.router.url);
    if (!onLoginScreen) {
      setPrevUrl();
      await this.router.navigate(['login']);
    }
  }

  async goUserAdmin($event?: MouseEvent) {
    $event.preventDefault();
    await this.router.navigate(['admin/users']);
  }

  async goLogout($event?: MouseEvent) {
    if ($event) {
      $event.preventDefault();
    }

    setPrevUrl();
    await this.userService.logout();
    await this.router.navigate(['logout']);
  }

  getInstitution() {
    const institutionId = getSessionInstitutionId();
    if (institutionId !== undefined) {
      this.subscriptions.add(
        this.api.getInstitution(institutionId).subscribe(inst => {
          this.institution = inst;
        }),
      );
    }
  }

  setIsNetworkView(network = true) {
    this.api.updateViewPreferences({isNetworkView: network});
    return network;
  }

  isNetworkOrBrowseView() {
    const pathArray = this.location.path().split('/');
    return pathArray && pathArray.length > 1 && (pathArray[1] === 'network' || pathArray[1] === 'browse');
  }

  async viewMode(isNetworkView: boolean) {
    // URL may have been modified via location.replaceState. Retrieve
    // categoryId from raw URL path string.
    const pathArray = this.location.path().split('/');

    if (
      pathArray &&
      pathArray.length === 3 &&
      (pathArray[1] === 'network' || pathArray[1] === 'browse') &&
      /^[0-9]+$/.test(pathArray[2])
    ) {
      this.categoryId = pathArray[2];
    }

    this.isNetworkView = this.setIsNetworkView(isNetworkView);

    if (this.categoryId) {
      if (this.isNetworkView) {
        await this.router.navigate(['network', this.categoryId]);

        // Go up the hierarchy to the Level 1 or 0 parent for this category
        this.api.getCategory(parseInt(this.categoryId, 10)).subscribe(c => {
          let catId: number;
          if (c.level === 2) {
            catId = c.parent.id;
          }
          if (c.level <= 1) {
            catId = c.id;
          }
          this.router.navigate(['network', catId.toString()]);
        });
      } else {
        // Go up the hierarchy to the Level 0 parent for this category
        this.api.getCategory(parseInt(this.categoryId, 10)).subscribe(c => {
          if (c.level === 2) {
            this.router.navigate(['category', c.id.toString()]);
          } else {
            let catId: number;

            if (c.level === 0) {
              catId = c.id;
            } else if (c.level === 1) {
              catId = c.parent.id;
            }
            this.router.navigate(['browse', catId.toString()]);
          }
        });
      }
    } else {
      if (this.isNetworkView) {
        await this.router.navigate(['network']);
      } else {
        await this.router.navigate(['browse']);
      }
    }
  }

  async toggleSidenav() {
    await this.sidenav.toggle();
  }

  async closeSidenav() {
    await this.sidenav.close();
  }

  async setTimeout(timeoutDelaySeconds: number) {
    const loginService = getUserLoginService(this.user);
    loginService.id = this.user.institution_id;

    if (loginService) {
      return await loginService.goLogin(this.router, timeoutDelaySeconds);
    }
  }

  private async setTimeLeftOrLogout(msLeft: number) {
    this.timeLeftInSession = msLeft;
  }

  private async logoutIfNoTimeLeft(loggedOut: boolean) {
    return new Promise(async resolve => {
      if (loggedOut) {
        const path = this.route.snapshot.firstChild ? this.route.snapshot.firstChild.url[0].path : 'home';
        if (!['timedout', 'logout', 'login', 'session'].includes(path)) {
          await this.userService.logout();
          this.intervalService.clearInterval();
          setPrevUrl();
          await this.router.navigate(['/timedout']);
          resolve(true);
        }
      }
    });
  }

  private async setupApp() {
    setPrevUrl();

    this.mobileQuery = this.media.matchMedia('(max-width: 959px)');
    this._mobileQueryListener = () => this.changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this._mobileQueryListener);

    // NB: document.documentMode is only defined in IE, it is undefined in other browsers
    // if (document['documentMode']) {
    //   this.router.navigate(['upgrade_browser']);
    // }

    this.trustUrl = this.sanitizer.bypassSecurityTrustResourceUrl;
    await this.loadIcons();

    this.router.events.subscribe(async e => {
      if (e instanceof NavigationEnd) {
        // Retrieve first path element (in case this is a pasted URL):
        const path = this.route.snapshot.firstChild ? this.route.snapshot.firstChild.url[0].path : 'home';

        this.route.queryParamMap.subscribe(async queryParamMap => {
          // Go to the login page if there is no session data & if on the home path:
          if (!localStorage.getItem('session') && path == 'home' && !queryParamMap.has('tabIndex')) {
            await this.goLogin();
          }

          if (queryParamMap.has('auth_token')) {
            localStorage.setItem('token', queryParamMap.get('auth_token'));
          }

          await this.checkStatus();
        });
      }

      if (e instanceof ActivationStart || e instanceof ActivationEnd) {
        if (e.snapshot && e.snapshot.data) {
          const data = e.snapshot.data;

          if (data.title) {
            this.title = `iTHRIV - ${data.title}`;
            this.titleService.setTitle(this.title);
          }
          this.categoryId = e.snapshot && e.snapshot.params && e.snapshot.params.category;
          this.hideHeader = !!data.hideHeader;
          this.pageTitle = data.title || '';
        }
      }

      if (e instanceof NavigationEnd) {
        this.isHome = ['/', '/search'].indexOf(e.url) > -1;
        this.isResourceView = /^\/resource\//.test(e.url);
      }
    });

    this.isNetworkView = this.setIsNetworkView(this.mobileQuery.matches);
    const numMinutes = 1;

    this.intervalService.setInterval(async () => {
      // Update seconds
      this.timeLeftInSession -= 1000;

      // Check status every numMinutes
      if (this.timeLeftInSession % (numMinutes * 60 * 1000) < 1000) {
        await this.checkStatus();
      }
    }, 1000);

    // Handle the session timeout + Check status again if page reloads:
    await this.checkStatus();

    // Re-initialise breadcrumbs on page reloads:
    localStorage.removeItem('breadcrumbsHistory');
  }

  showConsultRequest() {
    return this.user.institution.name !== 'CU';
  }

  async goConsult($event) {
    $event.preventDefault();
    await this.router.navigate(['consult_request']);
  }
}
