import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes, UrlSegment} from '@angular/router';
import {LoginComponent} from '@authentication/login/login.component';
import {LogoutComponent} from '@authentication/logout/logout.component';
import {SessionRedirectComponent} from '@authentication/session-redirect/session-redirect.component';
import {TimedoutComponent} from '@authentication/timedout/timedout.component';
import {HomeComponent} from '@home/home.component';
import {BrowseComponent} from '@navigation/browse/browse.component';
import {GraphComponent} from '@navigation/network/graph/graph.component';
import {NotFoundComponent} from '@navigation/not-found/not-found.component';
import {UpgradeBrowserComponent} from '@navigation/upgrade-browser/upgrade-browser.component';
import {CategoryComponent} from '@portal/category/category.component';
import {ConsultRequestFormComponent} from '@portal/consult-request-form/consult-request-form.component';
import {ResourceComponent} from '@portal/resource/resource.component';
import {SearchComponent} from '@portal/search/search.component';

export function searchFilterMatcher(url: UrlSegment[]) {
  if (url.length === 2 && url[0].path === 'search' && url[1].path === 'filter') {
    return {consumed: url};
  }
  return null;
}

const contentTitle = 'Health Research Resources/Events';
const browseTitle = 'Browse ' + contentTitle;
const searchTitle = 'Search ' + contentTitle;

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home',
  },
  {path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)},

  {
    path: 'browse',
    pathMatch: 'full',
    component: BrowseComponent,
    data: {title: browseTitle},
  },
  {
    path: 'browse/:category',
    pathMatch: 'full',
    component: BrowseComponent,
    data: {title: browseTitle},
  },
  {
    path: 'category_form/:category',
    pathMatch: 'full',
    loadComponent: () => import('./portal/category-form/category-form.component').then(m => m.CategoryFormComponent),
    data: {title: 'Edit Category'},
  },
  {
    path: 'category/:category',
    pathMatch: 'full',
    component: CategoryComponent,
    data: {title: browseTitle},
  },
  {
    path: 'consult_request',
    pathMatch: 'full',
    component: ConsultRequestFormComponent,
    data: {title: 'Request a Consult', hideHeader: false},
  },
  {
    path: 'help',
    pathMatch: 'full',
    loadChildren: () => import('./help/help.module').then(m => m.HelpModule),
  },
  {
    path: 'home',
    pathMatch: 'full',
    component: HomeComponent,
    data: {title: 'myTHRIV Workspace'},
  },
  {
    path: 'login',
    pathMatch: 'full',
    component: LoginComponent,
    data: {title: 'Log in to iTHRIV', hideHeader: true},
  },
  {
    path: 'login/:email_token',
    pathMatch: 'full',
    component: LoginComponent,
    data: {title: 'Log in to iTHRIV', hideHeader: true},
  },
  {
    path: 'logout',
    pathMatch: 'full',
    component: LogoutComponent,
    data: {title: 'Logged out from iTHRIV', hideHeader: true},
  },
  {
    path: 'network',
    pathMatch: 'full',
    component: GraphComponent,
    data: {title: browseTitle},
  },
  {
    path: 'network/:category',
    pathMatch: 'full',
    component: GraphComponent,
    data: {title: browseTitle},
  },
  {
    path: 'profile',
    loadChildren: () => import('@profile/profile.module').then(m => m.ProfileModule),
    pathMatch: 'full',
    data: {title: 'Edit your iTHRIV profile'},
  },
  {
    path: 'register',
    pathMatch: 'full',
    component: LoginComponent,
    data: {title: 'Sign up for iTHRIV', hideHeader: true},
  },
  {
    path: 'resource/:resource',
    pathMatch: 'full',
    component: ResourceComponent,
    data: {title: 'Resource/Event Details'},
  },
  {
    path: 'resource/:resource/edit',
    pathMatch: 'full',
    loadComponent: () => import('./portal/resource-form/resource-form.component').then(m => m.ResourceFormComponent),
    data: {title: 'Edit Resource'},
  },
  {
    path: 'event/:resource/edit',
    pathMatch: 'full',
    loadComponent: () => import('./portal/resource-form/resource-form.component').then(m => m.ResourceFormComponent),
    data: {title: 'Edit Event'},
  },
  {
    path: 'resource/add/:category',
    pathMatch: 'full',
    loadComponent: () => import('./portal/resource-form/resource-form.component').then(m => m.ResourceFormComponent),
    data: {title: 'Add Resource'},
  },
  {
    path: 'resource/add/new',
    pathMatch: 'full',
    loadComponent: () => import('./portal/resource-form/resource-form.component').then(m => m.ResourceFormComponent),
    data: {title: 'Add Resource'},
  },
  {
    path: 'event/add/:category',
    pathMatch: 'full',
    loadComponent: () => import('./portal/resource-form/resource-form.component').then(m => m.ResourceFormComponent),
    data: {title: 'Add Event'},
  },
  {
    path: 'event/add/new',
    pathMatch: 'full',
    loadComponent: () => import('./portal/resource-form/resource-form.component').then(m => m.ResourceFormComponent),
    data: {title: 'Add Event'},
  },
  {
    matcher: searchFilterMatcher,
    component: SearchComponent,
    data: {title: searchTitle},
  },
  {
    path: 'search',
    pathMatch: 'full',
    component: SearchComponent,
    data: {title: searchTitle},
  },
  {
    path: 'search/:query',
    pathMatch: 'full',
    component: SearchComponent,
    data: {title: searchTitle},
  },
  {
    path: 'session/:token',
    pathMatch: 'full',
    component: SessionRedirectComponent,
    data: {title: 'Logging in...', hideHeader: true},
  },
  {
    path: 'timedout',
    pathMatch: 'full',
    component: TimedoutComponent,
    data: {title: 'iTHRIV session timed out', hideHeader: true},
  },
  {
    path: 'upgrade_browser',
    pathMatch: 'full',
    component: UpgradeBrowserComponent,
    data: {title: 'Please upgrade your browser', hideHeader: true},
  },
  {
    path: 'public_commons',
    loadChildren: () => import('./public-commons/public-commons.module').then(m => m.PublicCommonsModule),
  },
  {
    path: 'private_commons',
    loadChildren: () => import('./private-commons/private-commons.module').then(m => m.PrivateCommonsModule),
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: PreloadAllModules,
      // enableTracing: true,
    }),
  ],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {}
