export const formatSize = (bytes: number, decimalPlaces = 2): string => {
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
  const factor = Math.pow(10, decimalPlaces);

  for (let i = 0; i < sizes.length; i++) {
    const divisor = Math.pow(10, 3 * i);
    const nextDivisor = Math.pow(10, 3 * (i + 1));

    if (bytes < nextDivisor) {
      return `${Math.round((bytes / divisor) * factor) / factor} ${sizes[i]}`;
    }
  }
};
