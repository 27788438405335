// Autogenerated by util/sync-labels/sync-labels.ts
// Do not edit this file directly.
// To regenerate this file, start ithriv_landing_service locally on port 8000 and
// run the following command in the root directory of the ithriv_web repository:
// > npm run sync_labels

export enum ProjectRole {
  OWNER = 'owner',
  COLLABORATOR = 'collaborator',
}

export const ProjectRoleLabels = {
  owner: 'Owner',
  collaborator: 'Collaborator',
} as const;

export type ProjectRoleId = keyof typeof ProjectRoleLabels;
export type ProjectRoleLabel = typeof ProjectRoleLabels[ProjectRoleId];
