import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute, Router} from '@angular/router';
import {CommonsProjectDocumentComponent} from '@commons/commons-project-document/commons-project-document.component';
import {CommonsApiService} from '@services/commons-api/commons-api.service';
import {ProjectFileVersionResponse, ProjectResponse} from '@services/landing-service';
import {ResourceApiService} from '@services/resource-api/resource-api.service';
import {UserService} from '@services/user-service/user.service';
import {ProjectFileCategoryLabels} from '@shared/labels/project-file-category';
import {CommonsProjectDocument, CommonsSearchProject, ProjectDocumentMap} from '@shared/types/commons-types';
import {User} from '@shared/types/user';
import {firstValueFrom, lastValueFrom} from 'rxjs';
import {ErrorSnackbarComponent} from '@form-controls/error-snackbar/error-snackbar.component';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-documents-card',
  templateUrl: './documents-card.component.html',
  styleUrls: ['./documents-card.component.scss'],
})
export class DocumentsCardComponent implements OnChanges, OnInit {
  @Input() project: ProjectResponse;
  @Input() projectWithPermissions: CommonsSearchProject;
  documentsDataSource: MatTableDataSource<ProjectFileVersionResponse>;
  displayedDocumentColumns: string[] = ['name', 'type', 'url', 'delete'];
  @Output() reloadEmit = new EventEmitter<any>();
  projectFileCategoryLabels = ProjectFileCategoryLabels;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private cas: CommonsApiService,
    private ras: ResourceApiService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
  ) {}

  ngOnInit() {
    // Only show documents that are the latest and not deleted version:
    this.documentsDataSource = new MatTableDataSource<ProjectFileVersionResponse>(
      this.project?.project_file_versions.filter(file => file.is_latest_and_not_deleted),
    );
  }

  ngOnChanges() {
    if (this.project && this.documentsDataSource) {
      this.documentsDataSource.data = this.project?.project_file_versions.filter(
        file => file.is_latest_and_not_deleted,
      );
    }
  }

  async downloadProjectDocument(document: ProjectFileVersionResponse, user: User) {
    const url = this.cas.getProjectFileUrl(this.user, this.project, document.file_version.id);
    await this.cas.downloadFile(url, document.file_version.file_name, user);
  }

  get user(): User {
    return this.userService.user;
  }

  async deleteDocument(document: ProjectFileVersionResponse) {
    try {
      await this.cascadeDelete(document.file_version.file_name, document.category);
      await firstValueFrom(this.ras.syncProject(this.project.id, this.user, this.user.institution.name));
      this.reloadEmit.emit();
    } catch (e) {
      this.displayError(e);
    }
  }

  /**
   * CascadeDelete: Deletes all project files with the same name and category.
   */
  async cascadeDelete(fileName: string, fileCategory: string) {
    for (const doc of this.project.project_file_versions) {
      if (doc.category === fileCategory && doc.file_version.file_name === fileName) {
        await lastValueFrom(this.cas.deleteDocument(this.project, doc, this.user));
      }
    }
  }

  /**
   * Casts any object to a ProjectDocument object, which is needed for
   * properly getting type hints in the documents table.
   */
  toTypedDocument(anyObject: unknown): ProjectFileVersionResponse {
    return anyObject as ProjectFileVersionResponse;
  }
  async addDocument() {
    const dialogRef = this.dialog.open(CommonsProjectDocumentComponent, {
      height: '400px',
      width: '600px',
      data: <ProjectDocumentMap>{
        user: this.user,
        project: this.project,
        document: <CommonsProjectDocument>{},
      },
    });

    const submitted = await lastValueFrom(dialogRef.afterClosed());
    if (submitted !== false) {
      await firstValueFrom(this.ras.syncProject(this.project.id, this.user, this.user.institution.name));
      this.reloadEmit.emit();
    }
  }

  async updateDocument(category: string) {
    const dialogRef = this.dialog.open(CommonsProjectDocumentComponent, {
      height: '400px',
      width: '600px',
      data: <ProjectDocumentMap>{
        user: this.user,
        project: this.project,
        document: <CommonsProjectDocument>{
          category: category,
        },
      },
    });

    await lastValueFrom(dialogRef.afterClosed());
    /** TODO: Replace this with new landing service project patch.
    await this.patch.patchProject(this.project);
     */
    this.reloadEmit.emit();
  }

  async restoreDocument(document) {
    try {
      await lastValueFrom(this.cas.restoreDocument(this.project, document, this.user));
    } catch (error) {
      console.error('Could not restore document:', error);
    }
    /** TODO: Replace this with new landing service project patch.
    await this.patch.patchProject(this.project);
     */
    this.reloadEmit.emit();
  }

  getDeleteButtonTooltip(document: CommonsProjectDocument) {
    return '';
    /** TODO: FIND ALTERNATE WAY TO SOLVE THIS
     if (document.can_be_deleted) {
      return `Click here to delete document (${document.filename})`;
    }

     const dataset = this.datasets.find(d => d.associatedDSP === document.url);
     const datasetName = dataset ? `the HSD dataset "${dataset.name}"` : 'a dataset';
     return (
     `This file (${document.filename}) cannot be deleted at this time, as it is associated with ${datasetName}. ` +
     `Please upload and associate a new DSP with that dataset before deleting this document.`
     );
     */
  }

  canDeleteDocument(doc) {
    return this.toTypedDocument(doc).file_version.id !== '' && this.projectWithPermissions._can_delete_data === true;
  }

  canUpdateDocument() {
    return this.projectWithPermissions._can_upload_data === true;
  }

  canDownloadDocument(doc) {
    return this.toTypedDocument(doc).file_version.id !== '' && this.projectWithPermissions._can_download_data === true;
  }

  canRestoreDocument(doc) {
    return this.toTypedDocument(doc).file_version.id !== '' && this.projectWithPermissions._can_upload_data === true;
  }

  canAddDocument() {
    return this.projectWithPermissions._can_upload_data === true;
  }

  hasDocuments() {
    return (
      this.project.project_file_versions.length > 0 &&
      this.project.project_file_versions.some(f => f.is_latest_and_not_deleted)
    );
  }

  private displayError(errorString?: string) {
    this.snackBar.openFromComponent(ErrorSnackbarComponent, {
      data: {errorString, action: 'Ok'},
      duration: 50000,
      panelClass: 'snackbar-warning',
    });
  }
}
