/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable({
    providedIn: 'root',
})
export class SystemUsageReportService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Download System Usage Report
     * Downloads all project or dataset meta and total file size for each as a csv file
     * @param recordType
     * @returns any Successful Response
     * @throws ApiError
     */
    public downloadSystemUsageReport(
        recordType: ('projects' | 'datasets'),
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/system-usage-report/{record_type}',
            path: {
                'record_type': recordType,
            },
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

}
