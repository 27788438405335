import {Component, Inject} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {cloneDeep} from 'lodash-es';
import {FormContainer} from '@form-controls/form-container';
import {CommonsSearchProject} from '@shared/types/commons-types';
import {FormField} from '@shared/types/form-field';
import {Resource} from '@shared/types/resource';
import {ErrorMatcher} from '@shared/validators/error-matcher';
import {ValidatePortalResourceUrls} from '@shared/validators/url.validator';
import {CommonsEulaDialogComponent} from '@commons/commons-eula-dialog/commons-eula-dialog.component';

@Component({
  selector: 'app-add-related-resource-dialog',
  templateUrl: './add-related-resource-dialog.component.html',
  styleUrls: ['./add-related-resource-dialog.component.scss'],
})
export class AddRelatedResourceDialogComponent {
  resource: Resource;
  resources: Resource[];
  events: Event[];
  fields: {[key: string]: FormField};
  errorMatcher = new ErrorMatcher();
  formGroup = new UntypedFormGroup({});
  formContainer: FormContainer;

  constructor(
    public dialogRef: MatDialogRef<CommonsEulaDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public project: CommonsSearchProject,
  ) {
    const urls = cloneDeep(this.project.portal_resources_urls.map(item => item.url));
    this.fields = {
      portal_resources_urls: new FormField({
        formGroup: this.formGroup,
        formControl: new UntypedFormControl(urls, [ValidatePortalResourceUrls]),
        required: false,
        placeholder: 'iTHRIV Portal resources/events that should be linked to this Commons Project:',
        type: 'list',
      }),
    };
    this.formContainer = new FormContainer(this.fields, this.formGroup);
  }

  checkValidity() {
    return this.fields?.portal_resources_urls?.formControl?.valid;
  }

  onSubmit(shouldSave: boolean) {
    if (shouldSave) {
      this.project.portal_resources_urls = this.fields.portal_resources_urls.formControl.value;
      this.dialogRef.close(this.project);
    } else {
      this.dialogRef.close();
    }
  }
}
