import {DatasetUserResponse, ProjectResponse, ProjectUserResponse} from '@services/landing-service';
import {DatasetRoleLabels} from '@shared/labels/dataset-role';
import {ProjectRoleLabels} from '@shared/labels/project-role';
import {cloneDeep} from 'lodash-es';
import {UnparseConfig} from 'papaparse';
import {CommonsApiService} from '@services/commons-api/commons-api.service';
import {ResourceApiService} from '@services/resource-api/resource-api.service';
import {CommonsSearchProject} from '@shared/types/commons-types';
import {Resource} from '@shared/types/resource';
import {ResourceQuery} from '@shared/types/resource-query';
import {User} from '@shared/types/user';
import {cleanUpCommaDelimitedList} from '@shared/validators/delimited_list.validator';
import {cleanUpHtml} from './clean-up-html';
import {lastValueFrom} from 'rxjs';

export type CsvDataItem = Resource | CommonsSearchProject | ProjectResponse;

export const getCsvKeys = (items: CsvDataItem[], redactFields: string[]): string[] => {
  if (items && items.length > 0) {
    return Object.keys(items[0])
      .filter(k => !redactFields.includes(k))
      .sort();
  } else {
    return [];
  }
};

export const prepareCsvData = async (
  items: CsvDataItem[],
  redactFields: string[],
  commonsApiService?: CommonsApiService,
  user?: User,
) => {
  if (!items || items.length === 0) {
    return [];
  }
  const keys = getCsvKeys(items, redactFields);

  const processedItems = await Promise.all(
    items.map(async r => {
      const returnObj = {};

      for (const key of keys) {
        if (r.hasOwnProperty(key)) {
          const val = r[key];

          if (['institution', 'icon'].includes(key)) {
            returnObj[key] = val.name;
          } else if (['alternate_name', 'keywords', 'partners'].includes(key)) {
            returnObj[key] = cleanUpCommaDelimitedList(val).replace(/,/g, '; ');
          } else if (key === 'project_users') {
            const projectUsers = r[key] as ProjectUserResponse[];
            returnObj[key + '_email'] = projectUsers.map(u => u.user.email).join('; ');
            returnObj[key + '_role'] = projectUsers.map(u => ProjectRoleLabels[u.project_role_id]).join('; ');
            returnObj[key + '_is_project_pi'] = r[key].map(u => u.is_project_pi).join('; ');
            returnObj[key + '_is_project_contact'] = r[key].map(u => u.is_project_contact).join('; ');
          } else if (key === 'dataset_users') {
            const datasetUsers = r[key] as DatasetUserResponse[];
            returnObj[key + '_email'] = datasetUsers.map(u => u.user.email).join('; ');
            returnObj[key + '_role'] = datasetUsers.map(u => DatasetRoleLabels[u.dataset_role_id]).join('; ');
          } else if (key === 'documents') {
            returnObj[key] = val.map(doc => doc.filename).join('; ');
          } else if (key === 'availabilities') {
            returnObj[key] = val
              .filter(a => a.available)
              .map(a => a.institution.name)
              .join('; ');
          } else if (key === 'resource_categories') {
            returnObj[key] = val.map(rc => rc.category.name).join('; ');
          } else if (Array.isArray(val)) {
            returnObj[key] = val.join('; ');

            if (val.length > 0 && typeof val[0] === 'string') {
              returnObj[key] = val.join('; ');
            } else if (val.length > 0 && typeof val[0] === 'object') {
              returnObj[key] = val.map(f => f.name || f.url || f.id || '').join('; ');
            } else {
              returnObj[key] = '';
            }
          } else if (val && typeof val === 'object') {
            returnObj[key] = val.name || val.id || '';
          } else if (key === 'description') {
            returnObj[key] = cleanUpHtml(val);
          } else {
            returnObj[key] = val || '';
          }
        }
      }
      return returnObj;
    }),
  );

  return processedItems;
};

/**
 * Given an options object with a Resource API service and/or list of resources,
 * parses the resources list into CSV data and calls the onComplete callback
 * function with that CSV data.
 * @param options
 */
export const convertResourceListToCsvData = async (options: {
  items?: CsvDataItem[];
  redactFields: string[];
  resourceQuery?: ResourceQuery;
  resourceApiService?: ResourceApiService;
  commonsApiService?: CommonsApiService;
  user?: User;
  onComplete?: (data: any[]) => void;
}) => {
  // If this is the search screen, get all unpaginated data from API.
  if (options.resourceQuery && options.resourceApiService) {
    // Clone the search query
    const csvQuery = cloneDeep(options.resourceQuery);
    delete csvQuery.resources;
    delete csvQuery.facets;

    // Get all available results
    csvQuery.start = 0;
    csvQuery.size = csvQuery.total;

    // Fetch the data from the backend
    const query = await lastValueFrom(options.resourceApiService.searchResources(csvQuery));
    return await prepareCsvData(query.resources, options.redactFields);
  } else if (options.items) {
    // Otherwise, just get data from the list of resources displayed.
    return await prepareCsvData(options.items, options.redactFields, options.commonsApiService, options.user);
  }
};

export const getCsvFilename = (prefix: string): string => {
  const d = new Date();
  return `${prefix} - ${d.toISOString().slice(0, 9)}.csv`;
};

export const getCsvOptions = (items: CsvDataItem[], redactFields: string[]): UnparseConfig => {
  const csvOptions = {
    columns: [],
  };

  if (items && items.length > 0) {
    csvOptions.columns = getCsvKeys(items, redactFields);
  }
  return csvOptions;
};
