import {
  DatasetCreate,
  DatasetPatch,
  DatasetResponse,
  DatasetUserResponse,
  DatasetUserRoleReference,
  DICOMDatasetResponse,
  GenericDatasetResponse,
  ProjectCreate,
  ProjectPatch,
  ProjectResponse,
  ProjectUserRoleReference,
  RedcapDatasetResponse,
} from '@services/landing-service';
import {DatasetFlagValue, ProjectFlagValue} from '@shared/constants/constants';
import {FormField} from '@shared/types/form-field';
import {Paths} from '@shared/types/nested-object-paths';
import {User} from '@shared/types/user';
import {Subscription} from 'rxjs';

export interface RightColField {
  label: string;
  value: boolean | string | string[] | number | number[] | Date;
}

export class FileUploadModel {
  data: File;
  state: string;
  inProgress: boolean;
  progress: number;
  canRetry: boolean;
  canCancel: boolean;
  sub?: Subscription;
}

export interface ProjectDocumentMap {
  user: User;
  project: CommonsSearchProject;
  document: CommonsProjectDocument;
}
export interface CommonsProjectDocument {
  _fileDeleted: boolean;
  category: string;
  encodingFormat: string;
  fileCreatedBy: {email: string};
  fileName: string;
  fileSize: number;
  fileVersion: string;
  url: string;
}

export const ProjectFileCategories = {
  contract: 'Contract',
  protocol: 'Protocol',
  'irb-application': 'IRB Application',
  'irb-approval': 'IRB Approval',
  'data-security-plan': 'Data Security Plan',
  publication: 'Publication',
  other: 'Other',
} as const;

export type ProjectFileCategoryId = keyof typeof ProjectFileCategories;
export type ProjectFileCategoryLabel = (typeof ProjectFileCategories)[ProjectFileCategoryId];
export const ProjectFileCategoryLabels: ProjectFileCategoryLabel[] = Object.values(ProjectFileCategories).map(
  c => c as ProjectFileCategoryLabel,
);
export const ProjectFileCategoryIds: ProjectFileCategoryId[] = Object.keys(ProjectFileCategories).map(
  c => c as ProjectFileCategoryId,
);
export const ROLES_TOOLTIPS = {
  'PROJECT OWNER':
    'A project owner has full project permissions including and exceeding project collaborators. A ' +
    'project owner can add other users to the project as owners. A project owner can add any member user as ' +
    'collaborator.  Members from partner sites can only be added if a contract document has already been uploaded to ' +
    'the project.  A project owner can edit project metadata and set the metadata access flag (private/public). They ' +
    'can also delete any project they own.',
  'PROJECT COLLABORATOR':
    'Project collaborators can add datasets to the project, manage project documents, and add to ' +
    'the project chat. The project collaborator only has access to datasets attached to the project IF the respective ' +
    'dataset admins grant them that access. When a collaborator adds a dataset he/she becomes the initial dataset ' +
    'administrator.',
  'DATASET ADMINISTRATOR':
    'Responsible for managing dataset for a project with full permission on the dataset. They ' +
    'can delete dataset, control permissions, and edit metadata. If the dataset if highly sensitive, they can only ' +
    'add permission for other researchers who are active on the associated IRB protocol.',
  'DATASET COLLABORATOR':
    'A user who is given permission by the Dataset Administrator to work with (download/edit) ' +
    'the dataset file. Note that dataset collaborators can see the file change history and can access previous ' +
    'versions of the dataset. The dataset collaborator must first be listed on the parent project where the dataset ' +
    'lives in. In the case of highly sensitive data, this group is restricted and must be a subset of users ' +
    'associated with the approved IRB protocol.',
  'DATASET CUSTOMER':
    'Any iTHRIV Member User who is given explicit personal permission to download the current ' +
    'version of the dataset. A customer does NOT have to be a project team member. They cannot make changes (edits) ' +
    'to the file and they cannot upload new versions. They cannot access older versions.',
};

export const getRoleTooltip = (value: string): string => {
  return ROLES_TOOLTIPS[value];
};

export interface UserPermissionMap {
  userPermission: UserPermission;
  permissionsMap: {[key: string]: string};
  isDataset: boolean;
  hasIrbNumber?: boolean;
  irbInvestigators?: IrbInvestigator[];
  projectTeamMembers?: ProjectUserRoleReference[];
}

export type UserPermission = DatasetUserRoleReference | ProjectUserRoleReference;

export interface DatasetUserPermissions {
  all: DatasetUserResponse[];
  team: DatasetUserResponse[];
  customer: DatasetUserResponse[];
}

export interface Add {
  sum?: string;
}

export interface IrbInvestigator {
  email: string;
}
export type SystemReportType = 'projects' | 'datasets';

export type CommonsStateForm =
  | 'commons-private'
  | 'commons-public'
  | 'commons-project-private'
  | 'commons-project-public'
  | 'commons-project-create-edit'
  | 'commons-dataset-private'
  | 'commons-dataset-public'
  | 'commons-dataset-create-edit'
  | 'commons-dataset-spreadsheet';

export interface CommonsStateProjects {
  all?: ProjectResponse[];
  private?: ProjectResponse[];
  public?: ProjectResponse[];
}

export interface CommonsStateDatasets {
  all?: DatasetResponse[];
  private?: DatasetResponse[];
  public?: DatasetResponse[];
}

export interface CommonsState {
  projects?: CommonsStateProjects;
  datasets?: CommonsStateDatasets;
  currentDataset?: DatasetResponse;
  currentProject?: ProjectResponse;
  previousForm?: CommonsStateForm;
  displayForm: CommonsStateForm;
  scrollTo?: string;
}

export interface CommonsSearchResponse {
  hits: CommonsSearchHit[];
  max_score: number;
  start: number;
  total: CommonsSearchTotal;
}

export interface CommonsSearchTotal {
  relation: string;
  value: number;
}

export interface CommonsSearchHit {
  _id: string;
  _index: string;
  _score: number;
  _source: CommonsSearchResult;
  _type: string;
}

export class CommonsNamedObject {
  name: string;
}

// ithriv_service adds the "_can_*" fields to the ProjectResponse
export type CommonsSearchProjectFlagsOnly = {[K in ProjectFlagValue]: boolean};
export type CommonsSearchProject = ProjectResponse & CommonsSearchProjectFlagsOnly;
export type CommonsSearchProjectOptional = Partial<CommonsSearchProject>;

// ithriv_service adds the "_can_*" fields to the DatasetResponse
export type CommonsSearchDatasetFlagsOnly = {[K in DatasetFlagValue]: boolean};

// ithriv_service adds the "url" field to the DatasetResponse when the dataset data is marked as public
export type CommonsSearchDatasetPublicOnly = {url?: string};
export type CommonsSearchGenericDataset = GenericDatasetResponse &
  CommonsSearchDatasetFlagsOnly &
  CommonsSearchDatasetPublicOnly;
export type CommonsSearchREDCapDataset = RedcapDatasetResponse &
  CommonsSearchDatasetFlagsOnly &
  CommonsSearchDatasetPublicOnly;
export type CommonsSearchDICOMDataset = DICOMDatasetResponse &
  CommonsSearchDatasetFlagsOnly &
  CommonsSearchDatasetPublicOnly;
export type CommonsSearchDataset = CommonsSearchGenericDataset | CommonsSearchREDCapDataset | CommonsSearchDICOMDataset;
export type CommonsSearchDatasetOptional = Partial<CommonsSearchDataset>;
export type CommonsSearchREDCapDatasetOptional = Partial<CommonsSearchREDCapDataset>;
export type CommonsSearchDICOMDatasetOptional = Partial<CommonsSearchDICOMDataset>;

export type CommonsSearchResult = CommonsSearchDataset | CommonsSearchProject;

export interface CommonsOrganization {
  name: string;
}

export interface CommonsPerson {
  email: string;
}

export interface CommonsStringSearchParams {
  q: string;
}

export interface CommonsDateSearchParams {
  start: Date;
  end: Date;
}

export interface CommonsDatasetTemporalCoverage {
  start_date: Date;
  end_date: Date;
}

export interface CommonsProjectSearchParams {
  principal_investigator?: CommonsPerson;
  contact_person?: CommonsPerson;
  source_organization?: string;
  customer?: CommonsPerson;
  partner?: string;
  funder?: string;
  alternate_name?: string;
  host_organization?: string;
  keywords?: string[];
  date_created?: CommonsDateSearchParams;
  contributor?: CommonsPerson;
  is_metadata_public?: boolean;
  description?: string;
  date_modified?: CommonsDateSearchParams;
  name?: string;
  creator?: CommonsPerson;
}

export interface CommonsDatasetSearchParams {
  keywords: string[];
  contributor: CommonsPerson;
  creator: CommonsPerson;
  customer: CommonsPerson;
  identifiers_hipaa: string;
  temporal_coverage: CommonsDatasetTemporalCoverage;
  contract: string;
  is_metadata_public: boolean;
  description: string;
  source_organization: string;
  spatial_coverage: string;
  file_name: string;
  license: string;
  host_organization: string;
  publisher: CommonsOrganization;
  publisher_id: string;
  date_created: CommonsDateSearchParams;
  date_modified: CommonsDateSearchParams;
  other_sensitive_data: string;
  approved_irb: string;
  name: string;
  variable_measured: string;
  is_data_public: boolean;
}

export type DatasetUnion = DatasetCreate | DatasetPatch | DatasetResponse | CommonsSearchDataset;
export type ProjectUnion = ProjectCreate | ProjectPatch | ProjectResponse | CommonsSearchProject;
export type CommonsObject = DatasetUnion | ProjectUnion;
export type CommonsObjectWithJoins = DatasetResponse | CommonsSearchDataset | ProjectResponse | CommonsSearchProject;
export type DatasetFormFieldPaths = Paths<DatasetCreate | {extract_options: any}>;

export type DatasetFormFields = {
  [key in DatasetFormFieldPaths]?: FormField;
};

export type DatasetFormDefaultValueMap = {
  [key in DatasetFormFieldPaths]?: any;
};
