/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { DatasetCreate } from '../models/DatasetCreate';
import type { DatasetPatch } from '../models/DatasetPatch';
import type { DatasetPut } from '../models/DatasetPut';
import type { DatasetResponse } from '../models/DatasetResponse';
import type { DatasetResponseList } from '../models/DatasetResponseList';
import type { FileVersionResponse } from '../models/FileVersionResponse';
import type { RequestBodyFileUpload } from '../models/RequestBodyFileUpload';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable({
    providedIn: 'root',
})
export class DatasetsService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get Datasets
     * Gets a list of all dataset metadata (matching query params) where
     * requesting user has permissions to view
     * @param page
     * @param perPage
     * @param showAll
     * @param name
     * @param description
     * @param datasetType
     * @param license
     * @param studyIrbNumber
     * @param sensitivityLevelId
     * @param dataAggregationId
     * @param doi
     * @param isMetadataPublic
     * @param isDataPublic
     * @param timeCreated
     * @param timeUpdated
     * @param sourceOrganizationId
     * @param publisherId
     * @returns DatasetResponseList Successful Response
     * @throws ApiError
     */
    public listDatasets(
        page?: (number | null),
        perPage?: (number | null),
        showAll?: (boolean | null),
        name?: (string | null),
        description?: (string | null),
        datasetType?: (string | null),
        license?: (string | null),
        studyIrbNumber?: (string | null),
        sensitivityLevelId?: (string | null),
        dataAggregationId?: (string | null),
        doi?: (string | null),
        isMetadataPublic?: (boolean | null),
        isDataPublic?: (boolean | null),
        timeCreated?: (string | null),
        timeUpdated?: (string | null),
        sourceOrganizationId?: (string | null),
        publisherId?: (string | null),
    ): Observable<DatasetResponseList> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/datasets',
            query: {
                'page': page,
                'per_page': perPage,
                'show_all': showAll,
                'name': name,
                'description': description,
                'dataset_type': datasetType,
                'license': license,
                'study_irb_number': studyIrbNumber,
                'sensitivity_level_id': sensitivityLevelId,
                'data_aggregation_id': dataAggregationId,
                'doi': doi,
                'is_metadata_public': isMetadataPublic,
                'is_data_public': isDataPublic,
                'time_created': timeCreated,
                'time_updated': timeUpdated,
                'source_organization_id': sourceOrganizationId,
                'publisher_id': publisherId,
            },
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Create Dataset
     * Creates a new dataset
     * @param requestBody
     * @returns any Successful Response
     * @returns string Created
     * @throws ApiError
     */
    public createDataset(
        requestBody: DatasetCreate,
    ): Observable<any | string> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/datasets',
            body: requestBody,
            mediaType: 'application/json',
            responseHeader: 'Location',
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get Dataset
     * Gets metadata for a specified (dataset_id) dataset where requesting user has a role on the dataset
     * @param datasetId
     * @returns DatasetResponse Successful Response
     * @throws ApiError
     */
    public getDataset(
        datasetId: string,
    ): Observable<DatasetResponse> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/datasets/{dataset_id}',
            path: {
                'dataset_id': datasetId,
            },
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update Dataset
     * Updates dataset metadata for a specified (dataset_id) dataset
     * where requesting user is an admin on the dataset.
     * @param datasetId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateDataset(
        datasetId: string,
        requestBody: DatasetPut,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/datasets/{dataset_id}',
            path: {
                'dataset_id': datasetId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Patch Dataset
     * Patches dataset metadata for a specified (dataset_id) dataset
     * where requesting user is an admin on the dataset.
     * @param datasetId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public editDataset(
        datasetId: string,
        requestBody: DatasetPatch,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'PATCH',
            url: '/datasets/{dataset_id}',
            path: {
                'dataset_id': datasetId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Delete Dataset
     * Schedules a specified (dataset_id) dataset where requesting user is an admin on the dataset for permanent
     * deletion. If param: 'immediate' is True, a permanent and immediate delete occurs for the dataset as well as all its
     * associated files.
     * @param datasetId
     * @param immediate
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteDataset(
        datasetId: string,
        immediate?: (boolean | null),
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/datasets/{dataset_id}',
            path: {
                'dataset_id': datasetId,
            },
            query: {
                'immediate': immediate,
            },
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Restore Dataset
     * If a dataset is scheduled to be deleted, this endpoint will remove it from the schedule
     * @param datasetId
     * @returns any Successful Response
     * @throws ApiError
     */
    public restoreDataset(
        datasetId: string,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/datasets/{dataset_id}/restore',
            path: {
                'dataset_id': datasetId,
            },
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Upload Dataset File
     * Uploads a file to a specified (dataset_id) dataset.
     * Set param "edit" to True for file editing in iThriv portal.
     * @param datasetId
     * @param formData
     * @param edit
     * @returns FileVersionResponse Successful Response
     * @throws ApiError
     */
    public uploadDatasetFile(
        datasetId: string,
        formData: RequestBodyFileUpload,
        edit?: (boolean | null),
    ): Observable<FileVersionResponse> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/datasets/{dataset_id}/files',
            path: {
                'dataset_id': datasetId,
            },
            query: {
                'edit': edit,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Download Dataset File
     * Downloads specified version of data file of a specified (dataset_id)
     * dataset. If "latest" is passed in "version_id", the most recent version of the file in minio is returned.
     * Set param "edit" to True for file editing in iThriv portal.
     * @param datasetId
     * @param fileVersionId
     * @param edit
     * @returns any Successful Response
     * @throws ApiError
     */
    public downloadDatasetFile(
        datasetId: string,
        fileVersionId: ('latest' | string),
        edit?: (boolean | null),
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/datasets/{dataset_id}/files/{file_version_id}',
            path: {
                'dataset_id': datasetId,
                'file_version_id': fileVersionId,
            },
            query: {
                'edit': edit,
            },
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Delete Dataset File
     * Schedules a specified dataset file version for permanent deletion. If param: 'immediate' is True, a permanent and
     * immediate delete occurs from the database and file storage for the specified file version
     * @param datasetId
     * @param fileVersionId
     * @param immediate
     * @returns any Successful Response
     * @throws ApiError
     */
    public deleteDatasetFile(
        datasetId: string,
        fileVersionId: string,
        immediate?: (boolean | null),
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/datasets/{dataset_id}/files/{file_version_id}',
            path: {
                'dataset_id': datasetId,
                'file_version_id': fileVersionId,
            },
            query: {
                'immediate': immediate,
            },
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Restore Dataset File
     * Restores a document that was previously deleted from a specified (project_id) project
     * @param datasetId
     * @param fileVersionId
     * @returns any Successful Response
     * @throws ApiError
     */
    public restoreDatasetFile(
        datasetId: string,
        fileVersionId: string,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/datasets/{dataset_id}/files/{file_version_id}/restore',
            path: {
                'dataset_id': datasetId,
                'file_version_id': fileVersionId,
            },
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Is Scan Complete
     * Returns True if scan is complete on a given a dataset file version
     * @param datasetId
     * @param fileVersionId
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public isScanComplete(
        datasetId: string,
        fileVersionId: ('latest' | string),
    ): Observable<boolean> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/datasets/{dataset_id}/is-scan-complete/{file_version_id}',
            path: {
                'dataset_id': datasetId,
                'file_version_id': fileVersionId,
            },
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get Pis On Dataset Associated Projects Over Size Limit
     * Gets a list of pi's associated with the dataset's primary project that are over the file size limit
     * @param datasetId
     * @returns any Successful Response
     * @throws ApiError
     */
    public getPisOnDatasetAssociatedProjectsOverSizeLimit(
        datasetId: string,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/datasets/{dataset_id}/pi-size-limit',
            path: {
                'dataset_id': datasetId,
            },
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

}
