/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DatasetAggregationLevelID {
    NONE = 'none',
    ROW_LEVEL = 'row-level',
    GROUPS = 'groups',
}
