import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatRadioChange} from '@angular/material/radio';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';
import {ACCEPTABLE_EMAIL_FORMATS, getLoginServices} from '@authentication/login-service';
import {AddPermissionComponent} from '@commons/add-permission/add-permission.component';
import {getNavItems} from '@commons/commons-nav/commons-nav-util';
import {
  SensitiveDataDialogComponent,
  SensitiveDialogData,
} from '@commons/sensitive-data-dialog/sensitive-data-dialog.component';
import {ParsedError} from '@form-controls/error-message/error-message.component';
import {ErrorSnackbarComponent} from '@form-controls/error-snackbar/error-snackbar.component';
import {FormContainer, loadFieldsets} from '@form-controls/form-container';
import {CommonsApiService} from '@services/commons-api/commons-api.service';
import {
  DatasetAggregationLevelID,
  DatasetCreate,
  DatasetPatch,
  DatasetPut,
  DatasetRedcapMetadataBaseWithTokenExcludedInResponse,
  DatasetResponse,
  DatasetRoleID,
  DatasetType,
  DatasetUserResponse,
  DatasetUserRoleReference,
  DICOMDatasetResponse,
  IdentifierHipaaID,
  IRBUserProtocolsResponse,
  Keyword,
  LabelTextIDResponse,
  OtherSensitiveDataID,
  ProjectResponse,
  ProjectRoleID,
  ProjectUserResponse,
  ProjectUserRoleReference,
  RedcapDatasetResponse,
  TemporalCoverageBase,
  TemporalCoverageResponse,
} from '@services/landing-service';
import {ResourceApiService} from '@services/resource-api/resource-api.service';
import {UserService} from '@services/user-service/user.service';
import {
  DATASET_ROLES,
  HSD_HIPAA_OPTIONS,
  IDENTIFIERS_HIPAA,
  REDCAP_TOKEN_PLACEHOLDER,
} from '@shared/constants/constants';
import {DatasetAggregationLevel, DatasetAggregationLevelLabels} from '@shared/labels/dataset-aggregation-level';
import {OtherSensitiveDataLabels} from '@shared/labels/other-sensitive-data';
import {ProjectRole, ProjectRoleId} from '@shared/labels/project-role';
import {
  CommonsSearchDataset,
  CommonsStateForm,
  DatasetFormDefaultValueMap,
  DatasetFormFields,
  DatasetUserPermissions,
  IrbInvestigator,
  ProjectFileCategoryId,
  UserPermission,
  UserPermissionMap,
} from '@shared/types/commons-types';
import {Fieldset} from '@shared/types/fieldset';
import {FormField} from '@shared/types/form-field';
import {FormSelectOption} from '@shared/types/form-select-option';
import {Institution} from '@shared/types/institution';
import {NavItem} from '@shared/types/nav-item';
import {DICOM_FIELDS, GENERIC_FIELDS, REDCAP_FIELDS} from '@shared/validators/dataset_type.validator';
import {ErrorMatcher} from '@shared/validators/error-matcher';
import {FormType, getFieldValidators} from '@shared/validators/get-field-validators';
import {isHipaaHsd, ValidateHsd} from '@shared/validators/hsd.validator';
import dayjs from '@src/assets/dayjs';
import {isHSD, makeDatasetPutFromDatasetResponse} from '@utilities/commons-dataset-util';
import {removeEmptyItems} from '@utilities/remove-empty-items';
import {scrollToRouteParam} from '@utilities/scroll-to-selector';
import {firstValueFrom, lastValueFrom} from 'rxjs';

@Component({
  selector: 'app-commons-dataset-create-edit',
  templateUrl: './commons-dataset-create-edit.component.html',
  styleUrls: ['./commons-dataset-create-edit.component.scss'],
})
export class CommonsDatasetCreateEditComponent implements OnInit {
  createNew = false;
  dataset: DatasetResponse;
  datasetWithPermissions: CommonsSearchDataset;
  dateTimeRange: Date[];
  defaultValueMap: DatasetFormDefaultValueMap;
  displayedUserpermColumns: string[] = ['email', 'role', 'edit', 'delete'];
  emailFormatsText = ACCEPTABLE_EMAIL_FORMATS;
  error: String;
  errorMatcher = new ErrorMatcher();
  errorMessage: string;
  errorMessagePerm: string;
  fg: UntypedFormGroup = new UntypedFormGroup({});
  fields: DatasetFormFields = {};
  fieldsets: Fieldset[] = [];
  files = {};
  formContainer: FormContainer;
  formStatus = 'form';
  institutions: Institution[];
  irbInvestigators: IrbInvestigator[] = [];
  irbNumbers: IRBUserProtocolsResponse[];
  isHSD = isHSD;
  navItems: NavItem[];
  needsRedcapToken: boolean = false;
  newDataset: DatasetCreate | DatasetPut;
  project: ProjectResponse;
  publisherName: string;
  showConfirmDelete = false;
  userPermissions: DatasetUserPermissions;

  constructor(
    public cas: CommonsApiService,
    public changeDetectorRef: ChangeDetectorRef,
    public ras: ResourceApiService,
    public route: ActivatedRoute,
    public router: Router,
    public userService: UserService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
  ) {}

  get datasetIdFromRoute(): string {
    return this.route.snapshot.paramMap.get('dataset_id');
  }

  get projectIdFromRoute(): string {
    return this.route.snapshot.paramMap.get('project_id');
  }

  get datasetTitle(): string {
    return this.isLoaded ? `Dataset: ${this.dataset?.name || 'New'}` : '';
  }

  get isLoaded(): boolean {
    // Remove the DS with permissions IF it's a new dataset??
    return !!(
      this.userService?.user &&
      this.project &&
      (this.newDataset || (this.dataset && this.datasetWithPermissions)) &&
      this.fields &&
      this.formContainer
    );
  }

  get isEdit(): boolean {
    return this.dataset && ![undefined, null, ''].includes(this.dataset.id);
  }

  ngOnInit() {
    this.tryLoad();
  }

  async tryLoad() {
    if (this.userService?.user) {
      await this.loadInstitutions();
      await this.loadProject();
      await this.loadDataset();
      await this.loadIRBNumbers();
      await this.loadIrbInvestigators();

      await this.loadFields();
      this.loadForm();
      this.loadNavItems();
      await this.loadPermissions();
      await scrollToRouteParam(this.route);
    } else {
      setTimeout(() => {
        this.tryLoad();
      }, 1000);
    }
  }

  showNext(dataset_id: string) {
    this.router.navigate(['/private_commons', 'project', this.projectIdFromRoute, 'dataset', dataset_id]);
  }

  async loadFields() {
    this.loadDefaultValues();
    this.fields = {
      ...this.loadGenericFields(),
      ...this.loadREDCapFields(),
      ...this.loadDICOMFields(),
    };
  }

  loadForm() {
    this.createNew = !this.dataset?.id;

    Object.entries(this.fields).forEach(([fieldName, field]) => {
      if (field.formControl) {
        field.name = fieldName;
        this.fg.addControl(fieldName, field.formControl);
        field.formControl.setValidators(
          getFieldValidators(field, FormType.DATASET, this.getDefaultFieldValue('dataset_type')),
        );
        field.formControl.setValue(this.getDefaultFieldValue(fieldName));
      }
    });

    this.formContainer = new FormContainer(this.fields, this.fg);
    this.fieldsets = loadFieldsets(this.fields);

    this.handleDatasetTypeChange();

    if (!this.createNew) {
      this.validate();
    }
  }

  async loadIrbInvestigators() {
    this.irbInvestigators = [];

    if (this.isEdit && this.dataset?.study_irb_number) {
      this.irbInvestigators = await lastValueFrom(
        this.cas.getDatasetIrbInvestigators(this.userService?.user, this.dataset),
      );
    }

    return this.irbInvestigators;
  }

  async loadPermissions(): Promise<DatasetUserPermissions> {
    // Load empty permissions by default.
    this.userPermissions = {
      all: [],
      team: [],
      customer: [],
    };

    if (this.datasetWithPermissions?._can_upload_data) {
      try {
        this.dataset.dataset_users?.forEach(perm => {
          this.userPermissions.all.push(perm);
          if ([DatasetRoleID.CUSTOMER].includes(perm.dataset_role_id as DatasetRoleID)) {
            this.userPermissions.customer.push(perm);
          } else {
            this.userPermissions.team.push(perm);
          }
        });
      } catch (e) {
        console.error(e);
      }
    }

    return this.userPermissions;
  }

  lookupRole(lookupKey: string) {
    return DATASET_ROLES[lookupKey];
  }

  toTypedUP(userPermission: unknown) {
    return userPermission as DatasetUserResponse;
  }

  async addPermission(): Promise<void> {
    const dialogRef = this.dialog.open(AddPermissionComponent, {
      height: '400px',
      width: '600px',
      data: await this.buildUserPermissionMap({
        email: '',
        role: DatasetRoleID.COLLABORATOR,
      }),
    });

    const newPermission: DatasetUserRoleReference = await lastValueFrom(dialogRef.afterClosed());
    if (newPermission) {
      const updatedUsers = this.dataset.dataset_users.map((du: DatasetUserResponse) => {
        return <DatasetUserRoleReference>{
          email: du.user.email,
          role: du.dataset_role_id,
        };
      });
      updatedUsers.push(newPermission);
      const fields: DatasetPatch = {
        users: updatedUsers,
      };
      await lastValueFrom(this.cas.patchDataset(this.dataset, fields));
      await firstValueFrom(
        this.ras.syncDataset(this.datasetIdFromRoute, this.userService?.user, this.dataset.publisher.name),
      );
      await this.tryLoad();
    }
  }

  async editPermission(userPermission: DatasetUserResponse): Promise<void> {
    const roleRef: DatasetUserRoleReference = {
      email: userPermission.user.email,
      role: userPermission.dataset_role_id as DatasetRoleID,
    };
    const dialogRef = this.dialog.open(AddPermissionComponent, {
      height: '400px',
      width: '600px',
      data: await this.buildUserPermissionMap(roleRef),
    });

    const newUserPermission: UserPermission = await lastValueFrom(dialogRef.afterClosed());
    if (newUserPermission) {
      try {
        const updatedUsers = this.dataset.dataset_users.map((du: DatasetUserResponse) => {
          const isEditedUser = newUserPermission.email === du.user.email;
          return <DatasetUserRoleReference>{
            email: du.user.email,
            role: isEditedUser ? newUserPermission.role : du.dataset_role_id,
          };
        });
        const fields: DatasetPatch = {
          users: updatedUsers,
        };
        await lastValueFrom(this.cas.patchDataset(this.dataset, fields));
        await firstValueFrom(
          this.ras.syncDataset(this.datasetIdFromRoute, this.userService?.user, this.dataset.publisher.name),
        );
        await this.tryLoad();
        this.errorMessagePerm = '';
      } catch (permissionError) {
        this.displayError(permissionError);
        this.errorMessagePerm = permissionError;
      }
    } else {
      await this.loadPermissions();
    }
  }

  async deletePermission(toDelete: DatasetUserResponse) {
    try {
      const updatedUsers: DatasetUserRoleReference[] = this.dataset.dataset_users
        .filter(du => du.user.email !== toDelete.user.email)
        .map((du: DatasetUserResponse) => {
          return <DatasetUserRoleReference>{
            email: du.user.email,
            role: du.dataset_role_id as DatasetRoleID,
          };
        });
      const fields: DatasetPatch = {
        users: updatedUsers,
      };
      await lastValueFrom(this.cas.patchDataset(this.dataset, fields));
      await firstValueFrom(
        this.ras.syncDataset(this.datasetIdFromRoute, this.userService?.user, this.dataset.publisher.name),
      );
      await this.tryLoad();
      this.errorMessagePerm = '';
    } catch (error) {
      this.displayError(error);
      this.errorMessagePerm = error;
    }
  }

  async confirmSensitiveData(): Promise<boolean> {
    const dialogRef = this.dialog.open(SensitiveDataDialogComponent, {
      height: '170px',
      width: '600px',
      data: <SensitiveDialogData>{
        dataset: this.newDataset,
        hsd: isHipaaHsd(this.fg),
        confirm: false,
      },
    });
    const data: SensitiveDialogData = await lastValueFrom(dialogRef.afterClosed());
    return data.confirm;
  }

  validate() {
    return this.formContainer.validate();
  }

  async submitDataset($event) {
    $event.preventDefault();
    this.formStatus = 'form';
    this.validate(); // Validate must be called twice to display all errors
    if (this.validate()) {
      const confirm = await this.confirmSensitiveData();
      if (confirm) {
        await this.saveChanges();
      }
    } else {
      this.displayFormErrors();
    }
  }

  async saveChanges(): Promise<void> {
    const isNew = this.createNew === true;
    this.copyFormValuesToDataset();

    try {
      const updatedDatasetUrl = isNew
        ? await lastValueFrom(this.cas.createDataset(this.newDataset, this.publisherName))
        : await lastValueFrom(this.cas.updateDataset(this.datasetIdFromRoute, this.dataset, this.newDataset));
      const newDatasetId = isNew && updatedDatasetUrl ? updatedDatasetUrl.split('/').pop() : this.datasetIdFromRoute;

      this.formStatus = 'submitting';
      if (isNew && newDatasetId) {
        // Wait for the sync process to complete before redirecting to the new Dataset Details screen,
        // to ensure that the new dataset is available in Elasticsearch.
        await this.ras.waitForSyncDataset(newDatasetId, this.userService?.user, this.publisherName);
        await this.loadDataset(newDatasetId);
      } else {
        // No need to wait for the sync process to complete before redirecting to the Details screen for
        // an existing Dataset.
        await firstValueFrom(this.ras.syncDataset(this.datasetIdFromRoute, this.userService?.user, this.publisherName));
      }

      this.errorMessage = '';

      if (this.needsRedcapToken) {
        await this.requestREDCapToken();
      }

      // Redirect to the new dataset page.
      this.showNext(newDatasetId);
      this.formStatus = 'complete';
    } catch (e) {
      this.errorMessage = e || CommonsApiService.getErrorText(this.createNew ? 'create dataset' : 'update dataset');
      console.error(this.errorMessage);
      this.displayError(this.errorMessage);
      this.formStatus = 'form';
      this.changeDetectorRef.detectChanges();
    }
  }

  cancelDataset() {
    const commands = ['/private_commons', 'project', this.projectIdFromRoute];

    if (this.datasetIdFromRoute) {
      commands.push('dataset', this.datasetIdFromRoute);
    }

    this.router.navigate(commands);
  }

  onCancel() {
    this.showForm();
  }

  showForm() {
    this.formContainer.reset();
    this.formStatus = 'form';
  }

  async requestREDCapToken() {
    if (!this.needsRedcapToken) {
      return;
    }

    this.errorMessage = '';
    try {
      await lastValueFrom(
        this.ras.sendConsultRequest(
          this.userService?.user,
          'Informatics Tools',
          'Inquiry',
          'iTHRIV commons portal: Request to create REDCap token',
          (this.dataset as RedcapDatasetResponse).redcap_metadata.redcap_project_url,
        ),
      );
    } catch (requestError) {
      this.errorMessage =
        requestError || CommonsApiService.getErrorText('submit request to create REDCap token', false);
      this.displayError(this.errorMessage);
    }
  }

  async buildUserPermissionMap(userPermission: UserPermission): Promise<UserPermissionMap> {
    const upMap: UserPermissionMap = {
      userPermission: userPermission,
      permissionsMap: DATASET_ROLES,
      isDataset: true,
      hasIrbNumber: !!this.dataset.study_irb_number,
      irbInvestigators: this.irbInvestigators,
    };

    const projectTeamMembers = await this.getProjectTeamMembers();

    if (projectTeamMembers) {
      upMap.projectTeamMembers = this.project.project_users.map((u: ProjectUserResponse) => {
        return <ProjectUserRoleReference>{
          email: u.user.email,
          role: u.project_role_id as ProjectRoleID,
          is_project_contact: (u.project_role_id as ProjectRoleId) === ProjectRole.OWNER,
          is_project_pi: !!u.is_project_pi,
        };
      });
    } else {
      upMap.projectTeamMembers = [];
    }

    return upMap;
  }

  displayError(errorString?: string, parsedError?: ParsedError) {
    this.snackBar.openFromComponent(ErrorSnackbarComponent, {
      data: {errorString, parsedError, action: 'Ok'},
      duration: 50000,
      panelClass: 'snackbar-warning',
    });
  }

  copyFormValuesToDataset() {
    // Remove fields from the newDataset object that aren't in the form.
    Object.keys(this.newDataset).forEach((key: string) => {
      if (!(key in this.fields) || this.fields[key].hidden || this.fields[key].formControl.disabled) {
        delete this.newDataset[key];
      }
    });

    // Copy values from form to dataset
    [
      'description',
      'keywords',
      'license',
      'link_to_external_dataset',
      'name',
      'study_irb_number',
      'variables_measured',
      'attribution',
    ].forEach(fieldName => {
      this.newDataset[fieldName] = this.fields[fieldName].formControl.value;
    });

    //Some fields need an empty value passed to indicate if they are empty or deleted.
    this.newDataset.study_irb_number = this.fields.study_irb_number.formControl.value || '';
    ['approved_irb_id', 'contract_id', 'dsp_id'].forEach(fieldName => {
      this.newDataset[fieldName] = this.fields[fieldName].formControl.value || '';
    });

    this.newDataset.users = this.dataset?.dataset_users?.map(u => ({
      email: u.user.email,
      role: u.dataset_role_id as DatasetRoleID,
    })) || [{email: this.userService?.user?.email, role: DatasetRoleID.ADMIN}];

    this.newDataset.data_aggregation_id =
      this.fields.data_aggregation_id.formControl.value || DatasetAggregationLevelID.NONE;

    // Inherit source organization from Project if none is given.
    const sourceOrgName = this.fields.source_organization.formControl.value;
    this.newDataset.source_organization = sourceOrgName
      ? {name: sourceOrgName}
      : {name: this.project.source_organization.name};

    // In the form and in DatasetCreate type, `identifiers_hipaa` is a `string[]`.
    // However, in DatasetResponse and CommonsSearchDataset, `identifiers_hipaa` is a `LabelTextIDResponse[]`.
    this.newDataset.identifiers_hipaa = this.fields.identifiers_hipaa.formControl.value.map(
      (v: string | LabelTextIDResponse) => (typeof v === 'string' ? v : v.id),
    );
    this.newDataset.other_sensitive_data = this.fields.other_sensitive_data.formControl.value.map(
      (v: string | LabelTextIDResponse) => (typeof v === 'string' ? v : v.id),
    );

    this.newDataset.variables_measured = this.newDataset.variables_measured || [];

    // Set default values for fields that are not in the form, but are still required by the API.
    this.newDataset.associated_projects = this.createNew
      ? [{project_id: this.projectIdFromRoute, is_primary_project: true}]
      : this.dataset.associated_projects.map(p => ({
          project_id: p.project.id,
          is_primary_project: p.is_primary_project,
        }));

    this.newDataset.original_datasets = this.createNew ? [] : this.dataset.original_datasets?.map(d => d.id) || [];
    this.newDataset.institutional_access = this.createNew ? false : this.dataset.institutional_access;
    this.newDataset.is_data_public = this.createNew ? false : this.dataset.is_data_public;
    this.newDataset.is_metadata_public = this.createNew ? false : this.dataset.is_metadata_public;

    const dateRangeObj: {start: Date | dayjs.Dayjs; end: Date | dayjs.Dayjs} =
      this.fields.temporal_coverage.formControl.value;
    if (dateRangeObj?.start && dateRangeObj?.end && this.fields.temporal_coverage.formControl.value.start) {
      this.newDataset.temporal_coverage = {
        start_date: dateRangeObj.start.toISOString(),
        end_date: dateRangeObj.end.toISOString(),
      };
    } else {
      this.newDataset.temporal_coverage = {start_date: '', end_date: ''};
    }

    this.newDataset.spatial_coverage = {
      street: this.fields['spatial_coverage.street'].formControl.value,
      city: this.fields['spatial_coverage.city'].formControl.value,
      state: this.fields['spatial_coverage.state'].formControl.value,
      zip_code: this.fields['spatial_coverage.zip_code'].formControl.value,
      country: this.fields['spatial_coverage.country'].formControl.value,
    };

    // Add in dataset_type, or lock it in place if it's already set.
    this.newDataset.dataset_type = this.dataset?.dataset_type || this.fields.dataset_type.formControl.value;

    // Copy REDCap-specific fields to the DatasetCreate object
    if (this.newDataset.dataset_type === DatasetType.REDCAP) {
      [
        'redcap_project_url',
        'redcap_extract_data',
        'redcap_report_id',
        'redcap_project_token',
        'is_data_dictionary',
      ].forEach(fieldName => {
        if (fieldName in this.fields) {
          this.newDataset[fieldName] = this.fields[fieldName].formControl.value;
        }
      });
      // Use a dummy token if no token is entered.
      if (this.fields.redcap_project_token.formControl.value === '') {
        this.newDataset.redcap_project_token = REDCAP_TOKEN_PLACEHOLDER;
      }
      // Parse the redcap extract options select.
      if (this.fields.extract_options.formControl.value === 'data_dictionary') {
        this.newDataset.redcap_extract_data = true;
        this.newDataset.is_data_dictionary = true;
      } else if (this.fields.extract_options.formControl.value === 'redcap_report') {
        this.newDataset.redcap_extract_data = true;
        this.newDataset.is_data_dictionary = false;
      } else {
        this.newDataset.redcap_extract_data = false;
        this.newDataset.is_data_dictionary = false;
      }

      this.needsRedcapToken =
        this.createNew &&
        this.newDataset.redcap_extract_data &&
        this.newDataset.redcap_project_token === REDCAP_TOKEN_PLACEHOLDER;
    }

    // Copy DICOM-specific fields to the DatasetCreate object
    if (this.newDataset.dataset_type === DatasetType.DICOM) {
      ['bids_structure', 'quality'].forEach(fieldName => {
        if (fieldName in this.fields) {
          this.newDataset[fieldName] = this.fields[fieldName].formControl.value;
        }
      });
      // Set a default value for the bids_structure if it was unselected.
      if (!this.fields.bids_structure.formControl.value) {
        this.newDataset.bids_structure = false;
      }
    }
  }

  async loadDataset(datasetId?: string) {
    datasetId = datasetId || this.datasetIdFromRoute;

    if (datasetId) {
      this.dataset = await lastValueFrom(
        this.cas.getDataset(datasetId, this.userService?.user, this.project?.publisher?.name),
      );
      const datasetSearchResults = await this.ras.getDatasets('', this.userService?.user, false, {id: datasetId}, true);
      if (datasetSearchResults?.length > 0) {
        this.datasetWithPermissions = datasetSearchResults[0];
      }
    }

    this.newDataset = makeDatasetPutFromDatasetResponse(this.userService?.user, this.projectIdFromRoute, this.dataset);
    return this.dataset;
  }

  async loadProject(): Promise<ProjectResponse> {
    // Look up Project in Elasticsearch to get the publisher name.
    const result = await this.ras.getProjects('', this.userService?.user, false, {id: this.projectIdFromRoute}, true);
    if (result?.length > 0) {
      this.publisherName = result[0]?.publisher?.name;
    }
    this.project = await lastValueFrom(
      this.cas.getProject(this.projectIdFromRoute, this.userService?.user, this.publisherName),
    );
    return this.project;
  }

  async loadIRBNumbers() {
    this.irbNumbers = await lastValueFrom(this.cas.getUserIrbNumbers(this.userService?.user, this.dataset));
    return this.irbNumbers;
  }

  loadNavItems() {
    const keys: CommonsStateForm[] = this.datasetIdFromRoute
      ? ['commons-private', 'commons-project-private', 'commons-dataset-private', 'commons-dataset-create-edit']
      : ['commons-private', 'commons-project-private', 'commons-dataset-create-edit'];
    this.navItems = getNavItems(keys, this.projectIdFromRoute, this.datasetIdFromRoute);
  }

  async getProjectTeamMembers() {
    this.project = await lastValueFrom(this.cas.getProject(this.project.id, this.userService.user, this.publisherName));
    return this.project.project_users;
  }

  async loadInstitutions(): Promise<Institution[]> {
    this.institutions = await lastValueFrom(this.ras.getInstitutions());
    return this.institutions;
  }

  displayFormErrors() {
    const messages: ParsedError = {
      title: 'Please double-check the following fields:',
      errors: [],
    };

    Object.values(this.fields).forEach((field: FormField) => {
      const formControl = field.formControl;
      const errors = formControl.errors;
      const label = field.placeholder;

      for (const errorName in errors) {
        if (errors.hasOwnProperty(errorName)) {
          switch (errorName) {
            case 'dateTimeRange':
              messages.errors.push({title: label, messages: [`Not a valid start and end date/time.`]});
              break;
            case 'email':
              messages.errors.push({title: label, messages: [`Not a valid email address.`]});
              break;
            case 'commaDelimitedList':
              messages.errors.push({
                title: label,
                messages: [`The items in the list must be separated by commas.`],
              });
              break;
            case 'cui':
              messages.errors.push({
                title: label,
                messages: [
                  'The iTHRIV Research Data Commons is not currently designed for storage of CUI data. ' +
                    'Please use other CUI solutions provided by your institution.',
                ],
              });
              break;
            case 'maxlength':
              messages.errors.push({title: label, messages: [`Not long enough.`]});
              break;
            case 'minlength':
              messages.errors.push({title: label, messages: [`Too short.`]});
              break;
            case 'required':
              messages.errors.push({title: label, messages: [`This field is required. It is currently empty.`]});
              break;
            case 'url':
              messages.errors.push({title: label, messages: [`Not a valid URL.`]});
              break;
            default:
              messages.errors.push({title: label, messages: [`Has an error.`]});
              break;
          }
        }
      }
    });

    this.displayError(undefined, messages);
  }

  isDatasetType(t: DatasetType): boolean {
    const datasetType: DatasetType = this.dataset?.dataset_type || this.fields?.dataset_type?.formControl?.value;
    return datasetType === t;
  }

  redcapDataset(): RedcapDatasetResponse {
    return this.dataset as RedcapDatasetResponse;
  }

  dicomDataset(): DICOMDatasetResponse {
    return this.dataset as DICOMDatasetResponse;
  }

  getDefaultFieldValue(fieldName: string) {
    const field = this.fields[fieldName];

    if (!field) {
      return;
    }

    if (fieldName in this.defaultValueMap) {
      return this.defaultValueMap[fieldName];
    }
  }

  temporalCoverageToDates(temporalCoverage: TemporalCoverageBase) {
    if (
      temporalCoverage?.start_date === '0001-01-01T00:00:00Z' &&
      temporalCoverage?.end_date === '0001-01-01T00:00:00Z'
    )
      return;
    if (temporalCoverage?.start_date && temporalCoverage?.end_date) {
      return {
        start: new Date(temporalCoverage.start_date),
        end: new Date(temporalCoverage.end_date),
      };
    }

    return undefined;
  }

  // Returns the correct ID based off of redcap extract options
  parseExtractOptions(rm?: DatasetRedcapMetadataBaseWithTokenExcludedInResponse) {
    if (!rm) return undefined;
    // Export Data Dictionary Only
    if (rm.redcap_extract_data && rm.is_data_dictionary) {
      return 'data_dictionary';
    }
    // Export Redcap Report
    if (rm.redcap_extract_data && !rm.is_data_dictionary) {
      return 'redcap_report';
    }
    // None
    else {
      return undefined;
    }
  }

  loadGenericFields(): DatasetFormFields {
    // Create options map from the list of ProjectFileCategoryIds
    const fileOptions: {[key in ProjectFileCategoryId]?: FormSelectOption[]} = {};

    this.project.project_file_versions.forEach(f => {
      const catId = f.category as ProjectFileCategoryId;

      if (!catId) {
        return;
      }

      if (!fileOptions[catId]) {
        fileOptions[catId] = [];
      }

      if (f.file_version) {
        fileOptions[catId].push(new FormSelectOption({id: f.file_version.id, name: f.file_version.file_name}));
      }
    });

    const hipaaOptions = Object.values(IDENTIFIERS_HIPAA).map(o => {
      return new FormSelectOption({
        id: o.id,
        name: `${o.text} (${o.sensitivity_level})`,
      });
    });

    const datasetTypeOptions = [
      new FormSelectOption({
        id: DatasetType.GENERIC,
        name: 'Generic',
        tooltip: 'Select this option if the dataset is neither stored in DICOM format nor in the REDCap system.',
      }),
      new FormSelectOption({
        id: DatasetType.DICOM,
        name: 'DICOM',
        tooltip:
          'Digital Imaging and Communications in Medicine (DICOM) is a standard for medical imaging ' +
          'information and related data, commonly used for storing and transmitting medical images.',
      }),
      new FormSelectOption({
        id: DatasetType.REDCAP,
        name: 'REDCap',
        tooltip:
          'REDCap provides UVA researchers with a HIPAA-compliant environment for managing research data. ' +
          'The REDCap integration allows researchers to request routine exports of their data from REDCap to the ' +
          'Commons instead of the researcher doing manual downloads out of REDCap.',
      }),
    ];

    const dataAggregationOptions = Object.entries(DatasetAggregationLevelLabels).map(
      ([k, v]) =>
        new FormSelectOption({
          id: k,
          name: v,
        }),
    );

    const otherSensitiveDataOptions = Object.entries(OtherSensitiveDataLabels).map(
      ([k, v]) =>
        new FormSelectOption({
          id: k,
          name: v,
        }),
    );

    return {
      dataset_type: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<DatasetType>({
          value: this.getDefaultFieldValue('dataset_type'),
          disabled: !!this.datasetIdFromRoute,
        }),
        required: true,
        placeholder: 'Dataset Type:',
        type: 'radio',
        selectOptions: datasetTypeOptions,
        onChange: this.handleDatasetTypeChange.bind(this),
        fieldsetId: 'dataset_type',
        fieldsetLabel: 'Dataset Type',
      }),
      source_organization: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string>(this.getDefaultFieldValue('source_organization')),
        required: true,
        placeholder: 'Source of Content / Host Institution',
        type: 'image_select',
        selectOptions: getLoginServices(this.institutions, false, this.userService?.user).map(s => {
          return new FormSelectOption({
            id: s.name,
            name: s.name,
            color: s.color,
            image: s.image,
          });
        }),
        defaultValue: this.userService.user.institution.name,
        fieldsetId: 'source_organization_prefs',
        fieldsetLabel: 'Source Organization',
        markdownBelow:
          'The default selection will be the institution associated with the dataset creator’s login. However, the Dataset may originate or be led by someone from another institution. Select "iTHRIV" if the dataset consists of data from multiple iTHRIV institutions. For all other datasets being indexed in the Commons, select "Other Source / External".',
      }),
      name: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string>(this.getDefaultFieldValue('name')),
        required: true,
        placeholder: 'Dataset Name:',
        type: 'text',
        options: {
          status: ['words'],
        },
        fieldsetId: 'basic_info',
        fieldsetLabel: 'Basic Information',
      }),
      description: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string>(this.getDefaultFieldValue('description')),
        required: true,
        placeholder: 'Brief Description:',
        type: 'textarea',
        options: {
          status: ['words'],
        },
        fieldsetId: 'basic_info',
      }),
      keywords: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string[]>(this.getDefaultFieldValue('keywords')),
        required: true,
        placeholder: 'Key Words:',
        helpText: 'Enter a comma-delimited list of words or phrases that people can use to search for this dataset.',
        type: 'list',
        fieldsetId: 'basic_info',
      }),
      identifiers_hipaa: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<IdentifierHipaaID[]>(this.getDefaultFieldValue('identifiers_hipaa')),
        required: true,
        placeholder: 'HIPAA options:',
        helpText: 'Enter patient data types captured. If the data does not come from medical records, enter N/A.',
        type: 'select',
        multiSelect: true,
        selectOptions: hipaaOptions,
        fieldsetId: 'basic_info',
        onChange: this.handleIdentifiersHipaaChange.bind(this),
      }),
      data_aggregation_id: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string>(this.getDefaultFieldValue('data_aggregation_id')),
        required: false,
        placeholder: 'Data aggregation:',
        type: 'select',
        selectOptions: dataAggregationOptions,
        hideNoneOption: true,
        fieldsetId: 'basic_info',
      }),
      other_sensitive_data: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<OtherSensitiveDataID[]>(this.getDefaultFieldValue('other_sensitive_data')),
        required: true,
        placeholder: 'Other sensitive data:',
        type: 'select',
        multiSelect: true,
        selectOptions: otherSensitiveDataOptions,
        fieldsetId: 'basic_info',
        onChange: this.handleIdentifiersHipaaChange.bind(this),
      }),
      variables_measured: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string[]>(this.getDefaultFieldValue('variables_measured')),
        required: false,
        placeholder: 'Variables Measured:',
        type: 'list',
        fieldsetId: 'basic_info',
      }),
      license: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string>(this.getDefaultFieldValue('license')),
        required: false,
        placeholder: 'License:',
        type: 'text',
        options: {
          status: ['words'],
        },
        fieldsetId: 'basic_info',
      }),
      attribution: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string>(this.getDefaultFieldValue('attribution')),
        required: false,
        placeholder: 'Attribution Instructions:',
        type: 'textarea',
        options: {
          status: ['words'],
        },
        helpText:
          'If the Dataset license allows it, enter instructions for re-using this Dataset. For example, see https://wiki.creativecommons.org/wiki/Attribution',
        fieldsetId: 'basic_info',
      }),
      link_to_external_dataset: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string>(this.getDefaultFieldValue('link_to_external_dataset')),
        required: false,
        placeholder: 'Link to Data (if stored elsewhere):',
        type: 'text',
        fieldsetId: 'basic_info',
      }),
      'spatial_coverage.street': new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string>(this.getDefaultFieldValue('spatial_coverage.street')),
        required: false,
        placeholder: 'Street',
        type: 'text',
        fieldsetId: 'spatial_coverage',
        fieldsetLabel: 'Geographic Coverage Description',
      }),
      'spatial_coverage.city': new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string>(this.getDefaultFieldValue('spatial_coverage.city')),
        required: false,
        placeholder: 'City',
        type: 'text',
        fieldsetId: 'spatial_coverage',
      }),
      'spatial_coverage.state': new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string>(this.getDefaultFieldValue('spatial_coverage.state')),
        required: false,
        placeholder: 'State',
        type: 'text',
        fieldsetId: 'spatial_coverage',
      }),
      'spatial_coverage.zip_code': new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string>(this.getDefaultFieldValue('spatial_coverage.zip_code')),
        required: false,
        placeholder: 'ZIP Code',
        type: 'text',
        fieldsetId: 'spatial_coverage',
      }),
      'spatial_coverage.country': new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string>(this.getDefaultFieldValue('spatial_coverage.country')),
        required: false,
        placeholder: 'Country',
        type: 'text',
        fieldsetId: 'spatial_coverage',
      }),
      temporal_coverage: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<TemporalCoverageResponse>(this.getDefaultFieldValue('temporal_coverage')),
        required: false,
        hidden: true,
        label: 'Click here to select start and end dates',
        placeholder: 'Data Collection Timeframe',
        type: 'daterange',
        defaultValue: this.getDefaultFieldValue('temporal_coverage'),
        fieldsetId: 'temporal_coverage',
        fieldsetLabel: 'Temporal Coverage Description',
      }),
      study_irb_number: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string>(this.getDefaultFieldValue('study_irb_number')),
        required: false,
        placeholder: 'IRB Protocol Number:',
        helpText: 'Required only for highly sensitive data.',
        type: 'select',
        multiSelect: false,
        selectOptions: this.irbNumbers.map((irbNumber: IRBUserProtocolsResponse) => {
          return new FormSelectOption({
            id: irbNumber.id,
            name: `${irbNumber.id}: ${irbNumber.title}`,
          });
        }),
        fieldsetId: 'irb_info',
        fieldsetLabel: 'IRB Info',
      }),
      approved_irb_id: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string>(this.getDefaultFieldValue('approved_irb_id')),
        required: false,
        // language=TEXT
        placeholder: 'Select Corresponding IRB Approval Document from Project Documents',
        type: 'select',
        multiSelect: false,
        selectOptions: fileOptions['irb-approval'],
        fieldsetId: 'irb_info',
      }),
      contract_id: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string>(this.getDefaultFieldValue('contract_id')),
        required: false,
        placeholder: 'Related Contract:',
        type: 'select',
        multiSelect: false,
        selectOptions: fileOptions['contract'],
        fieldsetId: 'irb_info',
      }),
      dsp_id: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string>(this.getDefaultFieldValue('dsp_id')),
        required: false,
        helpText: 'Required only for highly sensitive data.',
        // language=TEXT
        placeholder: 'Select Corresponding Data Security Plan from Project Documents',
        type: 'select',
        multiSelect: false,
        selectOptions: fileOptions['data-security-plan'],
        fieldsetId: 'irb_info',
      }),
    };
  }

  loadREDCapFields(): DatasetFormFields {
    const isREDCapDataset = this.isDatasetType(DatasetType.REDCAP);
    const hideTokenField = this.hideRedcapTokenField();

    const rm = isREDCapDataset && this.redcapDataset()?.redcap_metadata;
    const hasNotYetExtractedData = !isREDCapDataset || !rm?.redcap_extract_data || !rm?.redcap_project_pi;

    const dataExtractOptions: FormSelectOption[] = [
      new FormSelectOption({
        id: 'none',
        name: 'None',
      }),
      new FormSelectOption({
        id: 'data_dictionary',
        name: 'Export Data Dictionary Only',
      }),
      new FormSelectOption({
        id: 'redcap_report',
        name: 'Export Redcap Report',
      }),
    ];

    return {
      // User-editable fields
      redcap_project_url: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string>({value: this.getDefaultFieldValue('redcap_project_url'), disabled: false}),
        required: isREDCapDataset,
        hidden: !isREDCapDataset,
        placeholder: 'REDCap Project URL',
        type: 'url',
        options: {
          status: ['url'],
        },
        fieldsetId: 'redcap_metadata',
        fieldsetLabel: 'REDCap Dataset Information',
      }),
      extract_options: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string[]>(this.getDefaultFieldValue('extract_options')),
        required: false,
        hidden: !isREDCapDataset,
        placeholder: 'Extract From Redcap',
        type: 'select',
        selectOptions: dataExtractOptions,
        hideNoneOption: true,
        fieldsetId: 'redcap_metadata',
        onChange: this.handleRedcapExtractChange.bind(this),
      }),
      redcap_project_token: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<boolean>({
          value: this.getDefaultFieldValue('redcap_project_token'),
          disabled: false,
        }),
        type: 'text',
        required: false,
        hidden: hideTokenField,
        placeholder: 'REDCap Project API Token',
        fieldsetId: 'redcap_metadata',
        helpText:
          'All extracts require a token. If no token is entered, you will receive instructions from a REDCap Admin within two business days regarding how to get a token that you can enter here.',
      }),
      redcap_report_id: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string>({
          value: this.getDefaultFieldValue('redcap_report_id'),
          disabled: false,
        }),
        required: false,
        hidden: !isREDCapDataset,
        placeholder: 'REDCap Report ID',
        type: 'text',
        fieldsetId: 'redcap_metadata',
      }),
      redcap_project_title: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string | null>({
          value: this.getDefaultFieldValue('redcap_project_title'),
          disabled: true,
        }),
        hidden: hasNotYetExtractedData,
        required: false,
        placeholder: 'REDCap Project Title',
        type: 'text',
        fieldsetId: 'redcap_metadata',
      }),
      redcap_project_pi: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string | null>({
          value: this.getDefaultFieldValue('redcap_project_pi'),
          disabled: true,
        }),
        hidden: hasNotYetExtractedData,
        required: false,
        placeholder: 'REDCap Project PI',
        type: 'text',
        fieldsetId: 'redcap_metadata',
      }),
    };
  }

  loadDICOMFields(): DatasetFormFields {
    const isDICOMDataset = this.isDatasetType(DatasetType.DICOM);

    const dm = this.dicomDataset()?.dicom_metadata;

    return {
      bids_structure: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<boolean>({value: this.getDefaultFieldValue('bids_structure'), disabled: false}),
        type: 'boolean',
        booleanMode: 'checkbox',
        label: 'Bids Structure',
        required: isDICOMDataset,
        hidden: !isDICOMDataset,
        fieldsetId: 'dicom_metadata',
        fieldsetLabel: 'DICOM Dataset Information',
      }),
      quality: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string>({value: this.getDefaultFieldValue('quality'), disabled: false}),
        type: 'select',
        label: 'Quality',
        placeholder: 'Quality',
        required: isDICOMDataset,
        hidden: !isDICOMDataset,
        fieldsetId: 'dicom_metadata',
        selectOptions: ['unknown', 'good', 'medium', 'poor'],
      }),

      // Auto-populated fields
      study_date: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string | null>({value: this.getDefaultFieldValue('study_date'), disabled: true}),
        type: 'text',
        label: 'Study Date',
        required: false,
        hidden: !isDICOMDataset || !dm?.study_date,
        fieldsetId: 'dicom_metadata',
        markdownAbove: '### The following fields will be filled out automatically after a DICOM zip file is uploaded.',
      }),
      scanner_manufacturer_name: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string | null>({
          value: this.getDefaultFieldValue('scanner_manufacturer_name'),
          disabled: true,
        }),
        type: 'text',
        label: 'Scanner Manufacturer Name',
        required: false,
        hidden: !isDICOMDataset || !dm?.scanner_manufacturer_name,
        fieldsetId: 'dicom_metadata',
      }),
      scanner_model_name: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string | null>({
          value: this.getDefaultFieldValue('scanner_model_name'),
          disabled: true,
        }),
        type: 'text',
        label: 'Scanner Model Name',
        required: false,
        hidden: !isDICOMDataset || !dm?.scanner_model_name,
        fieldsetId: 'dicom_metadata',
      }),
      organ_name: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string | null>({value: this.getDefaultFieldValue('organ_name'), disabled: true}),
        type: 'text',
        label: 'Organ Name',
        required: false,
        hidden: !isDICOMDataset || !dm?.organ_name,
        fieldsetId: 'dicom_metadata',
      }),
      field_of_view: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<number | null>({
          value: this.getDefaultFieldValue('field_of_view'),
          disabled: true,
        }),
        type: 'number',
        label: 'Field Of View',
        required: false,
        hidden: !isDICOMDataset || !dm?.field_of_view,
        fieldsetId: 'dicom_metadata',
      }),
      field_strength: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<number | null>({
          value: this.getDefaultFieldValue('field_strength'),
          disabled: true,
        }),
        type: 'number',
        label: 'Field Strength',
        required: false,
        hidden: !isDICOMDataset || !dm?.field_strength,
        fieldsetId: 'dicom_metadata',
      }),
      acquisition_date: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string | null>({
          value: this.getDefaultFieldValue('acquisition_date'),
          disabled: true,
        }),
        type: 'text',
        label: 'Acquisition Date',
        required: false,
        hidden: !isDICOMDataset || !dm?.acquisition_date,
        fieldsetId: 'dicom_metadata',
      }),
      angio_flag: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string | null>({value: this.getDefaultFieldValue('angio_flag'), disabled: true}),
        type: 'text',
        label: 'Angio Flag',
        required: false,
        hidden: !isDICOMDataset || !dm?.angio_flag,
        fieldsetId: 'dicom_metadata',
      }),
      institution_name: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string | null>({
          value: this.getDefaultFieldValue('institution_name'),
          disabled: true,
        }),
        type: 'text',
        label: 'Institution Name',
        required: false,
        hidden: !isDICOMDataset || !dm?.institution_name,
        fieldsetId: 'dicom_metadata',
      }),
      largest_image_pixel_value: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<number | null>({
          value: this.getDefaultFieldValue('largest_image_pixel_value'),
          disabled: true,
        }),
        type: 'number',
        label: 'Largest Image Pixel Value',
        required: false,
        hidden: !isDICOMDataset || !dm?.largest_image_pixel_value,
        fieldsetId: 'dicom_metadata',
      }),
      modality: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string | null>({value: this.getDefaultFieldValue('modality'), disabled: true}),
        type: 'text',
        label: 'Modality',
        required: false,
        hidden: !isDICOMDataset || !dm?.modality,
        fieldsetId: 'dicom_metadata',
      }),
      mr_acquisition_type: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string | null>({
          value: this.getDefaultFieldValue('mr_acquisition_type'),
          disabled: true,
        }),
        type: 'text',
        label: 'MR Acquisition Type',
        required: false,
        hidden: !isDICOMDataset || !dm?.mr_acquisition_type,
        fieldsetId: 'dicom_metadata',
      }),
      patient_sex: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string | null>({value: this.getDefaultFieldValue('patient_sex'), disabled: true}),
        type: 'text',
        label: 'Patient Sex',
        required: false,
        hidden: !isDICOMDataset || !dm?.patient_sex,
        fieldsetId: 'dicom_metadata',
      }),
      protocol_name: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string | null>({
          value: this.getDefaultFieldValue('protocol_name'),
          disabled: true,
        }),
        type: 'text',
        label: 'Protocol Name',
        required: false,
        hidden: !isDICOMDataset || !dm?.protocol_name,
        fieldsetId: 'dicom_metadata',
      }),
      scanning_sequence: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string | null>({
          value: this.getDefaultFieldValue('scanning_sequence'),
          disabled: true,
        }),
        type: 'text',
        label: 'Scanning Sequence',
        required: false,
        hidden: !isDICOMDataset || !dm?.scanning_sequence,
        fieldsetId: 'dicom_metadata',
      }),
      sequence_name: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string | null>({
          value: this.getDefaultFieldValue('sequence_name'),
          disabled: true,
        }),
        type: 'text',
        label: 'Sequence Name',
        required: false,
        hidden: !isDICOMDataset || !dm?.sequence_name,
        fieldsetId: 'dicom_metadata',
      }),
      series_description: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string | null>({
          value: this.getDefaultFieldValue('series_description'),
          disabled: true,
        }),
        type: 'text',
        label: 'Series Description',
        required: false,
        hidden: !isDICOMDataset || !dm?.series_description,
        fieldsetId: 'dicom_metadata',
      }),
      slice_location: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<number | null>({
          value: this.getDefaultFieldValue('slice_location'),
          disabled: true,
        }),
        type: 'number',
        label: 'Slice Location',
        required: false,
        hidden: !isDICOMDataset || !dm?.slice_location,
        fieldsetId: 'dicom_metadata',
      }),
      slice_thickness: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<number | null>({
          value: this.getDefaultFieldValue('slice_thickness'),
          disabled: true,
        }),
        type: 'number',
        label: 'Slice Thickness',
        required: false,
        hidden: !isDICOMDataset || !dm?.slice_thickness,
        fieldsetId: 'dicom_metadata',
      }),
      software_versions: new FormField({
        formGroup: this.fg,
        formControl: new FormControl<string | null>({
          value: this.getDefaultFieldValue('software_versions'),
          disabled: true,
        }),
        type: 'text',
        label: 'Software Versions',
        required: false,
        hidden: !isDICOMDataset || !dm?.software_versions,
        fieldsetId: 'dicom_metadata',
      }),
    };
  }

  showFields(fieldNames: string[], shouldShow: boolean): void {
    if (!this.fields) {
      return;
    }

    // Set the hidden state of each of the given fields to the given value.
    fieldNames.forEach(f => {
      if (!this.fields[f]) {
        return;
      }
      this.fields[f].hidden = !shouldShow;
    });
  }

  handleDatasetTypeChange(event?: MatRadioChange) {
    const datasetType = event ? (event.value as DatasetType) : this.fields.dataset_type.formControl.value;

    // Always show Generic fields
    this.showFields(GENERIC_FIELDS, true);

    // If REDCap, show REDCap fields. Otherwise, hide them.
    this.showFields(REDCAP_FIELDS, datasetType === DatasetType.REDCAP);

    // If DICOM, show DICOM fields. Otherwise, hide them.
    this.showFields(DICOM_FIELDS, datasetType === DatasetType.DICOM);

    // If all fields are hidden in a fieldset, hide the entire fieldset.
    this.fieldsets.forEach(fieldset => {
      fieldset.hidden = fieldset.fields.every(f => f.hidden);
    });

    this.changeDetectorRef.detectChanges();
    this.resetValidators(datasetType);
  }

  handleIdentifiersHipaaChange() {
    const identifiersHipaa: IdentifierHipaaID[] = this.fields.identifiers_hipaa?.formControl?.value;
    const otherSensitiveData: OtherSensitiveDataID[] = this.fields.other_sensitive_data?.formControl?.value;
    const hsdIDs = new Set(Object.values(HSD_HIPAA_OPTIONS).map(o => o.id));

    const hasHSD = identifiersHipaa?.some(v => hsdIDs.has(v));
    const hasOSD = otherSensitiveData?.some(v => v !== OtherSensitiveDataID.NONE);

    ['study_irb_number', 'dsp_id'].forEach(fieldName => {
      const field = this.fields[fieldName];
      const validators = [ValidateHsd];
      field.name = fieldName;
      field.required = hasHSD || hasOSD;
      if (field.required) {
        validators.push(Validators.required);
      }

      field.formControl.clearValidators();
      field.formControl.setValidators(validators);
      this.fg.addControl(fieldName, field.formControl);
      field.formControl.markAsTouched();
      field.formControl.updateValueAndValidity();
    });
  }

  handleRedcapExtractChange() {
    this.fields.redcap_project_token.hidden = this.hideRedcapTokenField();
    this.fg.addControl(this.fields.redcap_project_token.name, this.fields.redcap_project_token.formControl);
    this.fields.redcap_project_token.formControl.markAsTouched();
    this.fields.redcap_project_token.formControl.updateValueAndValidity();
  }

  private hideRedcapTokenField() {
    return this.isDatasetType(DatasetType.REDCAP) && this.fields?.extract_options?.formControl?.value !== undefined;
  }

  resetValidators(datasetType: DatasetType) {
    Object.entries(this.fields).forEach(([fieldName, field]) => {
      if (field.formControl) {
        if (fieldName === 'dataset_type') {
        }
        field.name = fieldName;
        this.fg.addControl(fieldName, field.formControl);
        field.formControl.removeValidators(getFieldValidators(field, FormType.DATASET, datasetType));
        field.formControl.setValidators(getFieldValidators(field, FormType.DATASET, datasetType));
        field.formControl.updateValueAndValidity();
      }
    });
  }

  loadDefaultValues() {
    const d = this.dataset;
    const rm = (d as RedcapDatasetResponse)?.redcap_metadata;
    const dm = (d as DICOMDatasetResponse)?.dicom_metadata;

    this.defaultValueMap = {
      // Required fields
      description: d?.description || '',
      identifiers_hipaa: d?.identifiers_hipaa.map(x => x.id) || [],
      keywords: removeEmptyItems(d?.keywords.map((v: string | Keyword) => (typeof v === 'string' ? v : v.text))) || [],
      name: d?.name || '',
      other_sensitive_data: d?.other_sensitive_data.map(x => x.id) || [],
      source_organization: d?.source_organization.name || '',
      dataset_type: d?.dataset_type || DatasetType.GENERIC,

      // Optional fields
      approved_irb_id: d?.associated_projects?.find(p => !!p.approved_irb)?.approved_irb?.id || undefined,
      attribution: d?.attribution || undefined,
      contract_id: d?.associated_projects?.find(p => !!p.contract)?.contract?.id || undefined,
      data_aggregation_id:
        d?.data_aggregation?.id !== DatasetAggregationLevel.NONE ? d?.data_aggregation?.id : undefined,
      dsp_id: d?.associated_projects?.find(p => !!p.data_security_plan)?.data_security_plan?.id || undefined,
      license: d?.license || undefined,
      link_to_external_dataset: d?.link_to_external_dataset || undefined,
      'spatial_coverage.street': d?.spatial_coverage?.street || '',
      'spatial_coverage.city': d?.spatial_coverage?.city || '',
      'spatial_coverage.state': d?.spatial_coverage?.state || '',
      'spatial_coverage.zip_code': d?.spatial_coverage?.zip_code || '',
      'spatial_coverage.country': d?.spatial_coverage?.country || '',
      study_irb_number: d?.study_irb_number || undefined,
      temporal_coverage: this.temporalCoverageToDates(d?.temporal_coverage) || undefined,
      variables_measured: d?.variables_measured || undefined,

      // REDCap fields
      redcap_project_url: rm?.redcap_project_url,
      redcap_extract_data: rm?.redcap_extract_data,
      redcap_project_token: rm?.redcap_extract_data ? REDCAP_TOKEN_PLACEHOLDER : '',
      redcap_report_id: rm?.redcap_report_id,
      is_data_dictionary: rm?.is_data_dictionary,
      redcap_project_title: rm?.redcap_project_title,
      redcap_project_pi: rm?.redcap_project_pi,
      extract_options: this.parseExtractOptions(rm) || undefined,

      // DICOM fields
      bids_structure: dm?.bids_structure,
      quality: dm?.quality,
      study_date: dm?.study_date,
      scanner_manufacturer_name: dm?.scanner_manufacturer_name,
      scanner_model_name: dm?.scanner_model_name,
      organ_name: dm?.organ_name,
      field_of_view: dm?.field_of_view,
      field_strength: dm?.field_strength,
      acquisition_date: dm?.acquisition_date,
      angio_flag: dm?.angio_flag,
      institution_name: dm?.institution_name,
      largest_image_pixel_value: dm?.largest_image_pixel_value,
      modality: dm?.modality,
      mr_acquisition_type: dm?.mr_acquisition_type,
      patient_sex: dm?.patient_sex,
      protocol_name: dm?.protocol_name,
      scanning_sequence: dm?.scanning_sequence,
      sequence_name: dm?.sequence_name,
      series_description: dm?.series_description,
      slice_location: dm?.slice_location,
      slice_thickness: dm?.slice_thickness,
      software_versions: dm?.software_versions,
    };
  }
}
