/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { IRBUserProtocolsResponse } from '../models/IRBUserProtocolsResponse';
import type { UserCreate } from '../models/UserCreate';
import type { UserListResponse } from '../models/UserListResponse';
import type { UserPatch } from '../models/UserPatch';
import type { UserPut } from '../models/UserPut';
import type { UserResponse } from '../models/UserResponse';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable({
    providedIn: 'root',
})
export class UsersService {

    constructor(public readonly http: HttpClient) {}

    /**
     * Get Users
     * Set query param 'is_admin' to True to get list of admin users
     * @param isAdmin
     * @returns UserListResponse Successful Response
     * @throws ApiError
     */
    public listUsers(
        isAdmin?: (boolean | null),
    ): Observable<UserListResponse> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/users',
            query: {
                'is_admin': isAdmin,
            },
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Create User
     * @param requestBody
     * @returns any Successful Response
     * @returns string Created
     * @throws ApiError
     */
    public createUser(
        requestBody: UserCreate,
    ): Observable<any | string> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/users',
            body: requestBody,
            mediaType: 'application/json',
            responseHeader: 'Location',
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get User
     * @param userId
     * @returns UserResponse Successful Response
     * @throws ApiError
     */
    public getUser(
        userId: ('me' | string),
    ): Observable<UserResponse> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/users/{user_id}',
            path: {
                'user_id': userId,
            },
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Update User
     * @param userId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public updateUser(
        userId: string,
        requestBody: UserPut,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'PUT',
            url: '/users/{user_id}',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Patch User
     * @param userId
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public editUser(
        userId: string,
        requestBody: UserPatch,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'PATCH',
            url: '/users/{user_id}',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

    /**
     * Get User Irb Protocols
     * Gets a list of IRB protocols (IRB study IDs) with which the requesting user has approval
     * @param userId
     * @returns IRBUserProtocolsResponse Successful Response
     * @throws ApiError
     */
    public listUserIrbProtocols(
        userId: ('me' | string),
    ): Observable<Array<IRBUserProtocolsResponse>> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/users/{user_id}/irb-protocols',
            path: {
                'user_id': userId,
            },
            errors: {
                400: `Bad Request`,
                422: `Validation Error`,
                500: `Internal Server Error`,
            },
        });
    }

}
