// Autogenerated by util/sync-labels/sync-labels.ts
// Do not edit this file directly.
// To regenerate this file, start ithriv_landing_service locally on port 8000 and
// run the following command in the root directory of the ithriv_web repository:
// > npm run sync_labels

export enum SensitivityLevel {
  HSD = 'hsd',
  LDS = 'lds',
  DE_ID = 'de-id',
  NA = 'na',
}

export const SensitivityLevelLabels = {
  hsd: 'HIGHLY SENSITIVE DATA',
  lds: 'LIMITED DATASET',
  'de-id': 'DE-IDENTIFIED',
  na: 'N/A',
} as const;

export type SensitivityLevelId = keyof typeof SensitivityLevelLabels;
export type SensitivityLevelLabel = typeof SensitivityLevelLabels[SensitivityLevelId];
